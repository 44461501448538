import React from "react"
import {ApplicationState} from "../../../state/store"
import {Dispatch} from "redux"
import {connect} from "react-redux"
import SamplingTreeNode from "../../../typescript/objects/SamplingTreeNode"
import {withPortal} from "react-portalgun"
import Button from "reactstrap/lib/Button"
import ButtonGroup from "reactstrap/lib/ButtonGroup"
import Icon from "../../utils/Icon"
import {SamplingActionTypes} from "../../../state/ducks/sampling.duck"
import {UncontrolledTooltip} from "reactstrap/lib/Uncontrolled";

interface ComponentProps {
    node: SamplingTreeNode
}

interface ReduxProps { }

interface PortalProps {
    openMenu(params: any): void
}

interface ReduxActions {
    toggleNotMandatory(node: SamplingTreeNode): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

const SamplingToolbar = (props: Props) => (
    <>
        {!props.node.isOrganisational() &&
            <div className="sampling-toolbar">
                <ButtonGroup>
                    <Button
                        size="sm"
                        color="primary"
                        active={props.node.isNotMandatory()}
                        onClick={() => props.toggleNotMandatory(props.node)}
                    >
                        <Icon type="fas" icon="times" />
                    </Button>

                    <Button
                        size="sm"
                        color="primary"
                        active={props.node.isCustomerPerformance() || props.node.hasComment() || props.node.isExcludedFromProtocol() || props.node.isSpecialPricing()}
                        onClick={() => props.openMenu({ node: props.node })}
                        id={"modal-button"+props.node.id}
                    >
                        <Icon type="fas" icon="ellipsis-h" />
                    </Button>
                    { ( props.node.isCustomerPerformance() || props.node.hasComment() || props.node.isExcludedFromProtocol() || props.node.isSpecialPricing() ) &&
                    <UncontrolledTooltip placement="right" target={"modal-button"+props.node.id}>
                    {props.node.isCustomerPerformance() && (<span>Eigenleistung<br /></span>)}
                    {props.node.hasComment() && (<span>Freitext enthalten<br /></span>)}
                    {props.node.isExcludedFromProtocol() && (<span>Nicht auf Protokoll<br /></span>)}
                    {props.node.isSpecialPricing() && "Mehr-/Minderpreis"}
                    </UncontrolledTooltip>
                    }
                </ButtonGroup>
            </div >
        }
    </>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    toggleNotMandatory: (node: SamplingTreeNode) => dispatch({ type: SamplingActionTypes.TOGGLE_SAMPLING_NOT_MANDATORY, node })
})

export default withPortal("OPEN_SAMPLING_MENU", "openMenu")(
    connect(mapStateToProps, mapDispatchToProps)(SamplingToolbar)
)
import React from "react"

import ReactTable from 'react-table'
import CustomerToolbar from "./CustomerToolbar"

import "./customers.sass"
import Customer from "../../../typescript/objects/Customer"

const columns = [
    {
        Header: "Nachname",
        accessor: "surname"
    },
    {
        Header: "Vorname",
        accessor: "name"
    },

    {
        id: "company",
        Header: "Firma",
        accessor: (customer: Customer) => customer.company || "Privatkunde"
    },
]

interface ComponentProps {
    customers: Array<Customer>
}

type Props = ComponentProps

const CustomerTable = (props: Props) => (
    <ReactTable
        columns={columns}
        data={props.customers}
        SubComponent={(customer: any) => (<CustomerToolbar customer={customer.original} />)}
        sortable
        defaultSorted={[{
            id   : 'surname',
            asc : true,
          }]}
        defaultPageSize={100}
        pageSizeOptions={[50, 100, 250, 500, 1000]}
        noDataText="Keine Kunden gefunden!"
        pageText="Seite"
        ofText="von"
        previousText="Vorherige"
        nextText="Nächste"
        rowsText="Zeilen"
        className="-striped -highlight customers-table" />
)

export default CustomerTable
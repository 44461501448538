import React, {DragEvent} from "react"
import MasterdataTreeNode from "../../typescript/objects/MasterdataTreeNode"
import SamplingTreeNode from "../../typescript/objects/SamplingTreeNode"
import ListGroupItem from "reactstrap/lib/ListGroupItem"
import Button from "reactstrap/lib/Button"
import Icon from "./Icon"
import {Collapse} from "reactstrap"
import ContainerDimensions from "react-container-dimensions"
import {Flags} from "../../typescript/constants/Flags";

interface Props {
    node: MasterdataTreeNode | SamplingTreeNode
    dragAndDrop?: boolean
    parent?: MasterdataTreeNode | SamplingTreeNode
    getPrefix?(node: MasterdataTreeNode | SamplingTreeNode): JSX.Element
    getToolbar?(node: MasterdataTreeNode | SamplingTreeNode): JSX.Element
    depth?: number
    isExpanded(node: MasterdataTreeNode | SamplingTreeNode): boolean
    toggle(node: MasterdataTreeNode | SamplingTreeNode): void
    disableLeafs?: boolean
    onLeafClick?(node: MasterdataTreeNode | SamplingTreeNode): void
    onParentClick?(node: MasterdataTreeNode | SamplingTreeNode): void
    shallRender?(node: MasterdataTreeNode | SamplingTreeNode, parent?: MasterdataTreeNode | SamplingTreeNode): boolean
    customTitle?(node: MasterdataTreeNode | SamplingTreeNode): string
    onDragOver?(event: DragEvent<HTMLButtonElement>): void
    onDragStart?(event: DragEvent<HTMLButtonElement>, node: MasterdataTreeNode | SamplingTreeNode): void
    onDrop?(event: DragEvent<HTMLButtonElement>, node: MasterdataTreeNode | SamplingTreeNode): void
}

const NodeNotExpandedIcon = <Icon type="fas" icon="angle-right" />
const NodeExpandedIcon = <Icon type="fas" icon="angle-down" />

const TreeNode = (props: Props) => (
    <>
        {(props.shallRender ? props.shallRender(props.node) : true) &&
            <>
                <ListGroupItem
                    draggable={props.dragAndDrop || false}
                    onDragOver={(event: DragEvent<HTMLButtonElement>) => props.dragAndDrop && props.onDragOver && props.onDragOver(event)}
                    onDragStart={(event: DragEvent<HTMLButtonElement>) => props.dragAndDrop && props.onDragStart && props.onDragStart(event, props.node)}
                    onDrop={(event: DragEvent<HTMLButtonElement>) => props.dragAndDrop && props.onDrop && props.onDrop(event, props.node)}

                    className={`${((props.depth || 1) & 1) !== 0 ? "even-node" : "odd-node"}`}
                    style={{ paddingLeft: `${(props.depth || 0) * 10}px` }}
                    key={props.node.id}
                >
                    <ContainerDimensions>
                        {
                            ({ width, height }: { width: number, height: number }) => (
                                <Button
                                    style={{ maxWidth: `${(width - (props.node.isOrganisational() ? 0 : 100)) - ((props.depth || 0) * 5)}px`, overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                                    disabled={props.disableLeafs && !props.node.hasChildren()}
                                    size="sm"
                                    onClick={() => {
                                        if (props.node.hasChildren()) {
                                            props.toggle(props.node)
                                            props.onParentClick && props.onParentClick(props.node)
                                            return
                                        }

                                        props.onLeafClick && props.onLeafClick(props.node)
                                    }}
                                    className="treenode-title"
                                >
                                    <span style={{ color: props.node.hasFlag(Flags.DISABLED) ? "red" : "black" }}>
                                        {props.node.hasChildren() && (props.shallRender ? (props.node.children as Array<any>).filter(props.shallRender as any).length > 0 : true) && (props.isExpanded(props.node) ? NodeExpandedIcon : NodeNotExpandedIcon)}
                                        &nbsp;{props.getPrefix && props.getPrefix(props.node)}
                                        &nbsp;{props.customTitle ? props.customTitle(props.node) : props.node.title}
                                    </span>
                                </Button>
                            )
                        }
                    </ContainerDimensions>

                    {props.getToolbar &&
                        <div className="float-right">
                            {props.getToolbar(props.node)}
                        </div>
                    }
                </ListGroupItem>

                {props.node.hasChildren() && props.isExpanded(props.node) &&
                    <Collapse isOpen={props.isExpanded(props.node)}>
                        {(props.node.children as Array<any>).map((child: MasterdataTreeNode | SamplingTreeNode) => (
                            <TreeNode
                                dragAndDrop={props.dragAndDrop}
                                onDragOver={props.onDragOver}
                                onDragStart={props.onDragStart}
                                onDrop={props.onDrop}
                                parent={props.node}
                                customTitle={props.customTitle}
                                onParentClick={props.onParentClick}
                                onLeafClick={props.onLeafClick}
                                shallRender={props.shallRender}
                                disableLeafs={props.disableLeafs}
                                key={`treenode-${child.id}`}
                                node={child}
                                getPrefix={props.getPrefix}
                                getToolbar={props.getToolbar}
                                depth={(props.depth || 1) + 1}
                                isExpanded={props.isExpanded}
                                toggle={props.toggle}
                            />
                        ))}
                    </Collapse>
                }
            </>
        }
    </>
)

export default TreeNode
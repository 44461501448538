import React from "react"
import {UncontrolledButtonDropdown} from "reactstrap/lib/Uncontrolled"
import DropdownToggle from "reactstrap/lib/DropdownToggle"
import DropdownMenu from "reactstrap/lib/DropdownMenu"
import DropdownItem from "reactstrap/lib/DropdownItem"
import Icon from "../../utils/Icon"

interface Props { }

const SamplingLegend = (props: Props) => (
    <UncontrolledButtonDropdown direction="left">
        <DropdownToggle caret color="info">
            <span>
                <Icon type="fas" icon="info-circle" />
                &nbsp;
                Legende
            </span>
        </DropdownToggle>

        <DropdownMenu id="sampling-legend">
            <DropdownItem header>Formen</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="circle" />&nbsp;Exklusives Element</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="square" />&nbsp;Kumulatives Element</DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>Farben</DropdownItem>
            <DropdownItem disabled><span className="text-red">Rot</span> - Nicht bemustert</DropdownItem>
            <DropdownItem disabled><span className="text-yellow">Gelb</span> - Teilweise bemustert</DropdownItem>
            <DropdownItem disabled><span className="text-green">Grün</span> - Bemustert</DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>Aktionen</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="times" />&nbsp;Nicht erforderlich</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="ellipsis-h" />&nbsp;Erweiterte Optionen</DropdownItem>
        </DropdownMenu>
    </UncontrolledButtonDropdown>
)

export default SamplingLegend
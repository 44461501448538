import SamplingTreeNode from "../objects/SamplingTreeNode";
import {States} from "../constants/States";
import {Behaviours} from "../constants/Behaviours";
import {TreeNode, walkUpFrom} from "./TreeUtils";
import {StateReasonFlags} from '../constants/Flags';

const disableNode = (node: SamplingTreeNode) => {
    node.state = States.NOT_SAMPLED
    node.flags = node.flags.filter(flag => StateReasonFlags.indexOf(flag) === -1)
    node.children.forEach(disableNode)
}

const skipNode = (node: SamplingTreeNode) => {
    node.state = States.SKIPPED
    node.flags = node.flags.filter(flag => StateReasonFlags.indexOf(flag) === -1)
    node.children.forEach(skipNode)
}

export const reconcileTree = (tree: SamplingTreeNode, node: SamplingTreeNode) => {
    const wrappedTree = [tree]

    wrappedTree.forEach(child => {
        walkUpFrom(child, node, (leaf: TreeNode) => reconcileNode(tree, leaf as SamplingTreeNode))
    })
}

const reconcileNode = (tree: SamplingTreeNode, node: SamplingTreeNode) => {
    if (node.isLeaf()) {
        reconcileLeaf(tree, node)
        return
    }

    reconcileParent(tree, node)
}

const reconcileParent = (tree: SamplingTreeNode, parent: SamplingTreeNode) => {
    const siblings = tree.getSiblingsOf(parent)
    const childrenBehaviour = parent.children[0].behaviour

    // Some flags lead to instant sampling
    if (parent.isNotMandatory() || parent.isCustomerPerformance()) {
        parent.state = States.SAMPLED
        parent.children.forEach(skipNode)

        // When an exclusive node is sampled, its siblings must be disabled
        if (parent.behaviour === Behaviours.EXCLUSIVE) {
            siblings.forEach(disableNode)
        }
        return
    }

    // Resets skipped state
    if (!(parent.isNotMandatory() || parent.isCustomerPerformance()) && parent.children[0].state === States.SKIPPED) {
        disableNode(parent)
        return
    }

    // Don't reconcile skipped parents
    if (parent.state === States.SKIPPED) return

    // If at least one child is sampled or partly sampled, the parent shall be partly sampled
    if (parent.children.filter(child => child.state === States.SAMPLED || child.state === States.PARTLY_SAMPLED).length > 0) {
        parent.state = States.PARTLY_SAMPLED
    }

    // If all children are sampled, the parent shall be sampled too (cumulative children behaviour)
    if (childrenBehaviour === Behaviours.CUMULATIVE && parent.children.filter(child => child.state === States.SAMPLED).length === parent.children.length) {
        parent.state = States.SAMPLED
    }

    // If any children is sampled, the parent shall be sampled too (exclusive children behaviour)
    if (childrenBehaviour === Behaviours.EXCLUSIVE && parent.children.filter(child => child.state === States.SAMPLED).length > 0) {
        parent.state = States.SAMPLED
    }

    // If no children is sampled or partly sampled, the parent shall not be sampled
    if (parent.children.filter(child => child.state === States.SAMPLED || child.state === States.PARTLY_SAMPLED).length <= 0) {
        disableNode(parent)
    }

    // When an exclusive node is sampled, its siblings must be disabled
    if (parent.behaviour === Behaviours.EXCLUSIVE && parent.state !== States.NOT_SAMPLED) {
        siblings.forEach(disableNode)
    }
}

const reconcileLeaf = (tree: SamplingTreeNode, leaf: SamplingTreeNode) => {
    const siblings = tree.getSiblingsOf(leaf)

    // Some flags lead to instant sampling
    if (leaf.isNotMandatory() || leaf.isCustomerPerformance() || (leaf.isCommentRequired() && leaf.hasComment())) {
        leaf.state = States.SAMPLED
        leaf.children.forEach(skipNode)

        // When an exclusive node is sampled, its siblings must be disabled
        if (leaf.behaviour === Behaviours.EXCLUSIVE) {
            siblings.forEach(disableNode)
        }
        return
    }

    // Resets skipped state
    if (!(leaf.isNotMandatory() || leaf.isCustomerPerformance() || (leaf.isCommentRequired() && leaf.hasComment())) && leaf.state === States.SKIPPED) {
        disableNode(leaf)
        return
    }

    // Don't reconcile skipped leafs
    if (leaf.state === States.SKIPPED) return

    if (leaf.isLeafSelected()) {
        // User Selection triggers an instant sampling on leafs
        leaf.state = States.SAMPLED

        // When an exclusive node gets selected, its siblings must be disabled
        if (leaf.behaviour === Behaviours.EXCLUSIVE) {
            siblings.forEach(disableNode)
        }

        // One Exception: When the leaf requires a comment, but there is no comment yet, we overwrite its state and set it as partly sampled
        if (leaf.isCommentRequired() && !leaf.hasComment()) {
            leaf.state = States.PARTLY_SAMPLED
        }

        return
    }

    // If the leaf gets unselected, it shall be disabled
    disableNode(leaf)
}
import User from '../../typescript/objects/User'
import {AnyAction, Reducer} from "redux"
import * as BackendAPI from "../../typescript/backend/BackendAPI"
import {all, call, fork, put, takeEvery, takeLatest} from "redux-saga/effects"
import {AxiosResponse} from "axios"
import {BackendServer} from "../../typescript/objects/BackendServer"
import {BACKEND_SERVERS} from "../../typescript/config/config"
import {push} from 'connected-react-router'

const initialState: AuthenticationState = {
    availableBackends: BACKEND_SERVERS,
    backend: BACKEND_SERVERS[0],
    authenticated: false,
    busy: false
}

export const reducer: Reducer<AuthenticationState> = (state: AuthenticationState = initialState, action) => {
    switch (action.type) {
        case AuthenticationActionTypes.LOGIN_START:
        case AuthenticationActionTypes.REFRESH_START:
            return { ...state, busy: true }

        case AuthenticationActionTypes.LOGIN_FAILURE:
        case AuthenticationActionTypes.REFRESH_FAILURE:
            return { ...state, busy: false, error: action.error }

        case AuthenticationActionTypes.LOGIN_SUCCESS:
            return { ...state, busy: false, error: undefined, authenticated: true, token: action.token, user: action.user }

        case AuthenticationActionTypes.REFRESH_SUCCESS:
            const token = action.token
            const rawUser = JSON.parse(atob(token.split(".")[1])).user
            const user = new User(rawUser)

            return { ...state, busy: false, error: undefined, authenticated: true, token, user }

        case AuthenticationActionTypes.LOGOUT_SUCCESS:
            return { ...initialState, availableBackends: state.availableBackends, backend: state.backend }

        case AuthenticationActionTypes.SET_BACKEND:
            return { ...state, backend: action.backend }

        default:
            return state
    }
}

export interface AuthenticationState {
    readonly availableBackends: Array<BackendServer>,
    readonly backend: BackendServer,
    readonly user?: User,
    readonly token?: string,
    readonly authenticated: boolean,

    readonly busy: boolean,
    readonly error?: string
}

export enum AuthenticationActionTypes {
    LOGIN_REQUEST = "@authentication/login/request",
    LOGIN_START = "@authentication/login/start",
    LOGIN_SUCCESS = "@authentication/login/success",
    LOGIN_FAILURE = "@authentication/login/failure",

    REFRESH_REQUEST = "@authentication/refresh/request",
    REFRESH_START = "@authentication/refresh/start",
    REFRESH_SUCCESS = "@authentication/refresh/success",
    REFRESH_FAILURE = "@authentication/refresh/failure",

    LOGOUT_REQUEST = "@authentication/logout/request",
    LOGOUT_SUCCESS = "@authentication/logout/success",

    SET_BACKEND = "@authentication/set_backend"
}

export type AuthenticationAction =
    { type: AuthenticationActionTypes.LOGIN_REQUEST, user: string, password: string } |
    { type: AuthenticationActionTypes.REFRESH_REQUEST } |
    { type: AuthenticationActionTypes.LOGOUT_REQUEST } |
    { type: AuthenticationActionTypes.LOGIN_START } |
    { type: AuthenticationActionTypes.REFRESH_START } |
    { type: AuthenticationActionTypes.LOGIN_FAILURE, error: string } |
    { type: AuthenticationActionTypes.REFRESH_FAILURE, error: string } |
    { type: AuthenticationActionTypes.LOGIN_SUCCESS, user: User, token: string } |
    { type: AuthenticationActionTypes.REFRESH_SUCCESS, token: string } |
    { type: AuthenticationActionTypes.LOGOUT_SUCCESS } |
    { type: AuthenticationActionTypes.SET_BACKEND, backend: BackendServer }

/* Watchers & Action Creators */

// Login

function* loginWatcher() {
    yield takeEvery(AuthenticationActionTypes.LOGIN_REQUEST, login)
}

function* login(action: AnyAction): any {
    yield put(loginStart())

    const user: string = action.user
    const password: string = action.password

    try {
        const result: AxiosResponse = yield call(BackendAPI.login(user, password))
        const token: string = result.data.token
        const tokenParts: Array<string> = token.split(".")
        const payload: any = JSON.parse(atob(tokenParts[1]))

        // TODO: extract token expiry, ip, etc. from payload
        const authenticatedUser = new User(payload.user)

        // Signalise a successful login
        yield put(loginSuccess(token, authenticatedUser))

        // Redirect the user away from the login page
        yield put(push("/"))
    } catch (error) {
        const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
        yield put(loginFailure(message))
        console.error(message)
    }
}

const loginStart = () => ({ type: AuthenticationActionTypes.LOGIN_START })

const loginSuccess = (token: string, user: User) => ({ type: AuthenticationActionTypes.LOGIN_SUCCESS, token, user })

const loginFailure = (error: string) => ({ type: AuthenticationActionTypes.LOGIN_FAILURE, error })

// Refresh

function* refreshWatcher() {
    yield takeEvery(AuthenticationActionTypes.REFRESH_REQUEST, refresh)
}

function* refresh(action: AnyAction) {
    yield put(refreshStart())

    try {
        const result: AxiosResponse = yield call(BackendAPI.refresh())
        const token = result.data.token
        const tokenParts: Array<string> = token.split(".")
        const rawUser: object = JSON.parse(atob(tokenParts[1]))

        const user = new User(rawUser)

        yield put(refreshSuccess(token, user))
    } catch (error) {
        yield put(refreshFailure(JSON.stringify(error)))
        console.error(JSON.stringify(error))
    }
}

const refreshStart = () => ({ type: AuthenticationActionTypes.REFRESH_START })

const refreshSuccess = (token: string, user: User) => ({ type: AuthenticationActionTypes.REFRESH_SUCCESS, token, user })

const refreshFailure = (error: string) => ({ type: AuthenticationActionTypes.REFRESH_FAILURE, error })

function* logoutSaga() {
    yield takeLatest(AuthenticationActionTypes.LOGOUT_REQUEST, function* (action: AuthenticationAction) {
        // Signalise successful logout
        yield put({ type: AuthenticationActionTypes.LOGOUT_SUCCESS })

        // Send the user to the login page
        yield put(push("/login"))
    })
}

// TODO: Check the token validity and fire an logout action, if necessary.

// Export all watchers as a Saga
export function* saga() {
    yield all([
        fork(loginWatcher),
        fork(refreshWatcher),
        fork(logoutSaga)
    ])
}
import React, {Component} from "react"
import {connect} from "react-redux"
import {Button, ButtonGroup, Label, ListGroup, ListGroupItem, Row} from "reactstrap"
import {ApplicationState} from "../../../state/store"
import {Dispatch} from "redux"
import "./projects.sass"
import Project from "../../../typescript/objects/Project"
import {Form, FormState, FormValues, Option, Select, Text} from 'informed'
import Icon from "../../utils/Icon"
import {ProjectsActionTypes} from "../../../state/ducks/projects.duck"
import Col from "reactstrap/lib/Col"
import Customer from "../../../typescript/objects/Customer"
import User from "../../../typescript/objects/User"
import {SamplingActionTypes} from "../../../state/ducks/sampling.duck"
import FormGroup from 'reactstrap/lib/FormGroup'
import FormValidators from "../../../typescript/utils/FormValidators"
import FormFeedback from "reactstrap/lib/FormFeedback"
import {Roles} from "../../../typescript/constants/Roles";

interface State {
    edit: boolean
}

interface ComponentProps {
    project: Project
}

interface ReduxProps {
    user: User
    users: Array<User>
    customers: Array<Customer>
}

interface ReduxActions {
    selectProject(project: Project): void
    editProject(project: Project): void
    deleteProject(project: Project): void
    completeProject(project: Project): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class ProjectToolbar extends Component<Props, State> {

    state: State = {
        edit: false
    }

    requestDeletion = () => window.confirm("Wirklich löschen?") && this.props.deleteProject(this.props.project)

    toggleEditing = () => this.setState((state) => ({ edit: !state.edit }))

    reset = () => this.toggleEditing()

    save = (values: FormValues) => {
        if (!this.state.edit) return

        // Prepare a new Project
        const project = new Project(this.props.project)

        // Swap the necessary fields with the form values
        project.name = values.name.toString()
        project.customer = new Customer(this.props.customers.find(customer => customer.id === values.customer))
        project.street = values.street.toString()
        project.houseNumber = values.houseNumber.toString()
        project.zipCode = values.zipCode.toString()
        project.city = values.city.toString()
        project.manager = new User(this.props.users.find(user => user.id === values.manager))
        project.year = parseInt(values.year.toString())

        // Trigger the Project Editing
        this.props.editProject(project)

        // Disable further Editing
        this.toggleEditing()
    }

    isProjectAdmin = (user: User, project: Project): boolean => {
        return user.hasRole(Roles.MANAGE_PROJECTS) || user.admin || user.equals(project.manager) || user.hasRole(Roles.MANAGE_PROJECT_WITH_ID + project.id)
    }

    render = () => (
        <ListGroup className="table-row">
            <Form onSubmit={this.save} onResetCapture={this.reset}>
                {({ formState }: { formState: FormState<FormValues> }) => (
                    <>
                        <Row>
                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="name">Bezeichnung</Label>
                                        <Text field="name"
                                            disabled={!this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                            initialValue={this.props.project.name}
                                            className={`form-control${FormValidators.validateText(formState.values.name) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateText}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateText(formState.values.name)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="year">Baujahr</Label>
                                        <Text field="year"
                                            disabled={!this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                            initialValue={this.props.project.year}
                                            className={`form-control${FormValidators.validateNumber(formState.values.year) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateText}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateNumber(formState.values.year)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="street">Straße</Label>
                                        <Text field="street"
                                            disabled={!this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                            initialValue={this.props.project.street}
                                            className={`form-control${FormValidators.validateText(formState.values.street) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateText}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateText(formState.values.street)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="houseNumber">Hausnummer</Label>
                                        <Text field="houseNumber"
                                            disabled={!this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                            initialValue={this.props.project.houseNumber}
                                            className={`form-control${FormValidators.validateHouseNumber(formState.values.houseNumber) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateHouseNumber}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateHouseNumber(formState.values.houseNumber)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="zipCode">Postleitzahl</Label>
                                        <Text field="zipCode"
                                            disabled={!this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                            initialValue={this.props.project.zipCode}
                                            className={`form-control${FormValidators.validateZipCode(formState.values.zipCode) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateZipCode}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateZipCode(formState.values.zipCode)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="city">Bauort</Label>
                                        <Text field="city"
                                            disabled={!this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                            initialValue={this.props.project.city}
                                            className={`form-control${FormValidators.validateText(formState.values.city) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateText}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateText(formState.values.city)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <Label htmlFor="customer">Kunde</Label>
                                    <Select
                                        field="customer"
                                        disabled={!this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                        initialValue={this.props.project.customer.id}
                                        className="form-control"
                                    >
                                        <Option disabled>Bitte auswählen...</Option>
                                        {this.props.customers.map(customer => (
                                            <Option value={customer.id} key={customer.id}>{customer.name} {customer.surname}</Option>
                                        ))}
                                    </Select>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <Label htmlFor="manager">Bauleiter</Label>
                                    <Select
                                        field="manager"
                                        disabled={!this.state.edit || !this.props.user.hasRole(Roles.MANAGE_PROJECTS)}
                                        initialValue={this.props.project.manager.id}
                                        className="form-control"
                                    >
                                        <Option disabled>Bitte auswählen...</Option>
                                        {this.props.users.map(user => (
                                            <Option value={user.id} key={user.id}>{user.name} {user.surname}</Option>
                                        ))}
                                    </Select>
                                </ListGroupItem>
                            </Col>
                        </Row>

                        <ListGroupItem>
                            <ButtonGroup>
                                {!this.state.edit &&
                                    <Button
                                        color="danger"
                                        disabled={this.state.edit || !this.isProjectAdmin(this.props.user, this.props.project)}
                                        onClick={this.requestDeletion}
                                    >
                                        <i className="fas fa-trash"></i>&nbsp;Löschen
                                    </Button>
                                }

                                {this.state.edit &&
                                    <>
                                        <Button
                                            type="reset"
                                            color="danger"
                                        >
                                            <span>
                                                <Icon type="fas" icon="ban" />
                                                &nbsp;
                                                Abbrechen
                                            </span>
                                        </Button>

                                        <Button
                                            disabled={formState.invalid}
                                            type="submit"
                                            color="success"
                                        >
                                            <span>
                                                <Icon type="fas" icon="save" />
                                                &nbsp;
                                                Speichern
                                            </span>
                                        </Button>
                                    </>
                                }

                                {!this.state.edit &&
                                    <>
                                        <Button
                                            color="warning"
                                            onClick={this.toggleEditing}
                                            disabled={!this.isProjectAdmin(this.props.user, this.props.project)}
                                        >
                                            <span>
                                                <Icon type="fas" icon="edit" />
                                                &nbsp;
                                                Bearbeiten
                                            </span>
                                        </Button>

                                        <Button
                                            color="primary"
                                            disabled={!this.isProjectAdmin(this.props.user, this.props.project)}
                                            onClick={() => this.props.selectProject(this.props.project)}
                                        >
                                            <span>
                                                <Icon type="fas" icon="paste" />
                                                &nbsp;
                                                Bemustern
                                            </span>
                                        </Button>

                                        <Button
                                            color="info"
                                            disabled={!this.isProjectAdmin(this.props.user, this.props.project)}
                                            onClick={() => this.props.completeProject(this.props.project)}
                                        >
                                            <span>
                                                <Icon type="fas" icon="clipboard-check" />
                                                &nbsp;
                                                Abgeschlossen
                                            </span>
                                        </Button>
                                    </>
                                }
                            </ButtonGroup>
                        </ListGroupItem>
                    </>
                )}
            </Form>
        </ListGroup>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    user: state.authentication.user!,
    users: state.users.users,
    customers: state.customers.customers
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    selectProject: (project: Project) => dispatch({ type: SamplingActionTypes.SET_PROJECT_FOR_SAMPLING, project }),
    editProject: (project: Project) => dispatch({ type: ProjectsActionTypes.EDIT_PROJECT_REQUEST, project }),
    deleteProject: (project: Project) => dispatch({ type: ProjectsActionTypes.DELETE_PROJECT_REQUEST, project }),
    completeProject: (project: Project) => dispatch({ type: ProjectsActionTypes.COMPLETE_PROJECT_REQUEST, project }),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectToolbar)
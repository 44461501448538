export enum Flags {
    PERFORMED_BY_CUSTOMER = "performed_by_customer",
    NOT_MANDATORY = "not_mandatory",
    CUSTOM_WISH = "custom_wish",
    SPECIAL_PRICING = "special_pricing",
    COMMENT_REQUIRED = "comment_required",
    NAUTICAL = "nautical",
    ORGANISATIONAL = "organisational",
    EXCLUDED_FROM_PROTOCOL = "excluded_from_protocol",
    LEAF_SELECTED = "leaf_selected",
    CLONE = "clone",
    DISABLED = "disabled"
}

export const StateReasonFlags = [
    Flags.CUSTOM_WISH,
    Flags.NOT_MANDATORY,
    Flags.PERFORMED_BY_CUSTOMER,
    Flags.LEAF_SELECTED,
    Flags.CUSTOM_WISH
]
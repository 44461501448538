import React, {Component} from "react"
import {connect} from "react-redux"
import {ApplicationState} from '../state/store'
import {Dispatch} from "redux";
import {ChronicleActionTypes} from "../state/ducks/chronicle.duck"
import ChronicleEntryTable from "../components/routes/chronicle/ChronicleEntryTable"
import ChronicleEntry from "../typescript/objects/ChronicleEntry";
import Helmet from "react-helmet";

interface State { }

interface ComponentProps { }

interface ReduxProps {
    readonly entries: Array<ChronicleEntry>
}

interface ReduxActions {
    loadChronicleEntries(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class ChronicleView extends Component<Props, State> {

    componentDidMount = () => {
        this.props.loadChronicleEntries()
    }

    render = () => (
        <>
            <Helmet
                title="Chronik - Heimerl 4.0"
                meta={[{ name: "description", content: "Chronik" }]}
            />
            
            <ChronicleEntryTable entries={this.props.entries} />
        </>
    )

}

const mapStateToProps = (state: ApplicationState) => ({
    entries: state.chronicle.entries
})

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadChronicleEntries: () => dispatch({ type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(ChronicleView)
import {v4} from "node-uuid"

class Customer {

    id: string = v4()
    name: string
    surname: string
    email: string
    phone: string
    creator: string
    company?: string = ""
    enabled: boolean = true
    createdAt: Date = new Date()
    modifiedAt: Date = new Date()

    isPrivate = (): boolean => !this.company || this.company.trim().length <= 0
    equals = (customer: Customer) => this.id === customer.id

    constructor(input: any) {
        this.name = input.name
        this.surname = input.surname
        this.creator = input.creator

        if (input.id) this.id = input.id
        if (input.enabled) this.enabled = input.enabled
        if (input.email) this.email = `${input.email}`.trim().toLowerCase()
        if (input.phone) this.phone = `${input.phone}`.trim().toLowerCase()
        if (input.company) this.company = input.company
        if (input.createdAt) this.createdAt = new Date(input.createdAt)
        if (input.modifiedAt) this.modifiedAt = new Date(input.modifiedAt)
    }

}

export default Customer
import React, {Component} from "react"
import {connect} from "react-redux"
import {Button, ButtonGroup, Label, ListGroup, ListGroupItem, Row} from "reactstrap"
import {ApplicationState} from "../../../state/store"
import {Dispatch} from "redux"
import "./customers.sass"
import Customer from "../../../typescript/objects/Customer"
import {Form, FormState, FormValues, Text} from "informed"
import Icon from "../../utils/Icon"
import {CustomersActionTypes} from "../../../state/ducks/customers.duck"
import Col from "reactstrap/lib/Col"
import FormGroup from "reactstrap/lib/FormGroup";
import FormValidators from "../../../typescript/utils/FormValidators";
import FormFeedback from "reactstrap/lib/FormFeedback";

interface State {
    edit: boolean
}

interface ComponentProps {
    customer: Customer
}

interface ReduxProps { }

interface ReduxActions {
    editCustomer(customer: Customer): void
    deleteCustomer(customer: Customer): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class CustomerToolbar extends Component<Props, State> {

    state: State = {
        edit: false
    }

    requestDeletion = () => window.confirm("Wirklich löschen?") && this.props.deleteCustomer(this.props.customer)

    toggleEditing = () => this.setState((state) => ({ edit: !state.edit }))

    reset = () => this.toggleEditing()

    save = (values: FormValues) => {
        if (!this.state.edit) return

        // Prepare a new Customer
        const customer = new Customer(this.props.customer)

        // Swap the necessary fields with the form values
        customer.name = values.name.toString()
        customer.surname = values.surname.toString()
        customer.email = (values.email || "").toString() // email is optional
        customer.phone = (values.phone || "").toString() // phone is optional
        customer.company = (values.company || "").toString() // company is optional

        // Trigger the Customer Editing
        this.props.editCustomer(customer)

        // Disable further Editing
        this.toggleEditing()
    }

    render = () => (
        <ListGroup className="table-row">
            <Form onSubmit={this.save} onResetCapture={this.reset}> 
                {({ formState }: { formState: FormState<FormValues> }) => (
                    <>
                        <Row>
                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="name">Vorname</Label>
                                        <Text field="name"
                                            disabled={!this.state.edit}
                                            initialValue={this.props.customer.name}
                                            className={`form-control${FormValidators.validateText(formState.values.name) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateText}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateText(formState.values.name)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="surname">Nachname</Label>
                                        <Text field="surname"
                                            disabled={!this.state.edit}
                                            initialValue={this.props.customer.surname}
                                            className={`form-control${FormValidators.validateText(formState.values.surname) === undefined ? " is-valid" : " is-invalid"}`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateText}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateText(formState.values.surname)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="email">Email</Label>
                                        <Text field="email"
                                            disabled={!this.state.edit}
                                            initialValue={this.props.customer.email}
                                            className={`form-control${FormValidators.validateOptionalEmail(formState.values.email) === undefined ? " is-valid" : " is-invalid"} optional`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateOptionalEmail}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateOptionalEmail(formState.values.email)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="phone">Telefon</Label>
                                        <Text field="phone"
                                            disabled={!this.state.edit}
                                            initialValue={this.props.customer.phone}
                                            className={`form-control${FormValidators.validateOptionalPhone(formState.values.phone) === undefined ? " is-valid" : " is-invalid"} optional`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateOptionalPhone}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateOptionalPhone(formState.values.phone)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>

                            <Col md={6} xs={12}>
                                <ListGroupItem className="list-item-data">
                                    <FormGroup>
                                        <Label htmlFor="company">Firma</Label>
                                        <Text field="company"
                                            disabled={!this.state.edit}
                                            initialValue={this.props.customer.company}
                                            className={`form-control${FormValidators.validateOptionalText(formState.values.company) === undefined ? " is-valid" : " is-invalid"} optional`}
                                            validateOnChange
                                            validateOnMount
                                            validate={FormValidators.validateOptionalText}
                                        />
                                        <FormFeedback invalid="true">{FormValidators.validateOptionalText(formState.values.company)}</FormFeedback>
                                    </FormGroup>
                                </ListGroupItem>
                            </Col>
                        </Row>

                        <ListGroupItem>
                            <ButtonGroup>
                                <Button
                                    color="danger"
                                    disabled={this.state.edit}
                                    onClick={this.requestDeletion}
                                >
                                    <i className="fas fa-trash"></i>&nbsp;Löschen
                                </Button>

                                {this.state.edit &&
                                    <>
                                        <Button
                                            type="reset"
                                            color="danger"
                                        >
                                            <span>
                                                <Icon type="fas" icon="ban" />
                                                &nbsp;
                                                Abbrechen
                                            </span>
                                        </Button>

                                        <Button
                                            disabled={formState.invalid}
                                            type="submit"
                                            color="success"
                                        >
                                            <span>
                                                <Icon type="fas" icon="save" />
                                                &nbsp;
                                                Speichern
                                            </span>
                                        </Button>
                                    </>
                                }

                                {!this.state.edit &&
                                    <Button
                                        color="warning"
                                        onClick={this.toggleEditing}
                                    >
                                        <span>
                                            <Icon type="fas" icon="edit" />
                                            &nbsp;
                                            Bearbeiten
                                        </span>
                                    </Button>
                                }
                            </ButtonGroup>
                        </ListGroupItem>
                    </>
                )}
            </Form>
        </ListGroup>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({

})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    editCustomer: (customer: Customer) => dispatch({ type: CustomersActionTypes.EDIT_CUSTOMER_REQUEST, customer }),
    deleteCustomer: (customer: Customer) => dispatch({ type: CustomersActionTypes.DELETE_CUSTOMER_REQUEST, customer })
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerToolbar)
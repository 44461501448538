import {StoreSubscriber} from './StoreSubscriber'
import {Store} from 'redux'
import {ApplicationState} from '../store'
import axios from 'axios'

/*
    Whenever the authentication token changes, Axios' settings needs to be updated
*/
class AxiosTokenSubscriber implements StoreSubscriber {

    lastToken?: string

    accept = (store: Store<ApplicationState>) => {
        const currentToken = store.getState().authentication.token
        
        // If the token hasn't changed since the last call, we don't need to do anything here
        if (currentToken === this.lastToken) return

        // Update the token variable for the next call
        this.lastToken = currentToken

        // Update the Axios Settings to use the new token for authentication
        axios.defaults.headers["authorization"] = `Bearer: ${currentToken}`
    }

}

// We only need StoreSubscribers once, so we export it as a Singleton instance
export default new AxiosTokenSubscriber()

import {Behaviours} from "../constants/Behaviours"
import {v4} from "node-uuid"
import {States} from "../constants/States";
import {walkDownUntil} from "../utils/TreeUtils";
import {Flags} from "../constants/Flags";

class SamplingTreeNode {

    id: string = v4()
    title: string = "Neues Element"
    behaviour: Behaviours = Behaviours.EXCLUSIVE
    state: States = States.NOT_SAMPLED
    flags: Array<Flags> = []
    comment: string = ""
    pictures: Array<string> = []
    children: Array<SamplingTreeNode> = []

    equals = (node: SamplingTreeNode) => this.id === node.id

    hasComment = (): boolean => this.comment !== null && this.comment !== undefined && this.comment.trim().length > 0

    hasFlag = (flag: Flags): boolean => this.flags.indexOf(flag) !== -1

    isNautical = (): boolean => this.flags.indexOf(Flags.NAUTICAL) !== -1

    isCommentRequired = (): boolean => this.flags.indexOf(Flags.COMMENT_REQUIRED) !== -1

    isCustomerPerformance = (): boolean => this.flags.indexOf(Flags.PERFORMED_BY_CUSTOMER) !== -1

    isCustomWish = (): boolean => this.flags.indexOf(Flags.CUSTOM_WISH) !== -1

    isNotMandatory = (): boolean => this.flags.indexOf(Flags.NOT_MANDATORY) !== -1

    isSpecialPricing = (): boolean => this.flags.indexOf(Flags.SPECIAL_PRICING) !== -1

    isOrganisational = (): boolean => this.flags.indexOf(Flags.ORGANISATIONAL) !== -1

    isExcludedFromProtocol = (): boolean => this.flags.indexOf(Flags.EXCLUDED_FROM_PROTOCOL) !== -1

    isClone = (): boolean => this.flags.indexOf(Flags.CLONE) !== -1

    hasChildren = (): boolean => this.children && this.children.length > 0

    isEdgeElement = (): boolean => this.isNautical() && this.hasChildren() && this.children.filter(child => child.isNautical()).length === 0

    isLeaf = (): boolean => !this.hasChildren()

    isLeafSelected = (): boolean => this.flags.indexOf(Flags.LEAF_SELECTED) !== -1

    hasGrandChildren = (): boolean => this.hasChildren() && this.children.filter(child => child.hasChildren()).length > 0

    getReferenceOf = (id: string): SamplingTreeNode | undefined => {
        if (!this.containsNode(SamplingTreeNode.valueOf({id}))) return undefined
        var found = undefined

        walkDownUntil(this, SamplingTreeNode.valueOf({id}), (node) => {
            if (node.id !== id) return
            found = node
        })

        return found
    }

    getSiblingsOf = (node: SamplingTreeNode): Array<SamplingTreeNode> => {
        const parent = this.getParentOf(node)
        if (!parent) return []

        return parent.children.filter(child => !child.equals(node))
    }

    getParentOf = (node: SamplingTreeNode): SamplingTreeNode | undefined => {
        if (!this.containsNode(node)) return undefined
        var found = undefined

        walkDownUntil(this, node, (child) => {
            const ref = (child as SamplingTreeNode).getReferenceOf(node.id)
            if (!ref) return

            if ((child.children as Array<SamplingTreeNode>).indexOf(ref) !== -1) {
                found = child
            }
        })

        return found
    }

    containsNode = (node: SamplingTreeNode): boolean => {
        if (this.id === node.id) return true
        if (!this.hasChildren()) return false

        for (let child of this.children) {
            if (child.containsNode(node)) return true
        }

        return false
    }

    static valueOf = (input: any): SamplingTreeNode => {
        const node = new SamplingTreeNode()

        node.title = input.title
        node.behaviour = input.behaviour

        if (input.state) node.state = input.state
        if (input.id) node.id = input.id
        if (input.comment) node.comment = input.comment
        if (input.pictures) node.pictures = input.pictures
        if (input.flags) node.flags = input.flags
        if (input.children) node.children = (input.children as Array<any>).map(rawChild => SamplingTreeNode.valueOf(rawChild))

        return node
    }

    static copy = (source: SamplingTreeNode): SamplingTreeNode => {
        const copy = new SamplingTreeNode()

        copy.title = source.title
        copy.behaviour = source.behaviour

        copy.state = source.state
        copy.comment = source.comment
        copy.pictures = [...source.pictures]
        copy.flags = [...source.flags]
        copy.children = source.children.map(SamplingTreeNode.copy)

        return copy
    }

    static cleanCopy = (source: SamplingTreeNode): SamplingTreeNode => {
        const copy = new SamplingTreeNode()

        copy.title = source.title
        copy.behaviour = source.behaviour

        copy.pictures = [...source.pictures]
        copy.flags = [...source.flags]
        copy.children = source.children.map(SamplingTreeNode.cleanCopy)

        return copy
    }

}

export default SamplingTreeNode
import React from "react"

import ReactTable from 'react-table'
import ProjectToolbar from "./ProjectToolbar"

import {connect} from "react-redux"
import {Dispatch} from "redux"

import "./projects.sass"
import {ApplicationState} from "../../../state/store"
import Project from "../../../typescript/objects/Project"

const columns = [
    {
        id: "customer",
        Header: "Kunde",
        accessor: (project: Project) => `${project.customer.surname} ${project.customer.name}`
    },
    {
        Header: "Baujahr",
        accessor: "year"/*,
        width: "50px"*/
    },
    {
        Header: "Bauort",
        accessor: "city"
    },
    {
        Header: "Bezeichnung",
        accessor: "name"
    },
    {
        id: "manager",
        Header: "Bauleiter",
        accessor: (project: Project) => `${project.manager.surname} ${project.manager.name}`
    }
]

interface ComponentProps {
    projects: Array<Project>
}

interface ReduxProps { }

interface ReduxActions { }

type Props = ComponentProps & ReduxProps & ReduxActions

const ProjectTable = (props: Props) => (
    <ReactTable
        columns={columns}
        data={props.projects}
        SubComponent={(project: any) => (<ProjectToolbar project={project.original} />)}
        sortable
        defaultSorted={[{
            id: "customer",
            asc: true
        }]}
        defaultPageSize={100}
        pageSizeOptions={[50, 100, 250, 500, 1000]}
        noDataText="Keine Bauvorhaben gefunden!"
        pageText="Seite"
        ofText="von"
        previousText="Vorherige"
        nextText="Nächste"
        rowsText="Zeilen"
        className="-striped -highlight projects-table" />
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({

})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({

})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectTable)
class ChronicleEntry {

    message: string
    source: string
    object: string
    createdAt: Date = new Date()

    constructor(input: any) {
        this.message = input.message
        this.source = input.source
        this.object = input.object
        this.createdAt = new Date(input.date)
    }

}

export default ChronicleEntry
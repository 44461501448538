import {applyMiddleware, compose, createStore, Store} from 'redux'
import createSagaMiddleware from "redux-saga"

import {composeWithDevTools} from "redux-devtools-extension"
import {routerMiddleware} from 'connected-react-router'
import timerMiddleware from "redux-timer"
import {createTransform, PersistConfig, persistReducer, persistStore} from "redux-persist"
import storage from "redux-persist/lib/storage"

import {ApplicationState, history, rootReducer, rootSaga} from './store'

import LoginRedirectionSubscriber from './subscribers/LoginRedirectionSubscriber'
import AxiosBackendSubscriber from './subscribers/AxiosBackendSubscriber'
import AxiosTokenSubscriber from './subscribers/AxiosTokenSubscriber'
import autoMergeLevel2 from 'redux-persist/es/stateReconciler/autoMergeLevel2'
import RefreshTimerSubscriber from './subscribers/RefreshTimerSubscriber';
import TokenExpirationSubscriber from './subscribers/TokenExpirationSubscriber';
import User from '../typescript/objects/User';

const composeEnhancer: typeof compose = composeWithDevTools({})
const sagaMiddleware = createSagaMiddleware()

// User Transformer
const userTransform = createTransform(
  (inboundState: any, key: any) => {
    return { ...inboundState, user: inboundState.user ? JSON.stringify(inboundState.user) : undefined }
  },
  (outboundState: any, key: any) => {
    return { ...outboundState, user: outboundState.user ? new User(JSON.parse(outboundState.user)) : undefined }
  }
)

// To allow us to change the locally cached data structure, we use a changeable key to invalidate any older entry, so the app won't "lock" or crash because of invalid cache data
const persistenceConfig: PersistConfig = {
  transforms: [userTransform],
  key: "heimerl-auth-2021-10-08",
  version: 4408102021, // Version (41 = 4.1) Month (12 = DEC) Year (2018)
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: ["authentication"]
}

const persistedReducer = persistReducer(persistenceConfig, rootReducer)

const configureStore = (): Store<ApplicationState> => {
  const store = createStore(
    persistedReducer,
    composeEnhancer(
      applyMiddleware(
        routerMiddleware(history),
        sagaMiddleware,
        timerMiddleware
      )
    )
  )

  return store
}

const store = configureStore()
const persistor = persistStore(store)

// Run our Root Saga
sagaMiddleware.run(rootSaga)

// Hook up any store subscribers
store.subscribe(() => LoginRedirectionSubscriber.accept(store))
store.subscribe(() => AxiosBackendSubscriber.accept(store))
store.subscribe(() => AxiosTokenSubscriber.accept(store))
store.subscribe(() => RefreshTimerSubscriber.accept(store))
store.subscribe(() => TokenExpirationSubscriber.accept(store))

export { persistor }
export default store
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import User from '../../../typescript/objects/User';
import Label from 'reactstrap/lib/Label';
import {Form, FormState, FormValues, Text} from 'informed';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../state/store';
import {UsersActionTypes} from '../../../state/ducks/users.duck';
import FormValidators from '../../../typescript/utils/FormValidators';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormFeedback from 'reactstrap/lib/FormFeedback';

interface State { }

interface ComponentProps { }

interface ReduxProps { }

interface PortalProps {
    onClose(): void
}

interface ReduxActions {
    createUser(user: User): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class UserCreationModal extends Component<Props, State> {

    save = (values: FormValues) => {
        // Prepare a new user
        const user = new User(values)

        // Trigger the User Creation
        this.props.createUser(user)

        // Close the Modal
        this.props.onClose()
    }

    render = () => (
        <Modal isOpen size="lg">
            <ModalHeader>
                Neuer Benutzer
            </ModalHeader>

            <Form onSubmit={this.save}>
                {({ formState }: { formState: FormState<FormValues> }) => (
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="username">Benutzername</Label>
                                <Text field="username"
                                    className={`form-control${FormValidators.validateUsername(formState.values.username) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateUsername}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateUsername(formState.values.username)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="name">Vorname</Label>
                                <Text field="name"
                                    className={`form-control${FormValidators.validateText(formState.values.name) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.name)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="surname">Nachname</Label>
                                <Text field="surname"
                                    className={`form-control${FormValidators.validateText(formState.values.surname) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.surname)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="email">E-Mail</Label>
                                <Text field="email"
                                    className={`form-control${FormValidators.validateEmail(formState.values.email) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateEmail}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateEmail(formState.values.email)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="password">Passwort</Label>
                                <Text field="password"
                                    type="password"
                                    className={`form-control${FormValidators.validatePassword(formState.values.password) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validatePassword}
                                />
                                <FormFeedback invalid="true">{FormValidators.validatePassword(formState.values.password)}</FormFeedback>
                            </FormGroup>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="danger" onClick={this.props.onClose}>Abbrechen</Button>
                            <Button color="primary" type="submit" disabled={formState.invalid}>Speichern</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    createUser: (user: User) => dispatch({ type: UsersActionTypes.CREATE_USER_REQUEST, user })
})

export default connect(mapStateToProps, mapDispatchToProps)(UserCreationModal)
import {Reducer} from "redux"

const initialState: SearchState = {
    text: ""
}

export const reducer: Reducer<SearchState> = (state: SearchState = initialState, action) => {
    switch (action.type) {
        case SearchActionTypes.SET_TEXT:
            return { ...state, text: action.text }

        case SearchActionTypes.CLEAR_TEXT:
            return { ...state, ...initialState }

        default:
            return state
    }
}

export interface SearchState {
    readonly text: string
}

export enum SearchActionTypes {
    SET_TEXT = "@search/set_text",
    CLEAR_TEXT = "@search/clear_text"
}

export type SearchSetTextAction = { type: SearchActionTypes.SET_TEXT, text: string }
export type SearchClearTextAction = { type: SearchActionTypes.CLEAR_TEXT }

export type SearchAction = SearchSetTextAction | SearchClearTextAction
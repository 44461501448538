import {StoreSubscriber} from './StoreSubscriber'
import {Store} from 'redux'
import {ApplicationState} from '../store'
import {AuthenticationActionTypes} from '../ducks/authentication.duck';

/*
    Whenever a user is authenticated, the token refresh timer should run.
*/
class RefreshTimerSubscriber implements StoreSubscriber {

    timerStarted = false

    // This only needs to run once, to setup the timer itself
    accept = (store: Store<ApplicationState>) => {
        if (this.timerStarted) return
        this.timerStarted = true

        window.setInterval(() => {
            // Calling getState will yield the most recent state of this store object (which will not change over time - so this is safe!)
            const authenticated = store.getState().authentication.authenticated

            // If the user is not authenticated, a refresh would be nonsense
            if (!authenticated) return

            // Trigger a token refresh
            store.dispatch({ type: AuthenticationActionTypes.REFRESH_REQUEST })
        }, 30000) // 30 seconds
    }

}

// We only need StoreSubscribers once, so we export it as a Singleton instance
export default new RefreshTimerSubscriber()

import React from "react"
import "./Footer.sass"
import Navigation from "../navigation/FooterNavigation"

const Footer = () => (
    <div id="footer">
        <Navigation />
    </div>
)

export default Footer
import React, {Component} from "react"
import Helmet from "react-helmet"
import SamplingTreeNode from "../typescript/objects/SamplingTreeNode"
import {ApplicationState} from "../state/store"
import {Dispatch} from "redux"
import {connect} from "react-redux"
import {SamplingActionTypes} from "../state/ducks/sampling.duck"
import SamplingTree from "../components/routes/sampling/SamplingTree"
import SamplingBar from "../components/routes/sampling/SamplingBar";
import Project from "../typescript/objects/Project";
import {ProjectsActionTypes} from "../state/ducks/projects.duck";

interface State { }

interface ComponentProps { }

interface ReduxProps {
    readonly tree: Array<SamplingTreeNode>
    readonly projectId?: string
    readonly projects: Array<Project>
}

interface ReduxActions {
    loadProjects(): void
    loadSampling(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class SamplingView extends Component<Props, State> {

    componentDidMount = () => {
        this.props.loadProjects()
        this.props.loadSampling()
    }

    render = () => (
        <>
            <Helmet
                title="Bemusterung - Heimerl 4.0"
                meta={[{ name: "description", content: "Bemusterung" }]}
            />

            {this.props.tree && this.props.projectId && this.props.projects.length > 0 && <SamplingBar project={this.props.projects.find(project => project.id === this.props.projectId)!} tree={this.props.tree} />}
            < hr />

            {
                this.props.tree && this.props.projectId && this.props.projects.length > 0 &&
                <div id="sampling">
                    <SamplingTree project={this.props.projects.find(project => project.id === this.props.projectId)!} tree={this.props.tree} />
                </div>
            }
        </>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    tree: state.sampling.tree,
    projectId: state.sampling.projectId,
    projects: state.projects.projects
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadProjects: () => dispatch({ type: ProjectsActionTypes.FETCH_PROJECTS_REQUEST }),
    loadSampling: () => dispatch({ type: SamplingActionTypes.FETCH_SAMPLING_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(SamplingView)
class Backup {

    readonly fileName: string
    readonly fileSize: number
    readonly creationDate: Date

    equals = (backup: Backup) => this.fileName === backup.fileName && this.fileSize === backup.fileSize && this.creationDate === backup.creationDate

    constructor(input: any) {
        this.fileName = input.fileName
        this.fileSize = input.fileSize
        this.creationDate = new Date(input.creationDate)
    }

}

export default Backup
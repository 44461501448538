import React from "react"
import Label from "reactstrap/lib/Label";
import Button from "reactstrap/lib/Button";
import "./LoginMask.sass"
import Media from "reactstrap/lib/Media";
import HeimerlBanner from "../../../images/heimerl.svg"
import {Form, FormValues, Option, Select, Text} from "informed"
import {BackendServer} from "../../../typescript/objects/BackendServer";
import {Row} from "reactstrap";
import Col from "reactstrap/lib/Col";

interface Props {
    readonly currentBackend: BackendServer
    readonly backends: Array<BackendServer>
    switchBackend(backend: BackendServer): void
    handleLogin(user: string, password: string): void
}

const LoginMask = (props: Props) => (
    <>
        <div id="login-mask">
            <Media src={HeimerlBanner} id="heimerl-banner" />

            <Form onSubmit={(values: FormValues) => props.handleLogin(`${values.user || ""}`, `${values.password || ""}`)}>
                <Label htmlFor="user">Benutzer</Label>
                <Text autoFocus field="user" className="form-control" />

                <br />

                <Label htmlFor="password">Passwort</Label>
                <Text type="password" field="password" className="form-control" />

                <hr />

                <Row>
                    <Col>
                        <Button type="submit" color="primary">Anmelden</Button>
                    </Col>

                    <Col>
                        <Select
                            initialValue={props.currentBackend.url}
                            onChange={(event: any) => props.switchBackend(props.backends[event.target.selectedIndex])}
                            field="backend"
                            className="form-control"
                        >
                            {props.backends.map(backend => (
                                <Option value={backend.url} key={backend.url}>{backend.name}</Option>
                            ))}
                        </Select>
                    </Col>
                </Row>
            </Form>
        </div>
    </>
)

export default LoginMask
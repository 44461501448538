import React from "react"
import {Button, Media, Nav, NavItem, UncontrolledCollapse} from "reactstrap"
import "./HeaderNavigation.sass"
import Icon from '../utils/Icon'
import NavbarToggler from "reactstrap/lib/NavbarToggler"
import Navbar from "reactstrap/lib/Navbar"
import {Link} from "react-router-dom"
import {ApplicationState} from "../../state/store"
import {Dispatch} from "redux"
import {connect} from "react-redux"
import User from "../../typescript/objects/User"
import {Roles} from "../../typescript/constants/Roles"
import brand from "../../images/heimerl_brand_clean.svg"
import {AuthenticationActionTypes} from "../../state/ducks/authentication.duck"
import Searchbar from "../searchbar/Searchbar"

interface ComponentProps {
}

interface ReduxProps {
    readonly authenticated: boolean
    readonly user?: User
    readonly project?: string
    readonly path: string
}

interface ReduxActions {
    logout(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

const Navigation = (props: Props) => (
    <>
        <Navbar id="header-navigation" dark expand="lg">
            <div id="brand" className="navbar-brand">
                <Media src={brand} id="logo"/>
            </div>

            <NavbarToggler id="header-navbar-toggler">
                <Icon type="fas" icon="bars"/>
            </NavbarToggler>

            <UncontrolledCollapse toggler="#header-navbar-toggler" navbar className="float-right">
                <Nav navbar className="mr-auto">
                    {props.authenticated && props.project &&
                        <NavItem>
                            <Link to="/sampling"
                                  className={`${props.path.startsWith("/sampling") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="tasks"/> <span>Bemusterung</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.project &&
                        <NavItem>
                            <Link to="/protocol"
                                  className={`${props.path.startsWith("/protocol") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="print"/> <span>Protokoll</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated &&
                        <NavItem>
                            <Link to="/" className={`${props.path === "/" ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="home"/> <span>Bauvorhaben</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.user && (props.user.hasRole(Roles.MANAGE_CUSTOMERS) || props.user.hasRole(Roles.CREATE_CUSTOMERS)) &&
                        <NavItem>
                            <Link to="/customers"
                                  className={`${props.path.startsWith("/customers") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="user-tie"/> <span>Kunden</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.user &&
                        <NavItem>
                            <Link to="/users"
                                  className={`${props.path.startsWith("/users") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="users-cog"/> <span>Benutzer</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.user && props.user.hasRole(Roles.MANAGE_MASTERDATA) &&
                        <NavItem>
                            <Link to="/masterdata"
                                  className={`${props.path.startsWith("/masterdata") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="database"/> <span>Stammdaten</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.user && props.user.hasRole(Roles.READ_CHRONICLE) &&
                        <NavItem>
                            <Link to="/chronicle"
                                  className={`${props.path.startsWith("/chronicle") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="history"/> <span>Chronik</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.user && props.user.hasRole(Roles.MANAGE_BACKUPS) &&
                        <NavItem>
                            <Link to="/backups"
                                  className={`${props.path.startsWith("/backups") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="far" icon="save"/> <span>Backups</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.user && props.user.hasRole(Roles.MANAGE_IMAGES) &&
                        <NavItem>
                            <Link to="/images"
                                  className={`${props.path.startsWith("/images") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="images"/> <span>Bilder</span>
                            </Link>
                        </NavItem>
                    }

                    {props.authenticated && props.user && props.user.hasRole(Roles.MANAGE_PROJECTS) &&
                        <NavItem>
                            <Link to="/archive"
                                  className={`${props.path.startsWith("/archive") ? "active nav-link" : "nav-link"}`}>
                                <Icon type="fas" icon="archive"/> <span>Archiv</span>
                            </Link>
                        </NavItem>
                    }
                </Nav>

                <Nav>
                    {props.authenticated &&
                        <>
                            <NavItem>
                                <Searchbar/>
                            </NavItem>

                            <NavItem>
                                <Button color="danger" id="logout-button" onClick={props.logout}>
                                    <Icon type="fas" icon="sign-out-alt"/>
                                </Button>
                            </NavItem>
                        </>
                    }
                </Nav>
            </UncontrolledCollapse>
        </Navbar>
    </>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    user: state.authentication.user,
    authenticated: state.authentication.authenticated,
    project: state.sampling.projectId,
    path: state.router.location.pathname
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    logout: () => dispatch({type: AuthenticationActionTypes.LOGOUT_REQUEST})
})

export default connect(mapStateToProps, mapDispatchToProps)(Navigation)
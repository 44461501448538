import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import Customer from '../../../typescript/objects/Customer'
import Label from 'reactstrap/lib/Label'
import {Checkbox, Form, FormState, FormValues, Text} from 'informed'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {ApplicationState} from '../../../state/store'
import {CustomersActionTypes} from '../../../state/ducks/customers.duck'
import FormGroup from 'reactstrap/lib/FormGroup';
import FormValidators from '../../../typescript/utils/FormValidators';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import {withPortal} from "react-portalgun"
import {createProject} from '../../../typescript/backend/BackendAPI';

interface State { }

interface ComponentProps { }

interface ReduxProps { }

interface PortalProps {
    onClose(): void
    createProject(params: any): void
}

interface ReduxActions {
    createCustomer(customer: Customer): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class CustomerCreationModal extends Component<Props, State> {

    save = (values: FormValues) => {
        const createProject = values.create_project as boolean

        // Prepare a new customer
        const customer = new Customer(values)

        // Trigger the Customer Creation
        this.props.createCustomer(customer)

        // Close the Modal
        this.props.onClose()

        if (createProject) {
            setTimeout(() => this.props.createProject({}), 1500)
        }
    }

    render = () => (
        <Modal isOpen size="lg">
            <ModalHeader>
                Neuer Kunde
            </ModalHeader>

            <Form onSubmit={this.save}>
                {({ formState }: { formState: FormState<FormValues> }) => (
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="surname">Nachname</Label>
                                <Text field="surname"
                                    className={`form-control${FormValidators.validateText(formState.values.surname) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid>{FormValidators.validateText(formState.values.surname)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="name">Vorname</Label>
                                <Text field="name"
                                      className={`form-control${FormValidators.validateText(formState.values.name) === undefined ? " is-valid" : " is-invalid"}`}
                                      validateOnChange
                                      validateOnMount
                                      validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid>{FormValidators.validateText(formState.values.name)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="email">E-Mail</Label>
                                <Text field="email"
                                    className={`form-control${FormValidators.validateOptionalEmail(formState.values.email) === undefined ? " is-valid" : " is-invalid"} optional`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateOptionalEmail}
                                />
                                <FormFeedback valid style={{ color: "blue" }}>Optional</FormFeedback>
                                <FormFeedback invalid>{FormValidators.validateOptionalEmail(formState.values.email)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="phone">Telefon</Label>
                                <Text field="phone"
                                    className={`form-control${FormValidators.validateOptionalPhone(formState.values.phone) === undefined ? " is-valid" : " is-invalid"} optional`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateOptionalPhone}
                                />
                                <FormFeedback valid style={{ color: "blue" }}>Optional</FormFeedback>
                                <FormFeedback invalid>{FormValidators.validateOptionalPhone(formState.values.phone)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="company">Firma</Label>
                                <Text field="company"
                                    className={`form-control${FormValidators.validateOptionalText(formState.values.company) === undefined ? " is-valid" : " is-invalid"} optional`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateOptionalText}
                                />
                                <FormFeedback valid style={{ color: "blue" }}>Optional</FormFeedback>
                                <FormFeedback invalid>{FormValidators.validateOptionalText(formState.values.company)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                    <h5 style={{ display: "inline" }}>Bauvorhaben für diesen Kunden erstellen?</h5>&nbsp;&nbsp;
                                    <Checkbox field="create_project" style={{ verticalAlign: "middle", width: 25, height: 25}} checked={true} />
                            </FormGroup>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="danger" onClick={this.props.onClose}>Abbrechen</Button>
                            <Button color="primary" type="submit" disabled={formState.invalid}>Speichern</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    createCustomer: (customer: Customer) => dispatch({ type: CustomersActionTypes.CREATE_CUSTOMER_REQUEST, customer })
})

export default withPortal("CREATE_PROJECT", "createProject")(
    connect(mapStateToProps, mapDispatchToProps)(CustomerCreationModal)
)
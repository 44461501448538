import Customer from '../../typescript/objects/Customer'
import {AnyAction, Reducer} from 'redux';
import * as BackendAPI from "../../typescript/backend/BackendAPI"
import {all, call, fork, put, select, takeEvery} from "redux-saga/effects"
import {AxiosResponse} from "axios"
import {ApplicationState} from '../store';
import SearchFilters from '../../typescript/utils/SearchFilters';
import {SearchActionTypes} from './search.duck';

const initialState: CustomersState = {
    customers: [],
    filteredCustomers: [],
    busy: false
}

export const reducer: Reducer<CustomersState> = (state: CustomersState = initialState, action: CustomersAction) => {
    switch (action.type) {
        case CustomersActionTypes.FETCH_CUSTOMERS_START:
        case CustomersActionTypes.CREATE_CUSTOMER_START:
        case CustomersActionTypes.EDIT_CUSTOMER_START:
        case CustomersActionTypes.DELETE_CUSTOMER_START:
            return { ...state, busy: true }

        case CustomersActionTypes.FETCH_CUSTOMERS_FAILURE:
        case CustomersActionTypes.CREATE_CUSTOMER_FAILURE:
        case CustomersActionTypes.EDIT_CUSTOMER_FAILURE:
        case CustomersActionTypes.DELETE_CUSTOMER_FAILURE:
            return { ...state, busy: false, error: action.error }

        case CustomersActionTypes.FETCH_CUSTOMERS_SUCCESS:
            return { ...state, busy: false, error: undefined, customers: action.customers }

        case CustomersActionTypes.CREATE_CUSTOMER_SUCCESS:
            return { ...state, busy: false, error: undefined, customers: [...state.customers, action.customer] }

        case CustomersActionTypes.EDIT_CUSTOMER_SUCCESS: 
            return { ...state, busy: false, error: undefined, customers: [...state.customers.filter(customer => customer.id !== action.customer.id), action.customer] }

        case CustomersActionTypes.DELETE_CUSTOMER_SUCCESS:
            return { ...state, busy: false, error: undefined, customers: state.customers.filter(customer => customer.id !== action.customer.id) }

        case CustomersActionTypes.REPLACE_FILTERED_CUSTOMERS:
            return { ...state, filteredCustomers: action.filteredCustomers }

        default:
            return state
    }
}

export interface CustomersState {
    readonly customers: Array<Customer>
    readonly filteredCustomers: Array<Customer>
    readonly busy: boolean
    readonly error?: string
}

export enum CustomersActionTypes {
    FETCH_CUSTOMERS_REQUEST = "@customers/fetch_customers/request",
    FETCH_CUSTOMERS_START = "@customers/fetch_customers/start",
    FETCH_CUSTOMERS_SUCCESS = "@customers/fetch_customers/success",
    FETCH_CUSTOMERS_FAILURE = "@customers/fetch_customers/failure",

    CREATE_CUSTOMER_REQUEST = "@customers/create_customer/request",
    CREATE_CUSTOMER_START = "@customers/create_customer/start",
    CREATE_CUSTOMER_SUCCESS = "@customers/create_customer/success",
    CREATE_CUSTOMER_FAILURE = "@customers/create_customer/failure",

    EDIT_CUSTOMER_REQUEST = "@customers/edit_customer/request",
    EDIT_CUSTOMER_START = "@customers/edit_customer/start",
    EDIT_CUSTOMER_SUCCESS = "@customers/edit_customer/success",
    EDIT_CUSTOMER_FAILURE = "@customers/edit_customer/failure",

    DELETE_CUSTOMER_REQUEST = "@customers/delete_customer/request",
    DELETE_CUSTOMER_START = "@customers/delete_customer/start",
    DELETE_CUSTOMER_SUCCESS = "@customers/delete_customer/success",
    DELETE_CUSTOMER_FAILURE = "@customers/delete_customer/failure",

    REPLACE_FILTERED_CUSTOMERS = "@customers/replace_filtered_list"
}

export type CustomersFetchRequestAction = { type: CustomersActionTypes.FETCH_CUSTOMERS_REQUEST }
export type CustomersFetchStartAction = { type: CustomersActionTypes.FETCH_CUSTOMERS_START }
export type CustomersFetchSuccessAction = { type: CustomersActionTypes.FETCH_CUSTOMERS_SUCCESS, customers: Array<Customer> }
export type CustomersFetchFailureAction = { type: CustomersActionTypes.FETCH_CUSTOMERS_FAILURE, error: string }

export type CustomerCreationRequestAction = { type: CustomersActionTypes.CREATE_CUSTOMER_REQUEST, customer: Customer  }
export type CustomerCreationStartAction = { type: CustomersActionTypes.CREATE_CUSTOMER_START }
export type CustomerCreationSuccessAction = { type: CustomersActionTypes.CREATE_CUSTOMER_SUCCESS, customer: Customer }
export type CustomerCreationFailureAction = { type: CustomersActionTypes.CREATE_CUSTOMER_FAILURE, error: string }

export type CustomerEditingRequestAction = { type: CustomersActionTypes.EDIT_CUSTOMER_REQUEST, customer: Customer  }
export type CustomerEditingStartAction = { type: CustomersActionTypes.EDIT_CUSTOMER_START }
export type CustomerEditingSuccessAction = { type: CustomersActionTypes.EDIT_CUSTOMER_SUCCESS, customer: Customer }
export type CustomerEditingFailureAction = { type: CustomersActionTypes.EDIT_CUSTOMER_FAILURE, error: string }

export type CustomerDeletionRequestAction = { type: CustomersActionTypes.DELETE_CUSTOMER_REQUEST, customer: Customer  }
export type CustomerDeletionStartAction = { type: CustomersActionTypes.DELETE_CUSTOMER_START }
export type CustomerDeletionSuccessAction = { type: CustomersActionTypes.DELETE_CUSTOMER_SUCCESS, customer: Customer }
export type CustomerDeletionFailureAction = { type: CustomersActionTypes.DELETE_CUSTOMER_FAILURE, error: string }

export type ReplaceFilteredCustomersAction = { type: CustomersActionTypes.REPLACE_FILTERED_CUSTOMERS, filteredCustomers: Array<Customer> }

export type CustomersAction = 
CustomersFetchRequestAction | CustomersFetchStartAction | CustomersFetchSuccessAction | CustomersFetchFailureAction |
CustomerCreationRequestAction | CustomerCreationStartAction | CustomerCreationSuccessAction | CustomerCreationFailureAction |
CustomerEditingRequestAction | CustomerEditingStartAction | CustomerEditingSuccessAction | CustomerEditingFailureAction |
CustomerDeletionRequestAction | CustomerDeletionStartAction | CustomerDeletionSuccessAction | CustomerDeletionFailureAction |
ReplaceFilteredCustomersAction

/* Sagas */

// Replace Filtered Customer List
function* replaceFilteredCustomersSaga() {

    const replace = function*(action: AnyAction) {
        const searchText = yield select((state: ApplicationState) => state.search.text)
        const customers = yield select((state: ApplicationState) => state.customers.customers)
        const filteredCustomers = customers.filter(SearchFilters.customerFilter(searchText))

        yield put({ type: CustomersActionTypes.REPLACE_FILTERED_CUSTOMERS, filteredCustomers })
    }

    yield all([
        takeEvery(SearchActionTypes.SET_TEXT, replace),
        takeEvery(SearchActionTypes.CLEAR_TEXT, replace),
        takeEvery(CustomersActionTypes.FETCH_CUSTOMERS_SUCCESS, replace),
        takeEvery(CustomersActionTypes.EDIT_CUSTOMER_SUCCESS, replace),
        takeEvery(CustomersActionTypes.DELETE_CUSTOMER_SUCCESS, replace),
        takeEvery(CustomersActionTypes.CREATE_CUSTOMER_SUCCESS, replace)
    ])
}

// Customers Fetch
function* customersFetchSaga() {
    yield takeEvery(CustomersActionTypes.FETCH_CUSTOMERS_REQUEST, function*(action: CustomersFetchRequestAction) {
        yield put({ type: CustomersActionTypes.FETCH_CUSTOMERS_START })

        try {
            const result: AxiosResponse = yield call(BackendAPI.fetchCustomers())
            const customers: Array<Customer> = (result.data as Array<any>).map(rawCustomer => new Customer(rawCustomer))

            yield put({ type: CustomersActionTypes.FETCH_CUSTOMERS_SUCCESS, customers })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: CustomersActionTypes.FETCH_CUSTOMERS_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Customer Creation
function* customerCreationSaga() {
    yield takeEvery(CustomersActionTypes.CREATE_CUSTOMER_REQUEST, function*(action: CustomerCreationRequestAction) {
        yield put({ type: CustomersActionTypes.CREATE_CUSTOMER_START })

        const customerTemplate: Customer = action.customer
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.createCustomer(customerTemplate))
            const customer: Customer = new Customer(result.data)

            yield put({ type: CustomersActionTypes.CREATE_CUSTOMER_SUCCESS, customer })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: CustomersActionTypes.CREATE_CUSTOMER_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Customer Editing
function* customerEditingSaga() {
    yield takeEvery(CustomersActionTypes.EDIT_CUSTOMER_REQUEST, function*(action: CustomerEditingRequestAction) {
        yield put({ type: CustomersActionTypes.EDIT_CUSTOMER_START })

        const customerTemplate: Customer = action.customer
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.patchCustomer(customerTemplate))
            const customer: Customer = new Customer(result.data)

            yield put({ type: CustomersActionTypes.EDIT_CUSTOMER_SUCCESS, customer })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: CustomersActionTypes.EDIT_CUSTOMER_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Customer Deletion
function* customerDeletionSaga() {
    yield takeEvery(CustomersActionTypes.DELETE_CUSTOMER_REQUEST, function*(action: CustomerDeletionRequestAction) {
        yield put({ type: CustomersActionTypes.DELETE_CUSTOMER_START })

        const customer: Customer = action.customer
    
        try {
            yield call(BackendAPI.deleteCustomer(customer))
            yield put({ type: CustomersActionTypes.DELETE_CUSTOMER_SUCCESS, customer })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: CustomersActionTypes.DELETE_CUSTOMER_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Export a combined Saga
export function* saga() {
    yield all([
        fork(customersFetchSaga),
        fork(customerCreationSaga),
        fork(customerEditingSaga),
        fork(customerDeletionSaga),
        fork(replaceFilteredCustomersSaga)
    ])
}
import React from 'react'
import {Document, Font, Image, Page, Text, View} from '@react-pdf/renderer'
import styles from "./SamplingProtocolStyles"
import SamplingProtocolNode from './SamplingProtocolNode';
import SamplingProtocolCover from './SamplingProtocolCover';
import Project from '../../../typescript/objects/Project';
import SamplingTreeNode from '../../../typescript/objects/SamplingTreeNode';
import logo from "../../../images/Heimerl_Logo_Small.png"
import {States} from '../../../typescript/constants/States';
import {formatDateBackwards} from "../../../typescript/utils/FormatUtil";

//TODO: refactor components, FOOTER
//      header logo

interface Props {
    project: Project
    tree: Array<SamplingTreeNode>
    date: Date
    partial?: Boolean
}

//Worttrennung im PDF deaktivieren
Font.registerHyphenationCallback((words: Array<any>) => (
    words.map((word: any) => [word])
));

const SamplingProtocol = (props: Props) => (
    <Document
        title={`${props.partial ? "Teilprotokoll" : "Bemusterung"} ${props.project.customer.surname} ${props.project.customer.name} ${formatDateBackwards(props.date)}`}
        author={`${props.project.manager.surname} ${props.project.manager.name}`}
    >

        <SamplingProtocolCover project={props.project} date={props.date} tree={props.tree[0]} partial={props.partial || false} />

        <Page size="A4" style={styles.page} wrap >
            <View style={styles.header} fixed >
                <View >
                    <Text style={[styles.headertext, { fontFamily: 'Helvetica-Bold' }]} fixed>
                        {props.partial ? "Teilprotokoll" : "Bemusterung"}
                    </Text>
                    <Text style={[styles.headertext, { maxWidth: '63%', fontSize: '11pt' }]} fixed>
                        {`${props.project.name}\n${props.project.customer.surname} ${props.project.customer.name}`}
                    </Text>
                </View>
                <Image style={styles.logo} src={logo} />
            </View>

            {props.tree && props.tree.length > 0 &&
                <View style={styles.section} >
                    {props.tree[0].children
                        .filter(nodeToFilter => nodeToFilter.state === States.SAMPLED || nodeToFilter.state === States.PARTLY_SAMPLED)
                        .filter(nodeToFilter => !nodeToFilter.isExcludedFromProtocol())
                        .map(node => <SamplingProtocolNode key={node.id} node={node} />)}
                </View>
            }

            <View style={styles.footer} fixed>
                <Text fixed> {`Stand: ${props.date.toLocaleDateString()}`}</Text>

                <Text
                    render={({ pageNumber, totalPages }: { pageNumber: number, totalPages: number }) => `Seite ${pageNumber} von ${totalPages}`}
                    fixed
                />
            </View>

            <View style={styles.footerBarRed} fixed />
            <View style={styles.footerBarGreen} fixed />
        </Page>
    </Document>
)

export default SamplingProtocol
import React from "react"
import LoginMask from '../components/routes/login/LoginMask'
import {Helmet} from "react-helmet"
import {ApplicationState} from "../state/store"
import {Dispatch} from 'redux'
import {connect} from 'react-redux'
import {AuthenticationActionTypes} from '../state/ducks/authentication.duck'
import {BackendServer} from "../typescript/objects/BackendServer";

interface ReduxProps {
    readonly currentBackend: BackendServer
    readonly backends: Array<BackendServer>
    readonly busy: boolean
}

interface ReduxActions {
    setBackend(backend: BackendServer): void
    login(user: string, password: string): void
}

type Props =  ReduxProps & ReduxActions

const LoginView = (props: Props) => (
    <>
        <Helmet
            title="Login - Heimerl 4.0"
            meta={[ { name: "description", content: "Login Seite" } ]}
        />

        <LoginMask
            currentBackend={props.currentBackend}
            backends={props.backends}
            switchBackend={props.setBackend}
            handleLogin={props.login}
        />
    </>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    currentBackend: state.authentication.backend,
    backends: state.authentication.availableBackends,
    busy: state.authentication.busy
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    setBackend: (backend: BackendServer) => dispatch({ type: AuthenticationActionTypes.SET_BACKEND, backend }),
    login: (user: string, password: string) => dispatch({ type: AuthenticationActionTypes.LOGIN_REQUEST, user, password })
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginView)
import React from "react"
import {connect} from "react-redux"
import {ApplicationState} from "../../state/store"
import {Route, Switch} from "react-router"

import ProjectsView from '../../views/projects'
import CustomersView from '../../views/customers'
import SamplingView from '../../views/sampling'
import UsersView from '../../views/users'
import MasterdataView from '../../views/masterdata'
import PageNotFound from '../../views/404'
import ProtocolView from '../../views/protocol'
import LoginView from '../../views/login'
import ChronicleView from '../../views/chronicle'
import ImagesView from "../../views/images";
import BackupsView from "../../views/backups";
import ArchivesView from "../../views/archives";

interface ComponentProps {
}

interface ReduxProps {
    authenticated: boolean
}

interface ReduxActions {
}

type Props = ComponentProps & ReduxProps & ReduxActions

const Router = () => (
    <>
        <Switch>
            <Route exact path="/" component={ProjectsView}/>
            <Route path="/projects" component={ProjectsView}/>
            <Route path="/sampling" component={SamplingView}/>
            <Route path="/customers" component={CustomersView}/>
            <Route path="/users" component={UsersView}/>
            <Route path="/masterdata" component={MasterdataView}/>
            <Route path="/chronicle" component={ChronicleView}/>
            <Route path="/protocol" component={ProtocolView}/>
            <Route path="/login" component={LoginView}/>
            <Route path="/backups" component={BackupsView}/>
            <Route path="/images" component={ImagesView}/>
            <Route path="/archive" component={ArchivesView}/>

            <Route component={PageNotFound}/>
        </Switch>
    </>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    authenticated: state.authentication.authenticated
})

export default connect(mapStateToProps, null, null, {pure: false})(Router)
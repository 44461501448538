import {v4} from "node-uuid"

class Role {

    id: string = v4()
    name: string
    description: string
    createdAt: Date = new Date()
    modifiedAt: Date = new Date()

    constructor (input: any) {
        this.name = `${input.name}`.trim().toLowerCase()
        this.description = input.description

        if (input.id) this.id = input.id
        if (input.createdAt) this.createdAt = new Date(input.createdAt)
        if (input.modifiedAt) this.modifiedAt = new Date(input.modifiedAt)
    }

}

export default Role
import MasterdataTreeNode from "../objects/MasterdataTreeNode";
import SamplingTreeNode from "../objects/SamplingTreeNode";

export type TreeNode = MasterdataTreeNode | SamplingTreeNode

export type TreeFunction = (node: TreeNode) => void
export type ParentedTreeFunction = (node: TreeNode, parent?: TreeNode) => void

export const walkDownFully = (tree: TreeNode, action: TreeFunction) => {
    action(tree)

    for (let child of tree.children) {
        action(child)
        walkDownFully(child, action)
    }
}

export const walkDownUntil = (tree: TreeNode, target: TreeNode, action: TreeFunction) => {
    action(tree)

    for (let child of tree.children) {
        if (!(child as any).containsNode(target)) continue

        action(child)
        walkDownUntil(child, target, action)
    }
}

export const walkDownAndMatch = (tree: TreeNode, isTarget: (node: TreeNode) => boolean, action: TreeFunction) => {
    if (isTarget(tree)) action(tree)

    for (let child of tree.children) {
        walkDownAndMatch(child, isTarget, action)
    }
}

export const walkUpFrom = (tree: TreeNode, target: TreeNode, action: ParentedTreeFunction) => {
    const childParentMapping: Map<string, TreeNode> = new Map()

    // Collect all relevant child <=> parent relations
    walkDownUntil(tree, target, (node) => {
        for (let child of node.children) {
            if (!(child as any).containsNode(target)) continue

            childParentMapping.set(child.id, node)
        }
    })

    var node: TreeNode | undefined = target
    var parent: TreeNode | undefined = node ? childParentMapping.get(node.id) : undefined

    // Walk up the tree by using the collected relations
    while (node !== undefined && node !== null) {
        action(node, parent)

        node = parent
        parent = node ? childParentMapping.get(node.id) : undefined
    }
}
import React from "react"
import {Nav} from "reactstrap"
import "./FooterNavigation.sass"
import Navbar from "reactstrap/lib/Navbar"

const Navigation = () => (
    <>
        <Navbar id="footer-navigation" dark expand="md">
            <Nav navbar>
                {/* Whatever we my add here in the future */}
            </Nav>
        </Navbar>
    </>
)

export default Navigation
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import Label from 'reactstrap/lib/Label';
import {Form, FormState, FormValues, Text} from 'informed';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../state/store';
import {MasterdataActionTypes} from '../../../state/ducks/masterdata.duck';
import MasterdataTreeNode from '../../../typescript/objects/MasterdataTreeNode';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormValidators from '../../../typescript/utils/FormValidators';
import FormFeedback from 'reactstrap/lib/FormFeedback';

interface State { }

interface ComponentProps { }

interface ReduxProps { }

interface PortalProps {
    parent: MasterdataTreeNode
    onClose(): void
}

interface ReduxActions {
    createNode(node: MasterdataTreeNode, parent: MasterdataTreeNode): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class MasterdataNodeCreationModal extends Component<Props, State> {

    save = (values: FormValues) => {
        // Prepare a new Node
        const node = new MasterdataTreeNode()

        // Inject Form Values
        node.title = values.title.toString()

        // Trigger the Node Creation
        this.props.createNode(node, this.props.parent)

        // Close the Modal
        this.props.onClose()
    }

    render = () => (
        <Modal isOpen size="lg">
            <ModalHeader>
                Neues Stammdatenelement
            </ModalHeader>

            <Form onSubmit={this.save}>
                {({ formState }: { formState: FormState<FormValues> }) => (
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="title">Bezeichnung</Label>
                                <Text field="title"
                                    className={`form-control${FormValidators.validateText(formState.values.title) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.title)}</FormFeedback>
                            </FormGroup>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="danger" onClick={this.props.onClose}>Abbrechen</Button>
                            <Button color="primary" type="submit" disabled={formState.invalid}>Speichern</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    createNode: (node: MasterdataTreeNode, parent: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.CREATE_MASTERDATA_NODE, node, parent })
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterdataNodeCreationModal)
import React from "react"
import {ApplicationState} from "../../../state/store";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {MasterdataActionTypes} from "../../../state/ducks/masterdata.duck";
import "./masterdata.sass"
import Button from "reactstrap/lib/Button";
import Icon from "../../utils/Icon";
import MasterdataLegend from "./MasterdataLegend";
import ButtonGroup from "reactstrap/lib/ButtonGroup";

interface ComponentProps { }

interface ReduxProps {
    busy: boolean
}

interface ReduxActions {
    loadMasterdata(): void
    saveMasterdata(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

const MasterdataBar = (props: Props) => (
    <div className="masterdata-bar">
        <Button color="primary" disabled={props.busy}
            onClick={props.saveMasterdata}
        >
            <Icon type="fas" icon="save" />
            &nbsp;
            <span>Speichern</span>
        </Button>

        <span>
            <ButtonGroup>
                <MasterdataLegend />
                <Button color="success" disabled={props.busy}
                    onClick={props.loadMasterdata}
                >
                    <Icon type="fas" icon="sync" spin={props.busy} />
                    &nbsp;
                    <span>Wiederherstellen</span>
                </Button>
            </ButtonGroup>
        </span>
    </div>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    busy: state.masterdata.busy
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadMasterdata: () => dispatch({ type: MasterdataActionTypes.FETCH_MASTERDATA_REQUEST }),
    saveMasterdata: () => dispatch({ type: MasterdataActionTypes.EDIT_MASTERDATA_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterdataBar)
import React, {Component} from "react"
import Project from "../typescript/objects/Project";
import {PDFViewer} from '@react-pdf/renderer'
import SamplingTreeNode from "../typescript/objects/SamplingTreeNode";
import {connect} from "react-redux";
import {ApplicationState} from "../state/store";
import {Dispatch} from "redux";
import {ProjectsActionTypes} from "../state/ducks/projects.duck";
import styles from "../components/routes/protocol/SamplingProtocolStyles";
import SamplingProtocol from "../components/routes/protocol/SamplingProtocol";
import Helmet from "react-helmet";

interface State { }

interface ComponentProps { }

interface ReduxProps {
    readonly project?: string
    readonly tree?: SamplingTreeNode[]
    readonly projects: Array<Project>
}

interface ReduxActions {
    loadProjects(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class ProtocolView extends Component<Props, State> {

    componentDidMount = () => {
        this.props.loadProjects()
    }

    render = () => (
        <>
            <Helmet
                title="Protokoll - Heimerl 4.0"
                meta={[{ name: "description", content: "Bemusterungsprotokoll" }]}
            />

            {this.props.project && this.props.tree && this.props.projects.length > 0 &&
                <PDFViewer style={styles.viewer}>
                    <SamplingProtocol project={this.props.projects.find(project => project.id === this.props.project)!} tree={this.props.tree} date={new Date()} />
                </PDFViewer>
            }
        </>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    tree: state.sampling.tree,
    project: state.sampling.projectId,
    projects: state.projects.projects
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadProjects: () => dispatch({ type: ProjectsActionTypes.FETCH_PROJECTS_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtocolView)
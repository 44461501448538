import React, {DragEvent} from "react"

import {connect} from "react-redux"
import {Dispatch} from "redux"

import "./masterdata.sass"
import {ApplicationState} from "../../../state/store"
import MasterdataTreeNode from "../../../typescript/objects/MasterdataTreeNode"
import {ListGroup} from "reactstrap";
import Icon from "../../utils/Icon";
import {MasterdataActionTypes} from "../../../state/ducks/masterdata.duck";
import MasterdataToolbar from "./MasterdataToolbar";
import SearchFilters from "../../../typescript/utils/SearchFilters";
import {TreeNode as TreeNodeType, walkDownAndMatch, walkUpFrom} from "../../../typescript/utils/TreeUtils";
import TreeNode from "../../utils/TreeNode";

interface ComponentProps {
    tree: Array<MasterdataTreeNode>
}

interface ReduxProps {
    expandedNodes: Array<string>
    search: string
}

interface ReduxActions {
    toggleNodeExpansion(node: MasterdataTreeNode): void
    switchNodes(from: string, to: string): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

const getIconType = (node: MasterdataTreeNode) => {
    if (node.title === "Stammdaten") return "icon-masterdata"
    if (node.isNautical() && node.hasChildren() && !node.children[0].isNautical()) return "icon-cross"
    if (node.isNautical()) return "icon-category"
    return "icon-detail"
}

const getIcon = (node: MasterdataTreeNode) => {
    // Root Element (Stammdaten)
    if (node.title === "Stammdaten") return <Icon cssClass={getIconType(node)} type="fas" icon="tree" />

    if (node.hasChildren()) {
        // Exclusive Elements
        if (node.behaviour === "exclusive") return <Icon cssClass={getIconType(node)} type="fas" icon="circle" />

        // Kumulative Elements
        if (node.behaviour === "cumulative") return <Icon cssClass={getIconType(node)} type="fas" icon="square" />
    } else {
        // Exclusive Elements without children
        if (node.behaviour === "exclusive") return <Icon cssClass={getIconType(node)} type="far" icon="circle" />

        // Kumulative Elements without children
        if (node.behaviour === "cumulative") return <Icon cssClass={getIconType(node)} type="far" icon="square" />
    }

    // Unknown Elements
    return <Icon cssClass={getIconType(node)} type="fas" icon="question" />
}

const getMasterdataToolbar = (node: MasterdataTreeNode) => <MasterdataToolbar node={node} />

const searchFilter = (search: string, tree: MasterdataTreeNode, node: MasterdataTreeNode): boolean => {
    const filter = SearchFilters.treeNodeFilter(search)

    if (filter(node)) {
        return true
    }

    let containsMatch = false

    walkDownAndMatch(node, (node: TreeNodeType) => filter(node), (node: TreeNodeType) => {
        containsMatch = true
    })

    if (containsMatch) return true

    let parentMatches = false

    walkUpFrom(tree, node, (node, parent) => {
        if (filter(node)) {
            parentMatches = true
        }
    })

    if (parentMatches) return true

    return false
}

const handleDragOver = (event: DragEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    event.preventDefault()
}

const handleDragStart = (event: DragEvent<HTMLButtonElement>, node: MasterdataTreeNode) => {
    if (node.isOrganisational()) {
        event.preventDefault()
        event.stopPropagation()
        return
    }

    // Embed the node id in the event data to find the dragged node in the tree later
    event.dataTransfer.setData("text/plain", node.id)
}

const handleDrop = (event: DragEvent<HTMLButtonElement>, node: MasterdataTreeNode, props: Props) => {
    event.stopPropagation()
    event.preventDefault()

    const from = event.dataTransfer.getData("text")
    const to = node

    props.switchNodes(from, to.id)
}

const MasterdataTree = (props: Props) => (
    <ListGroup>
        {props.tree.map(((node: MasterdataTreeNode) => (
            <TreeNode
                dragAndDrop
                onDragOver={handleDragOver}
                onDragStart={handleDragStart}
                onDrop={(event, node) => handleDrop(event, node as MasterdataTreeNode, props)}
                shallRender={(treeNode: MasterdataTreeNode, parent?: MasterdataTreeNode): boolean => props.search.trim().length > 0 ? searchFilter(props.search, props.tree[0], treeNode) : true}
                disableLeafs
                key={node.id}
                node={node}
                toggle={props.toggleNodeExpansion}
                isExpanded={(node: MasterdataTreeNode) => props.expandedNodes.indexOf(node.id) !== -1}
                getPrefix={getIcon}
                getToolbar={getMasterdataToolbar}
            />
        )))}
    </ListGroup>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    expandedNodes: state.masterdata.expandedNodes,
    search: state.search.text
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    toggleNodeExpansion: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_EXPANSION, node }),
    switchNodes: (from: string, to: string) => dispatch({ type: MasterdataActionTypes.SWITCH_MASTERDATA_NODES, from, to })
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterdataTree)
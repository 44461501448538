import React from 'react'
import {Image, Text, View} from '@react-pdf/renderer'
import styles from "./SamplingProtocolStyles"
import SamplingTreeNode from '../../../typescript/objects/SamplingTreeNode'
import {States} from '../../../typescript/constants/States';

interface Props {
    node: SamplingTreeNode
    depth?: number
}

const SamplingProtocolNode = (props: Props) => (
    <React.Fragment>{/*TODO: REFACTOR THE STYLING - inline is a total mess */}
        <View style={{
            //borderRight: '0pt solid #000000',
            //border: `${props.node.isNautical() ? `0` : `0`}pt solid #000000`,
            //borderBottom: `${props.depth ? (props.depth === 1 ? `4` : (props.depth === 2 ? `1` : `0`)) : `0`}pt solid #000000`,
            borderBottom: `${props.depth ? (props.depth === 2 ? `1` : `0`) : `0`}pt solid #000000`,
            borderRight: `${props.depth ? (props.depth === 3 ? `1` : `0`) : `0`}pt solid #AAAAAA`,
            //margin: 0,
            margin: `${Math.max(12 - ((props.depth || 0) * 2), 1)}px`,
            marginLeft: '0pt', marginRight: '3pt',
            paddingRight: '3pt',
            paddingTop: '2pt',
            paddingBottom: '2pt',
            flexGrow: 1,
            //minWidth: `${props.node.isNautical() ? `90` : `11`}%`,
            //minWidth: `40%`,
            //maxWidth: `${props.depth ? (100 - (props.depth*4)) : `100`}%`,

        }}
            wrap={props.depth ? (props.depth < 3) : true}
        //break={props.node.isNautical ? true : false}
        //break={props.depth ? (props.depth == 1 ? true : false) : false}
        //minPresenceAhead={5}
        // debug
        >

            {/*  //only cumulative needed? min-width high tier? auto-wrap? */}
            {/* false && (node.behaviour === 'cumulative') &&   //if? check syntax, {if(){}} didn't parse
                <View style={styles.exclusive}>
                    <Text>{node.title} ({node.state})</Text>
                    <Text>{node.comment}</Text>
                {node.children.filter(nodeToFilter => (nodeToFilter.state == 'partly_sampled' 
                                                || nodeToFilter.state == 'sampled'))
                          .map(child => <SamplingProtocolNode node={child} />)}
        
                </View>
            */}

            {//(node.behaviour === 'exclusive') &&

                <View style={{
                    ...styles.exclusive,
                    ...{ fontSize: `${Math.max(17 - ((props.depth || 0) * 2), 10)}pt` },
                    fontFamily: `${props.node.isNautical() ? `Helvetica-Bold` : `Helvetica`}`,
                    //borderBottom: `${props.depth ? (props.depth === 1 ? `4` : `0`) : 0}pt solid #000000`,

                }}>
                    <View>
                        <Text>
                            <Text style={{ color: '#C02020', fontSize: '14pt' }}>{props.node.isSpecialPricing() ? '•MP ' : ''}</Text>

                            {
                                props.node.title
                                + (props.node.hasComment() ? ': ' : '')
                            }

                            <Image style={styles.logo} src=""></Image>
                            <Text style={{ fontFamily: `Helvetica-Oblique` }}>{props.node.comment}</Text>
                            <Text style={{ fontFamily: `Helvetica-Oblique`, color: "blue", fontWeight: "bold", fontSize: "12pt" }}>
                                {props.node.isNotMandatory() ? ' wird nicht gewünscht' : ''}
                                {props.node.isCustomerPerformance() ? ' - Eigenleistung' : ''}
                            </Text>
                        </Text>
                    </View>
                    <View style={styles.cumulative}>
                        {props.node.children
                            .filter(nodeToFilter => nodeToFilter.state === States.SAMPLED || nodeToFilter.state === States.PARTLY_SAMPLED)
                            .filter(nodeToFilter => !nodeToFilter.isExcludedFromProtocol())
                            .map(child => <SamplingProtocolNode key={child.id} node={child} depth={(props.depth || 0) + 1} />)}
                    </View>
                </View>
            }
        </View>

        {//separator on main categories
            true
            && (props.depth ? (props.depth === 1 ? true : false) : false)
            &&
            <View style={{
                borderBottom: `5pt solid #000000`,
                width: `100%`,
            }}
            >
            </View>

        }
    </React.Fragment>
)

export default SamplingProtocolNode
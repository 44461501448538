import React, {Component} from 'react'
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Label from 'reactstrap/lib/Label'
import {Form, FormState, FormValues, Text} from 'informed'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {ApplicationState} from '../../../state/store'
import {MasterdataActionTypes} from '../../../state/ducks/masterdata.duck'
import MasterdataTreeNode from '../../../typescript/objects/MasterdataTreeNode'
import FormGroup from 'reactstrap/lib/FormGroup'
import FormValidators from '../../../typescript/utils/FormValidators'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import FormText from 'reactstrap/lib/FormText'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'

interface State { }

interface ComponentProps { }

interface ReduxProps {
    readonly masterdata: MasterdataTreeNode
}

interface PortalProps {
    readonly node: MasterdataTreeNode
    onClose(): void
}

interface ReduxActions {
    editNode(node: MasterdataTreeNode): void
    toggleCommentRequired(node: MasterdataTreeNode): void
    toggleCategory(node: MasterdataTreeNode): void
    toggleDisabled(node: MasterdataTreeNode): void
    cloneNode(source: MasterdataTreeNode, clone: MasterdataTreeNode): void
    deleteNode(node: MasterdataTreeNode): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class MasterdataNodeMenuModal extends Component<Props, State> {

    getNode = () => this.props.masterdata.getReferenceOf(this.props.node.id)!

    saveTitle = (values: FormValues) => {
        // Prepare a new Node
        const node = MasterdataTreeNode.valueOf(this.getNode())

        // Inject Form Values
        node.title = values.title.toString()

        // Trigger the Node Menu
        this.props.editNode(node)
    }

    saveClone = (values: FormValues) => {
        // Prepare a new Node
        const clone = MasterdataTreeNode.copy(this.getNode())

        // Inject Form Values
        clone.title = values.title.toString()

        // Trigger the node cloning
        this.props.cloneNode(this.getNode(), clone)

        // Close the modal after cloning
        this.props.onClose()
    }

    removeNode = () => {
        if (window.confirm("Wollen sie diesen Datensatz wirklich löschen?")) {
            this.props.onClose()
            this.props.deleteNode(this.getNode())
        }
    }

    render = () => (
        <Modal isOpen size="lg">
            <ModalHeader>
                {this.getNode().title}
            </ModalHeader>


            <ModalBody>
                <Form onSubmit={this.saveTitle}>
                    {({ formState }: { formState: FormState<FormValues> }) => (
                        <FormGroup>
                            <Label htmlFor="title">Bezeichnung</Label>
                            <Row>
                                <Col xs="auto" md={8}>
                                    <Text field="title"
                                        readOnly={this.getNode().isOrganisational()}
                                        initialValue={this.getNode().title}
                                        className={`form-control${FormValidators.validateText(formState.values.title) === undefined ? " is-valid" : " is-invalid"}`}
                                        validateOnChange
                                        validateOnMount
                                        validate={FormValidators.validateText}
                                    />
                                    <FormFeedback invalid="true">{FormValidators.validateText(formState.values.title)}</FormFeedback>
                                    {this.getNode().isOrganisational() && <FormText>Die Bezeichnung dieses Elements kann nicht bearbeitet werden.</FormText>}
                                </Col>

                                <Col xs="auto" md="auto">
                                    <ButtonGroup className="float-right">
                                        <Button color="warning" type="reset" disabled={this.getNode().isOrganisational()}>Zurücksetzen</Button>
                                        <Button color="primary" type="submit" disabled={this.getNode().isOrganisational() || formState.invalid}>Speichern</Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </Form>

                <hr />

                <Form onSubmit={this.saveClone}>
                    {({ formState }: { formState: FormState<FormValues> }) => (
                        <FormGroup>
                            <Label htmlFor="title">Bezeichnung des Klons</Label>
                            <Row>
                                <Col xs="auto" md={8}>
                                    <Text field="title"
                                        readOnly={this.getNode().isOrganisational()}
                                        initialValue={`Kopie von ${this.getNode().title}`}
                                        className={`form-control${FormValidators.validateText(formState.values.title) === undefined ? " is-valid" : " is-invalid"}`}
                                        validateOnChange
                                        validateOnMount
                                        validate={FormValidators.validateText}
                                    />
                                    <FormFeedback invalid="true">{FormValidators.validateText(formState.values.title)}</FormFeedback>
                                    {this.getNode().isOrganisational() && <FormText>Dieses Element kann nicht geklont werden.</FormText>}
                                </Col>

                                <Col xs="auto" md="auto">
                                    <ButtonGroup className="float-right">
                                        <Button color="primary" type="submit" disabled={this.getNode().isOrganisational() || formState.invalid}>Klonen</Button>
                                    </ButtonGroup>
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </Form>

                <hr />

                <ButtonGroup id="flag-buttons">
                    <Button
                        color={this.getNode().isCommentRequired() ? "success" : "danger"}
                        active={this.getNode().isCommentRequired()}
                        disabled={this.getNode().isOrganisational() || this.getNode().hasChildren()}
                        onClick={() => this.props.toggleCommentRequired(this.getNode())}
                    >
                        Freitext erforderlich &nbsp;
                    </Button>

                    <Button
                        color={this.getNode().isNautical() ? "success" : "danger"}
                        active={this.getNode().isNautical()}
                        disabled={this.getNode().isOrganisational()}
                        onClick={() => this.props.toggleCategory(this.getNode())}
                    >
                        Element ist Kategorie &nbsp;
                    </Button>

                    <Button
                        color={this.getNode().isDisabled() ? "danger" : "success" }
                        active={!this.getNode().isDisabled()}
                        disabled={this.getNode().isOrganisational()}
                        onClick={() => this.props.toggleDisabled(this.getNode())}
                    >
                        Element ist { this.getNode().isDisabled() ? "deaktiviert" : "aktiviert" } &nbsp;
                    </Button>

                    <Button
                        color="danger"
                        disabled={this.getNode().isOrganisational()}
                        onClick={this.removeNode}
                    >
                        Element löschen
                    </Button>
                </ButtonGroup>


            </ModalBody>

            <ModalFooter>
                <Button color="danger" onClick={this.props.onClose}>Schließen</Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    masterdata: state.masterdata.tree[0]
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    editNode: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.EDIT_MASTERDATA_NODE, node }),
    toggleCommentRequired: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_COMMENT_REQUIRED, node }),
    toggleCategory: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_CATEGORY, node }),
    toggleDisabled: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_DISABLED, node }),
    cloneNode: (source: MasterdataTreeNode, clone: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.CLONE_MASTERDATA_NODE, source, clone }),
    deleteNode: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.DELETE_MASTERDATA_NODE, node })
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterdataNodeMenuModal)
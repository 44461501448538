import {v4} from "node-uuid"
import Role from "./Role";

class User {

    id: string = v4()
    name: string
    surname: string
    username: string
    email: string
    password?: string
    deleted: boolean = false
    immutable: boolean = false
    admin: boolean = false
    roles: Array<string> = []
    createdAt: Date = new Date()
    modifiedAt: Date = new Date()

    constructor (input: any) {
        this.username = `${input.username}`.trim().toLowerCase()
        this.name = input.name
        this.surname = input.surname
        this.email = `${input.email}`.trim().toLowerCase()

        if (input.id) this.id = input.id
        if (input.admin) this.admin = input.admin
        if (input.password) this.password = input.password
        if (input.deleted) this.deleted = input.deleted
        if (input.immutable) this.immutable = input.immutable
        if (input.roles) this.roles = input.roles
        if (input.createdAt) this.createdAt = new Date(input.createdAt)
        if (input.modifiedAt) this.modifiedAt = new Date(input.modifiedAt)
    }

    hasRole = (role: Role | string) => {
        if (this.admin) {
            return true
        }

        let name: string

        if (typeof role === "string") {
            name = role
        } else name = role.name

        return this.roles.indexOf(name) !== -1
    }

    equals = (user: User) => this.id === user.id

}

export default User
import {Reducer} from "redux"
import * as BackendAPI from "../../typescript/backend/BackendAPI"
import {all, call, Effect, fork, put, select, takeEvery, takeLatest} from "redux-saga/effects"
import {AxiosResponse} from "axios"
import SamplingTreeNode from '../../typescript/objects/SamplingTreeNode'
import {ApplicationState} from "../store"
import * as TreeReconciler from "../../typescript/utils/SamplingTreeReconciling"
import Project from "../../typescript/objects/Project";
import {push} from "connected-react-router";
import {Flags} from "../../typescript/constants/Flags";

const initialState: SamplingState = {
    tree: [],
    expandedNodes: [],
    busy: false
}

export const reducer: Reducer<SamplingState> = (state: SamplingState = initialState, action: SamplingAction) => {
    switch (action.type) {
        case SamplingActionTypes.FETCH_SAMPLING_START:
        case SamplingActionTypes.EDIT_SAMPLING_START:
            return { ...state, busy: true }

        case SamplingActionTypes.FETCH_SAMPLING_FAILURE:
        case SamplingActionTypes.EDIT_SAMPLING_FAILURE:
            return { ...state, busy: false, error: action.error }

        case SamplingActionTypes.FETCH_SAMPLING_SUCCESS:
        case SamplingActionTypes.EDIT_SAMPLING_SUCCESS:
            return { ...state, busy: false, error: undefined, tree: action.tree }

        case SamplingActionTypes.TOGGLE_SAMPLING_NODE_EXPANSION:
            return { ...state, expandedNodes: state.expandedNodes.indexOf(action.node.id) !== -1 ? state.expandedNodes.filter(id => id !== action.node.id) : [...state.expandedNodes, action.node.id] }

        case SamplingActionTypes.SET_PROJECT_FOR_SAMPLING:
            return { ...state, projectId: action.project.id }

        case SamplingActionTypes.REPLACE_SAMPLING_TREE:
            return { ...state, tree: [action.tree] }

        case SamplingActionTypes.SET_NAVTREE_CATEGORY:
            return { ...state, category: action.node.id }

        default:
            return state
    }
}

export interface SamplingState {
    readonly category?: string
    readonly projectId?: string
    readonly tree: Array<SamplingTreeNode>,
    readonly expandedNodes: Array<string>
    readonly busy: boolean,
    readonly error?: string
}

export enum SamplingActionTypes {
    FETCH_SAMPLING_REQUEST = "@sampling/fetch_sampling/request",
    FETCH_SAMPLING_START = "@sampling/fetch_sampling/start",
    FETCH_SAMPLING_SUCCESS = "@sampling/fetch_sampling/success",
    FETCH_SAMPLING_FAILURE = "@sampling/fetch_sampling/failure",

    EDIT_SAMPLING_REQUEST = "@sampling/edit_sampling/request",
    EDIT_SAMPLING_START = "@sampling/edit_sampling/start",
    EDIT_SAMPLING_SUCCESS = "@sampling/edit_sampling/success",
    EDIT_SAMPLING_FAILURE = "@sampling/edit_sampling/failure",

    TOGGLE_SAMPLING_NODE_EXPANSION = "@sampling/sampling_node_expansion/toggle",

    TOGGLE_SAMPLING_NODE_CUSTOMER_PERFORMANCE = "@sampling/sampling_node_customer_performance/toggle",

    TOGGLE_SAMPLING_NOT_MANDATORY = "@sampling/sampling_node_not_mandatory/toggle",

    TOGGLE_SAMPLING_NODE_SPECIAL_PRICING = "@sampling/sampling_node_special_pricing/toggle",

    TOGGLE_SAMPLING_NODE_PROTOCOL_EXCLUSION = "@sampling/sampling_node_protocol_exclusion/toggle",

    TOGGLE_SAMPLING_NODE_SELECTION = "@sampling/sampling_node_selection/toggle",

    EDIT_SAMPLING_NODE_COMMENT = "@sampling/sampling_node_edit_comment",

    RENAME_CLONED_NONE = "@sampling/rename_cloned_node",

    CLONE_SAMPLING_NODE = "@sampling/sampling_node_clone",

    SET_NAVTREE_CATEGORY = "@sampling/set_navtree_category",

    SET_PROJECT_FOR_SAMPLING = "@sampling/set_project_for_sampling",

    SWITCH_SAMPLING_NODE_BEHAVIOUR = "@sampling/switch_sampling_node_behaviour",

    DELETE_CLONED_SAMPLING_NODE = "@sampling/delete_cloned_sampling_node",

    REPLACE_SAMPLING_TREE = "@sampling/internal/replace_tree"
}

export type SamplingFetchRequestAction = { type: SamplingActionTypes.FETCH_SAMPLING_REQUEST }
export type SamplingFetchStartAction = { type: SamplingActionTypes.FETCH_SAMPLING_START }
export type SamplingFetchSuccessAction = { type: SamplingActionTypes.FETCH_SAMPLING_SUCCESS, tree: Array<SamplingTreeNode> }
export type SamplingFetchFailureAction = { type: SamplingActionTypes.FETCH_SAMPLING_FAILURE, error: string }

export type SamplingEditingRequestAction = { type: SamplingActionTypes.EDIT_SAMPLING_REQUEST }
export type SamplingEditingStartAction = { type: SamplingActionTypes.EDIT_SAMPLING_START }
export type SamplingEditingSuccessAction = { type: SamplingActionTypes.EDIT_SAMPLING_SUCCESS, tree: Array<SamplingTreeNode> }
export type SamplingEditingFailureAction = { type: SamplingActionTypes.EDIT_SAMPLING_FAILURE, error: string }

export type SamplingNodeExpansionToggleAction = { type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_EXPANSION, node: SamplingTreeNode }

export type SamplingNodeCustomerPerformanceToggleAction = { type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_CUSTOMER_PERFORMANCE, node: SamplingTreeNode }

export type SamplingNodeNotMandatoryToggleAction = { type: SamplingActionTypes.TOGGLE_SAMPLING_NOT_MANDATORY, node: SamplingTreeNode }

export type SamplingNodeSpecialPricingToggleAction = { type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_SPECIAL_PRICING, node: SamplingTreeNode }

export type SamplingNodeProtocolExclusionToggleAction = { type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_PROTOCOL_EXCLUSION, node: SamplingTreeNode }

export type SamplingSetProjectAction = { type: SamplingActionTypes.SET_PROJECT_FOR_SAMPLING, project: Project }

export type SamplingNodeSelectionToggleAction = { type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_SELECTION, node: SamplingTreeNode }

export type SamplingNodeCloningAction = { type: SamplingActionTypes.CLONE_SAMPLING_NODE, source: SamplingTreeNode, clone: SamplingTreeNode }

export type SamplingNodeCloneRenameAction = { type: SamplingActionTypes.RENAME_CLONED_NONE, node: SamplingTreeNode }

export type SamplingNodeSwitchBehaviourAction = { type: SamplingActionTypes.SWITCH_SAMPLING_NODE_BEHAVIOUR, node: SamplingTreeNode }

export type SamplingReplaceTreeInternalAction = { type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree: SamplingTreeNode }

export type SamplingNodeEditCommentAction = { type: SamplingActionTypes.EDIT_SAMPLING_NODE_COMMENT, node: SamplingTreeNode }

export type SamplingSetNavtreeCategoryAction = { type: SamplingActionTypes.SET_NAVTREE_CATEGORY, node: SamplingTreeNode }

export type SamplingNodeDeleteCloneAction = { type: SamplingActionTypes.DELETE_CLONED_SAMPLING_NODE, clone: SamplingTreeNode }

export type SamplingAction =
    SamplingFetchRequestAction | SamplingFetchStartAction | SamplingFetchSuccessAction | SamplingFetchFailureAction |
    SamplingEditingRequestAction | SamplingEditingStartAction | SamplingEditingSuccessAction | SamplingEditingFailureAction |
    SamplingNodeExpansionToggleAction | SamplingNodeSwitchBehaviourAction | SamplingReplaceTreeInternalAction | SamplingSetProjectAction |
    SamplingNodeSelectionToggleAction | SamplingNodeNotMandatoryToggleAction | SamplingNodeSpecialPricingToggleAction | SamplingNodeCustomerPerformanceToggleAction |
    SamplingNodeEditCommentAction | SamplingSetNavtreeCategoryAction | SamplingNodeCloningAction | SamplingNodeProtocolExclusionToggleAction | SamplingNodeDeleteCloneAction |
    SamplingNodeCloneRenameAction

/* Sagas */

// Sampling Fetch
function* samplingFetchSaga() {
    yield takeLatest(SamplingActionTypes.FETCH_SAMPLING_REQUEST, function* (action: SamplingFetchRequestAction) {
        yield put({ type: SamplingActionTypes.FETCH_SAMPLING_START })
        const project = yield select((state: ApplicationState) => state.sampling.projectId)

        try {
            const result: AxiosResponse = yield call(BackendAPI.fetchProjectTree(project))
            const tree: Array<SamplingTreeNode> = (result.data as Array<any>).map(rawNode => SamplingTreeNode.valueOf(rawNode))

            yield put({ type: SamplingActionTypes.FETCH_SAMPLING_SUCCESS, tree })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)

            yield put({ type: SamplingActionTypes.FETCH_SAMPLING_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Sampling Editing
function* samplingEditingSaga() {
    yield takeEvery(SamplingActionTypes.EDIT_SAMPLING_REQUEST, function* (action: SamplingEditingRequestAction) {
        yield put({ type: SamplingActionTypes.EDIT_SAMPLING_START })
        const project = yield select((state: ApplicationState) => state.sampling.projectId)

        const treeTemplate: Array<SamplingTreeNode> = yield select((state: ApplicationState) => state.sampling.tree)

        try {
            const result: AxiosResponse = yield call(BackendAPI.patchProjectTree(project, treeTemplate))
            const tree: Array<SamplingTreeNode> = (result.data as Array<any>).map(rawNode => SamplingTreeNode.valueOf(rawNode))

            yield put({ type: SamplingActionTypes.EDIT_SAMPLING_SUCCESS, tree })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)

            yield put({ type: SamplingActionTypes.EDIT_SAMPLING_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Node Expansion Nesting
function* nodeExpansionSaga() {
    yield takeEvery(SamplingActionTypes.TOGGLE_SAMPLING_NODE_EXPANSION, function* (action: SamplingNodeExpansionToggleAction) {
        const node: SamplingTreeNode = action.node
        const expandedNodes: Array<string> = yield select((state: ApplicationState) => state.sampling.expandedNodes)

        // Toggle every node that is below this one - this will automatically recurse down the whole tree (if necessary)
        const effects: Array<Effect> = node.children
            .filter(child => expandedNodes.indexOf(child.id) !== -1)
            .map(child => put({ type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_EXPANSION, node: child }))

        // Trigger the actions concurrently
        yield all(effects)
    })
}

// Set Project for Sampling
function* setProjectForSamplingSaga() {
    yield takeEvery(SamplingActionTypes.SET_PROJECT_FOR_SAMPLING, function* (action: SamplingSetProjectAction) {
        // Trigger the download of the selected project
        yield put({ type: SamplingActionTypes.FETCH_SAMPLING_REQUEST })

        // Redirect the user to the Sampling View
        yield put(push("/sampling"))
    })
}

// Node Toggle Customer Performance
function* nodeToggleCustomerPerformanceSaga() {
    yield takeEvery(SamplingActionTypes.TOGGLE_SAMPLING_NODE_CUSTOMER_PERFORMANCE, function* (action: SamplingNodeCustomerPerformanceToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulating it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Toggle the value as required by the action
        if (node.isCustomerPerformance()) {
            node.flags = node.flags.filter(flag => flag !== Flags.PERFORMED_BY_CUSTOMER)
        } else node.flags = [...node.flags, Flags.PERFORMED_BY_CUSTOMER]

        // Trigger the Tree Reconciler to enforce the sampling logic
        TreeReconciler.reconcileTree(tree, node)

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Node Toggle Not Mandatory
function* nodeToggleNotMandatorySaga() {
    yield takeEvery(SamplingActionTypes.TOGGLE_SAMPLING_NOT_MANDATORY, function* (action: SamplingNodeNotMandatoryToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Toggle the value as required by the action
        if (node.isNotMandatory()) {
            node.flags = node.flags.filter(flag => flag !== Flags.NOT_MANDATORY)
        } else node.flags = [...new Set([...node.flags, Flags.NOT_MANDATORY, Flags.EXCLUDED_FROM_PROTOCOL])]

        // Trigger the Tree Reconciler to enforce the sampling logic
        TreeReconciler.reconcileTree(tree, node)

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Node Toggle Special Pricing
function* nodeToggleSpecialPricingSaga() {
    yield takeEvery(SamplingActionTypes.TOGGLE_SAMPLING_NODE_SPECIAL_PRICING, function* (action: SamplingNodeSpecialPricingToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Toggle the value as required by the action
        // Because this flag doesn't affect the tree logic, we don't need to reconcile the tree
        if (node.isSpecialPricing()) {
            node.flags = node.flags.filter(flag => flag !== Flags.SPECIAL_PRICING)
        } else node.flags = [...node.flags, Flags.SPECIAL_PRICING]

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Node Toggle Protocol Exclusion
function* nodeToggleProtocolExclusionSaga() {
    yield takeEvery(SamplingActionTypes.TOGGLE_SAMPLING_NODE_PROTOCOL_EXCLUSION, function* (action: SamplingNodeProtocolExclusionToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Toggle the value as required by the action
        // Because this flag doesn't affect the tree logic, we don't need to reconcile the tree
        if (node.isExcludedFromProtocol()) {
            node.flags = node.flags.filter(flag => flag !== Flags.EXCLUDED_FROM_PROTOCOL)
        } else node.flags = [...node.flags, Flags.EXCLUDED_FROM_PROTOCOL]

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Node Toggle Selection
function* nodeToggleSelectionSaga() {
    yield takeEvery(SamplingActionTypes.TOGGLE_SAMPLING_NODE_SELECTION, function* (action: SamplingNodeSelectionToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return
        if (!node.isLeaf()) return

        // Toggle the value as required by the action
        if (node.isLeafSelected()) {
            node.flags = node.flags.filter(flag => flag !== Flags.LEAF_SELECTED)
        } else node.flags = [...node.flags, Flags.LEAF_SELECTED]

        // Trigger the Tree Reconciler to enforce the sampling logic
        TreeReconciler.reconcileTree(tree, node)

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Node Comment Editing
function* nodeCommentEditingSaga() {
    yield takeEvery(SamplingActionTypes.EDIT_SAMPLING_NODE_COMMENT, function* (action: SamplingNodeEditCommentAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Copy the nodes' notes
        node.comment = (action.node.comment || "").trim()

        // Provide a state reason
        if (node.isCustomWish()) {
            node.flags = node.flags.filter(flag => flag !== Flags.CUSTOM_WISH)
        } else node.flags = [...new Set([...node.flags, Flags.CUSTOM_WISH, Flags.LEAF_SELECTED])]

        // We only need to trigger the tree reconciler, if the node requires a comment - otherwise comments does not affect the tree logic
        if (node.isCommentRequired()) {
            TreeReconciler.reconcileTree(tree, node)
        }

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Clone Renaming
function* clonedNodeRenameSaga() {
    yield takeEvery(SamplingActionTypes.RENAME_CLONED_NONE, function* (action: SamplingNodeCloneRenameAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulating it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Copy the nodes' notes
        node.title = (action.node.title || "").trim()

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Node Cloning
function* nodeCloningSaga() {
    yield takeEvery(SamplingActionTypes.CLONE_SAMPLING_NODE, function* (action: SamplingNodeCloningAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)

        const clone = action.clone
        const source = tree.getReferenceOf(action.source.id)

        if (!source) return

        const sourceParent = tree.getParentOf(source)

        if (!sourceParent) {
            return
        }

        // Add clone flag to the cloned element
        clone.flags = [...new Set([...clone.flags, Flags.CLONE])]

        // Insert the cloned element right after the original one
        const sourceIndex = sourceParent.children.indexOf(source)
        const size = sourceParent.children.length
        const modifiedChildren = []

        for (let index = 0; index < size; index++) {
            modifiedChildren.push(sourceParent.children[index])

            if (index !== sourceIndex) {
                continue
            }

            modifiedChildren.push(clone)
        }

        sourceParent.children = modifiedChildren

        // This action requires a tree reconciling because the now missing node could change the sampling state of the whole tree
        TreeReconciler.reconcileTree(tree, sourceParent)

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Clone Deletion
function* cloneDeletionSaga() {
    yield takeEvery(SamplingActionTypes.DELETE_CLONED_SAMPLING_NODE, function* (action: SamplingNodeDeleteCloneAction) {
        const originalTree = yield select((state: ApplicationState) => state.sampling.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = SamplingTreeNode.valueOf(originalTree)

        const clone = tree.getReferenceOf(action.clone.id)
        if (!clone) return
        if (!clone.isClone()) return

        const parent = tree.getParentOf(clone)
        if (!parent) return

        // Remove the cloned Node
        parent.children = parent.children.filter(child => !child.equals(clone))

        // This action requires a tree reconciling because the now missing node could change the sampling state of the whole tree
        TreeReconciler.reconcileTree(tree, parent)

        // Send the state update!
        yield put({ type: SamplingActionTypes.REPLACE_SAMPLING_TREE, tree })
    })
}

// Export a combined Saga
export function* saga() {
    yield all([
        fork(samplingFetchSaga),
        fork(samplingEditingSaga),
        fork(nodeExpansionSaga),
        fork(setProjectForSamplingSaga),
        fork(nodeToggleCustomerPerformanceSaga),
        fork(nodeToggleNotMandatorySaga),
        fork(nodeToggleSpecialPricingSaga),
        fork(nodeToggleSelectionSaga),
        fork(nodeCommentEditingSaga),
        fork(nodeToggleProtocolExclusionSaga),
        fork(nodeCloningSaga),
        fork(cloneDeletionSaga),
        fork(clonedNodeRenameSaga)
    ])
}
import React, {Component} from "react"
import Helmet from "react-helmet"
import MasterdataTreeNode from "../typescript/objects/MasterdataTreeNode"
import {ApplicationState} from "../state/store"
import {Dispatch} from "redux"
import {connect} from "react-redux"
import {MasterdataActionTypes} from "../state/ducks/masterdata.duck"
import MasterdataTree from "../components/routes/masterdata/MasterdataTree"
import MasterdataBar from "../components/routes/masterdata/MasterdataBar";

interface State { }

interface ComponentProps { }

interface ReduxProps {
    tree: Array<MasterdataTreeNode>
}

interface ReduxActions {
    loadMasterdata(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class MasterdataView extends Component<Props, State> {

    componentDidMount = () => this.props.loadMasterdata()

    render = () => (
        <>
            <Helmet
                title="Stammdaten - Heimerl 4.0"
                meta={[{ name: "description", content: "Stammdaten Verwaltung" }]}
            />

            <MasterdataBar />
            <hr />

            {this.props.tree &&
                <div id="masterdata">
                    <MasterdataTree tree={this.props.tree} />
                </div>
            }
        </>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    tree: state.masterdata.tree
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadMasterdata: () => dispatch({ type: MasterdataActionTypes.FETCH_MASTERDATA_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(MasterdataView)
import React from "react"
import "./UserToolbar.sass"
import User from "../../../typescript/objects/User"
import {connect} from "react-redux";
import {Dispatch} from "redux";
import {ApplicationState} from "../../../state/store";
import {UsersActionTypes} from "../../../state/ducks/users.duck";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import Button from "reactstrap/lib/Button";
import Icon from "../../utils/Icon";
import {withPortal} from 'react-portalgun'
import {Roles} from "../../../typescript/constants/Roles";

interface ComponentProps {
    user: User
}

interface ReduxProps {
    currentUser: User
}

interface ReduxActions {
    deleteUser(user: User): void
}

interface PortalProps {
    openEditor(param: any): void
    openRoles(param: any): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

const UserToolbar = (props: Props) => (
    <ButtonGroup>
        {props.currentUser.hasRole(Roles.MANAGE_ROLES) &&
            <Button disabled={props.user.admin || props.user.equals(props.currentUser)} color="primary" onClick={() => props.openRoles({ user: props.user })}>
                <Icon type="fas" icon="user-shield" />
            </Button>
        }

        <Button color="primary" onClick={() => props.openEditor({ user: props.user })}>
            <Icon type="fas" icon="edit" />
        </Button>

        {props.currentUser.hasRole(Roles.MANAGE_USERS) &&
            <Button disabled={props.user.admin || props.user.equals(props.currentUser)} color="danger" onClick={() => props.deleteUser(props.user)}>
                <Icon type="fas" icon="trash" />
            </Button>
        }
    </ButtonGroup>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    currentUser: state.authentication.user!
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    deleteUser: (user: User) => dispatch({ type: UsersActionTypes.DELETE_USER_REQUEST, user })
})

export default withPortal("EDIT_USER", "openEditor")(
    withPortal("USER_ROLES", "openRoles")(
        connect(mapStateToProps, mapDispatchToProps)(UserToolbar)
    )
)
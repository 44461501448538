import Project from '../../typescript/objects/Project'
import {AnyAction, Reducer} from "redux"
import * as BackendAPI from "../../typescript/backend/BackendAPI"
import {all, call, fork, put, select, takeEvery} from "redux-saga/effects"
import {AxiosResponse} from "axios"
import {ApplicationState} from '../store';
import SearchFilters from '../../typescript/utils/SearchFilters';
import {SearchActionTypes} from './search.duck';

const initialState: ProjectsState = {
    projects: [],
    filteredProjects: [],
    busy: false
}

export const reducer: Reducer<ProjectsState> = (state: ProjectsState = initialState, action: ProjectsAction) => {
    switch (action.type) {
        case ProjectsActionTypes.FETCH_PROJECTS_START:
        case ProjectsActionTypes.CREATE_PROJECT_START:
        case ProjectsActionTypes.EDIT_PROJECT_START:
        case ProjectsActionTypes.DELETE_PROJECT_START:
        case ProjectsActionTypes.COMPLETE_PROJECT_START:
            return { ...state, busy: true }

        case ProjectsActionTypes.FETCH_PROJECTS_FAILURE:
        case ProjectsActionTypes.CREATE_PROJECT_FAILURE:
        case ProjectsActionTypes.EDIT_PROJECT_FAILURE:
        case ProjectsActionTypes.DELETE_PROJECT_FAILURE:
        case ProjectsActionTypes.COMPLETE_PROJECT_FAILURE:
            return { ...state, busy: false, error: action.error }

        case ProjectsActionTypes.FETCH_PROJECTS_SUCCESS:
            return { ...state, busy: false, error: undefined, projects: action.projects }

        case ProjectsActionTypes.CREATE_PROJECT_SUCCESS:
            return { ...state, busy: false, error: undefined, projects: [...state.projects, action.project] }

        case ProjectsActionTypes.EDIT_PROJECT_SUCCESS: 
            return { ...state, busy: false, error: undefined, projects: [...state.projects.filter(project => project.id !== action.project.id), action.project] }

        case ProjectsActionTypes.DELETE_PROJECT_SUCCESS:
        case ProjectsActionTypes.COMPLETE_PROJECT_SUCCESS:
            return { ...state, busy: false, error: undefined, projects: state.projects.filter(project => project.id !== action.project.id) }

        case ProjectsActionTypes.REPLACE_FILTERED_PROJECTS:
            return { ...state, filteredProjects: action.filteredProjects }
        
        default:
            return state
    }
}

export interface ProjectsState {
    readonly projects: Array<Project>
    readonly filteredProjects: Array<Project>
    readonly busy: boolean
    readonly error?: string
}

export enum ProjectsActionTypes {
    FETCH_PROJECTS_REQUEST = "@projects/fetch_projects/request",
    FETCH_PROJECTS_START = "@projects/fetch_projects/start",
    FETCH_PROJECTS_SUCCESS = "@projects/fetch_projects/success",
    FETCH_PROJECTS_FAILURE = "@projects/fetch_projects/failure",

    CREATE_PROJECT_REQUEST = "@projects/create_project/request",
    CREATE_PROJECT_START = "@projects/create_project/start",
    CREATE_PROJECT_SUCCESS = "@projects/create_project/success",
    CREATE_PROJECT_FAILURE = "@projects/create_project/failure",

    EDIT_PROJECT_REQUEST = "@projects/edit_project/request",
    EDIT_PROJECT_START = "@projects/edit_project/start",
    EDIT_PROJECT_SUCCESS = "@projects/edit_project/success",
    EDIT_PROJECT_FAILURE = "@projects/edit_project/failure",

    DELETE_PROJECT_REQUEST = "@projects/delete_project/request",
    DELETE_PROJECT_START = "@projects/delete_project/start",
    DELETE_PROJECT_SUCCESS = "@projects/delete_project/success",
    DELETE_PROJECT_FAILURE = "@projects/delete_project/failure",

    COMPLETE_PROJECT_REQUEST = "@projects/complete_project/request",
    COMPLETE_PROJECT_START = "@projects/complete_project/start",
    COMPLETE_PROJECT_SUCCESS = "@projects/complete_project/success",
    COMPLETE_PROJECT_FAILURE = "@projects/complete_project/failure",

    REPLACE_FILTERED_PROJECTS = "@projects/replace_filtered_list"
}

export type ProjectsFetchRequestAction = { type: ProjectsActionTypes.FETCH_PROJECTS_REQUEST }
export type ProjectsFetchStartAction = { type: ProjectsActionTypes.FETCH_PROJECTS_START }
export type ProjectsFetchSuccessAction = { type: ProjectsActionTypes.FETCH_PROJECTS_SUCCESS, projects: Array<Project> }
export type ProjectsFetchFailureAction = { type: ProjectsActionTypes.FETCH_PROJECTS_FAILURE, error: string }

export type ProjectCreationRequestAction = { type: ProjectsActionTypes.CREATE_PROJECT_REQUEST, project: Project  }
export type ProjectCreationStartAction = { type: ProjectsActionTypes.CREATE_PROJECT_START }
export type ProjectCreationSuccessAction = { type: ProjectsActionTypes.CREATE_PROJECT_SUCCESS, project: Project }
export type ProjectCreationFailureAction = { type: ProjectsActionTypes.CREATE_PROJECT_FAILURE, error: string }

export type ProjectEditingRequestAction = { type: ProjectsActionTypes.EDIT_PROJECT_REQUEST, project: Project  }
export type ProjectEditingStartAction = { type: ProjectsActionTypes.EDIT_PROJECT_START }
export type ProjectEditingSuccessAction = { type: ProjectsActionTypes.EDIT_PROJECT_SUCCESS, project: Project }
export type ProjectEditingFailureAction = { type: ProjectsActionTypes.EDIT_PROJECT_FAILURE, error: string }

export type ProjectDeletionRequestAction = { type: ProjectsActionTypes.DELETE_PROJECT_REQUEST, project: Project  }
export type ProjectDeletionStartAction = { type: ProjectsActionTypes.DELETE_PROJECT_START }
export type ProjectDeletionSuccessAction = { type: ProjectsActionTypes.DELETE_PROJECT_SUCCESS, project: Project }
export type ProjectDeletionFailureAction = { type: ProjectsActionTypes.DELETE_PROJECT_FAILURE, error: string }

export type ProjectCompletionRequestAction = { type: ProjectsActionTypes.COMPLETE_PROJECT_REQUEST, project: Project  }
export type ProjectCompletionStartAction = { type: ProjectsActionTypes.COMPLETE_PROJECT_START }
export type ProjectCompletionSuccessAction = { type: ProjectsActionTypes.COMPLETE_PROJECT_SUCCESS, project: Project }
export type ProjectCompletionFailureAction = { type: ProjectsActionTypes.COMPLETE_PROJECT_FAILURE, error: string }

export type ReplaceFilteredProjectsAction = { type: ProjectsActionTypes.REPLACE_FILTERED_PROJECTS, filteredProjects: Array<Project> }

export type ProjectsAction = 
ProjectsFetchRequestAction | ProjectsFetchStartAction | ProjectsFetchSuccessAction | ProjectsFetchFailureAction |
ProjectCreationRequestAction | ProjectCreationStartAction | ProjectCreationSuccessAction | ProjectCreationFailureAction |
ProjectEditingRequestAction | ProjectEditingStartAction | ProjectEditingSuccessAction | ProjectEditingFailureAction |
ProjectDeletionRequestAction | ProjectDeletionStartAction | ProjectDeletionSuccessAction | ProjectDeletionFailureAction |
ProjectCompletionRequestAction | ProjectCompletionStartAction | ProjectCompletionSuccessAction | ProjectCompletionFailureAction |
ReplaceFilteredProjectsAction

/* Sagas */

// Replace Filtered Projects List

function* replaceFilteredProjectsSaga() {

    const replace = function*(action: AnyAction) {
        const searchText = yield select((state: ApplicationState) => state.search.text)
        const projects = yield select((state: ApplicationState) => state.projects.projects)
        const filteredProjects = projects.filter(SearchFilters.projectFilter(searchText))

        yield put({ type: ProjectsActionTypes.REPLACE_FILTERED_PROJECTS, filteredProjects })
    }

    yield all([
        takeEvery(SearchActionTypes.SET_TEXT, replace),
        takeEvery(SearchActionTypes.CLEAR_TEXT, replace),
        takeEvery(ProjectsActionTypes.FETCH_PROJECTS_SUCCESS, replace),
        takeEvery(ProjectsActionTypes.EDIT_PROJECT_SUCCESS, replace),
        takeEvery(ProjectsActionTypes.DELETE_PROJECT_SUCCESS, replace),
        takeEvery(ProjectsActionTypes.CREATE_PROJECT_SUCCESS, replace),
        takeEvery(ProjectsActionTypes.COMPLETE_PROJECT_SUCCESS, replace),
    ])
}

// Projects Fetch
function* projectsFetchSaga() {
    yield takeEvery(ProjectsActionTypes.FETCH_PROJECTS_REQUEST, function*(action: ProjectsFetchRequestAction) {
        yield put({ type: ProjectsActionTypes.FETCH_PROJECTS_START })

        try {
            const result: AxiosResponse = yield call(BackendAPI.fetchProjects())
            const projects: Array<Project> = (result.data as Array<any>).map(rawProject => new Project(rawProject))

            yield put({ type: ProjectsActionTypes.FETCH_PROJECTS_SUCCESS, projects })
        } catch (error) {
            const message: string = error.response ? JSON.stringify(error.response) : JSON.stringify(error)
    
            yield put({ type: ProjectsActionTypes.FETCH_PROJECTS_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Project Creation
function* projectCreationSaga() {
    yield takeEvery(ProjectsActionTypes.CREATE_PROJECT_REQUEST, function*(action: ProjectCreationRequestAction) {
        yield put({ type: ProjectsActionTypes.CREATE_PROJECT_START })

        const projectTemplate: Project = action.project
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.createProject(projectTemplate))
            const project: Project = new Project(result.data)

            yield put({ type: ProjectsActionTypes.CREATE_PROJECT_SUCCESS, project })
        } catch (error) {
            const message: string = error.response ? JSON.stringify(error.response) : JSON.stringify(error)
    
            yield put({ type: ProjectsActionTypes.CREATE_PROJECT_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Project Editing
function* projectEditingSaga() {
    yield takeEvery(ProjectsActionTypes.EDIT_PROJECT_REQUEST, function*(action: ProjectEditingRequestAction) {
        yield put({ type: ProjectsActionTypes.EDIT_PROJECT_START })

        const projectTemplate: Project = action.project
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.patchProject(projectTemplate))
            const project: Project = new Project(result.data)

            yield put({ type: ProjectsActionTypes.EDIT_PROJECT_SUCCESS, project })
        } catch (error) {
            const message: string = error.response ? JSON.stringify(error.response) : JSON.stringify(error)
    
            yield put({ type: ProjectsActionTypes.EDIT_PROJECT_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Project Deletion
function* projectDeletionSaga() {
    yield takeEvery(ProjectsActionTypes.DELETE_PROJECT_REQUEST, function*(action: ProjectDeletionRequestAction) {
        yield put({ type: ProjectsActionTypes.DELETE_PROJECT_START })

        const project: Project = action.project
    
        try {
            yield call(BackendAPI.deleteProject(project))
            yield put({ type: ProjectsActionTypes.DELETE_PROJECT_SUCCESS, project })
        } catch (error) {
            const message: string = error.response ? JSON.stringify(error.response) : JSON.stringify(error)
    
            yield put({ type: ProjectsActionTypes.DELETE_PROJECT_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Project Completion
function* projectCompletionSaga() {
    yield takeEvery(ProjectsActionTypes.COMPLETE_PROJECT_REQUEST, function*(action: ProjectCompletionRequestAction) {
        yield put({ type: ProjectsActionTypes.COMPLETE_PROJECT_START })

        const project: Project = action.project

        try {
            yield call(BackendAPI.completeProject(project))
            yield put({ type: ProjectsActionTypes.COMPLETE_PROJECT_SUCCESS, project })
        } catch (error) {
            const message: string = error.response ? JSON.stringify(error.response) : JSON.stringify(error)

            yield put({ type: ProjectsActionTypes.COMPLETE_PROJECT_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Export a combined Saga
export function* saga() {
    yield all([
        fork(projectsFetchSaga),
        fork(projectCreationSaga),
        fork(projectEditingSaga),
        fork(projectDeletionSaga),
        fork(projectCompletionSaga),
        fork(replaceFilteredProjectsSaga)
    ])
}
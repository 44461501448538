import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import Project from '../../../typescript/objects/Project'
import Label from 'reactstrap/lib/Label'
import {Form, FormState, FormValues, Option, Select, Text} from 'informed'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {ApplicationState} from '../../../state/store'
import {ProjectsActionTypes} from '../../../state/ducks/projects.duck'
import User from '../../../typescript/objects/User';
import Customer from '../../../typescript/objects/Customer';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormValidators from '../../../typescript/utils/FormValidators';

interface State { }

interface ComponentProps { }

interface ReduxProps {
    users: Array<User>
    customers: Array<Customer>
    currentUser: User
}

interface PortalProps {
    onClose(): void
}

interface ReduxActions {
    createProject(project: Project): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class ProjectCreationModal extends Component<Props, State> {

    save = (values: FormValues) => {
        // Prepare a new project
        const project = new Project(values)

        const customer = new Customer(this.props.customers.find(customer => customer.id === values.customer))
        const manager = new User(this.props.users.find(user => user.id === values.manager))

        project.customer = customer
        project.manager = manager

        // Trigger the Project Creation
        this.props.createProject(project)

        // Close the Modal
        this.props.onClose()
    }

    render = () => (
        <Modal isOpen size="lg">
            <ModalHeader>
                Neues Bauvorhaben
            </ModalHeader>

            <Form onSubmit={this.save}>
                {({ formState }: { formState: FormState<FormValues> }) => (
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="name">Bezeichnung</Label>
                                <Text field="name"
                                    className={`form-control${FormValidators.validateText(formState.values.name) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.name)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="year">Baujahr</Label>
                                <Text field="year"
                                    className={`form-control${FormValidators.validateNumber(formState.values.year) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateNumber}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateNumber(formState.values.year)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="street">Straße (Bauort)</Label>
                                <Text field="street"
                                    className={`form-control${FormValidators.validateText(formState.values.street) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.street)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="houseNumber">Hausnummer (Bauort)</Label>
                                <Text field="houseNumber"
                                    className={`form-control${FormValidators.validateHouseNumber(formState.values.houseNumber) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateHouseNumber}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateHouseNumber(formState.values.houseNumber)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="zipCode">Postleitzahl (Bauort)</Label>
                                <Text field="zipCode"
                                    className={`form-control${FormValidators.validateZipCode(formState.values.zipCode) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateZipCode}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateZipCode(formState.values.zipCode)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="city">Stadt (Bauort)</Label>
                                <Text field="city"
                                    className={`form-control${FormValidators.validateText(formState.values.city) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.city)}</FormFeedback>
                            </FormGroup>

                            <Label htmlFor="customer">Kunde</Label>
                            <Select
                                field="customer"
                                initialValue={(this.props.customers
                                    .sort((a, b) => {
                                        return a.createdAt.getTime() > b.createdAt.getTime() ? 1 : a.createdAt.getTime() === b.createdAt.getTime() ? 0 : -1
                                    })
                                    .sort((a, b) => {
                                        return a.creator === this.props.currentUser.id ? -1 : b.creator === this.props.currentUser.id ? 1 : 0
                                    })[0] || {}).id}
                                className="form-control"
                            >
                                <Option disabled>Bitte auswählen...</Option>
                                {this.props.customers
                                    .sort((a, b) => {
                                        return a.createdAt.getTime() > b.createdAt.getTime() ? 1 : a.createdAt.getTime() === b.createdAt.getTime() ? 0 : -1
                                    })
                                    .sort((a, b) => {
                                        return a.creator === this.props.currentUser.id ? -1 : b.creator === this.props.currentUser.id ? 1 : 0
                                    })
                                    .map(customer => (
                                        <Option key={customer.id} value={customer.id}>{customer.surname} {customer.name}</Option>
                                    ))}
                            </Select>

                            <br />

                            <Label htmlFor="manager">Bauleiter</Label>
                            <Select
                                field="manager"
                                initialValue={this.props.currentUser.id}
                                className="form-control"
                            >
                                <Option disabled>Bitte auswählen...</Option>
                                {this.props.users.map(user => (
                                    <Option key={user.id} value={user.id}>{user.surname} {user.name}</Option>
                                ))}
                            </Select>
                        </ModalBody>

                        <ModalFooter>
                            <Button color="danger" onClick={this.props.onClose}>Abbrechen</Button>
                            <Button color="primary" type="submit" disabled={formState.invalid || !formState.values.manager || !formState.values.customer}>Speichern</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    users: state.users.users,
    customers: state.customers.customers,
    currentUser: state.authentication.user!
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    createProject: (project: Project) => dispatch({ type: ProjectsActionTypes.CREATE_PROJECT_REQUEST, project })
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectCreationModal)
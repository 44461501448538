import React, {Component} from "react"
import Jumbotron from "reactstrap/lib/Jumbotron"
import {Link} from "react-router-dom"
import Helmet from "react-helmet";

interface State { }

interface Props { }

class PageNotFound extends Component<Props, State> {

    state: State = {}

    render = () => (
        <>
            <Helmet
                title="Nicht gefunden - Heimerl 4.0"
                meta={[{ name: "description", content: "Seite nicht gefunden" }]}
            />

            <Jumbotron>
                <h2>Die von Ihnen angeforderte Seite wurde nicht gefunden!</h2>
                <hr />

                <Link to="/">Zurück zur Bauvorhabenübersicht</Link>
            </Jumbotron>
        </>
    )

}

export default PageNotFound
import React from "react"

import {connect} from "react-redux"
import {Dispatch} from "redux"

import "./sampling.sass"
import {ApplicationState} from "../../../state/store"
import SamplingTreeNode from "../../../typescript/objects/SamplingTreeNode"
import ListGroup from "reactstrap/lib/ListGroup"
import Icon from "../../utils/Icon"
import TreeNode from "../../utils/TreeNode"
import {SamplingActionTypes} from "../../../state/ducks/sampling.duck"
import SamplingToolbar from "./SamplingToolbar"
import {States} from "../../../typescript/constants/States"
import {Behaviours} from "../../../typescript/constants/Behaviours"
import Project from "../../../typescript/objects/Project"
import {withPortal} from "react-portalgun"
import SearchFilters from './../../../typescript/utils/SearchFilters'
import {TreeNode as TreeNodeType, walkDownAndMatch, walkUpFrom} from "../../../typescript/utils/TreeUtils"

interface ComponentProps {
    readonly tree: Array<SamplingTreeNode>
    readonly project: Project
}

interface ReduxProps {
    readonly category?: string
    readonly search: string
    readonly expandedNodes: Array<string>
}

interface PortalProps {
    openMenu(params: any): void
}

interface ReduxActions {
    toggleNodeSelection(node: SamplingTreeNode): void
    toggleNodeExpansion(node: SamplingTreeNode): void
    setCategory(node: SamplingTreeNode): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

const getIconType = (node: SamplingTreeNode) => {
    switch (node.state) {
        case States.SAMPLED:
            return "node-sampled"

        case States.PARTLY_SAMPLED:
            return "node-partly-sampled"

        case States.NOT_SAMPLED:
            return "node-not-sampled"

        default:
            return "node-state-unknown"
    }
}

const getIcon = (node: SamplingTreeNode) => {
    if (node.isOrganisational()) return <Icon cssClass={getIconType(node)} type="fas" icon="home" />

    if (node.hasChildren()) {
        // Exclusive Elements
        if (node.behaviour === Behaviours.EXCLUSIVE) return <Icon cssClass={getIconType(node)} type="fas" icon="circle" />

        // Kumulative Elements
        if (node.behaviour === Behaviours.CUMULATIVE) return <Icon cssClass={getIconType(node)} type="fas" icon="square" />
    } else {
        if (node.state === States.SAMPLED) {
            // Exclusive Elements without children that are sampled
            if (node.behaviour === Behaviours.EXCLUSIVE) return <Icon cssClass={getIconType(node)} type="far" icon="dot-circle" />

            // Kumulative Elements without children that are sampled
            if (node.behaviour === Behaviours.CUMULATIVE) return <Icon cssClass={getIconType(node)} type="far" icon="check-square" />
        } else {
            // Exclusive Elements without children that are not sampled yet
            if (node.behaviour === Behaviours.EXCLUSIVE) return <Icon cssClass={getIconType(node)} type="far" icon="circle" />

            // Kumulative Elements without children that are not sampled yet
            if (node.behaviour === Behaviours.CUMULATIVE) return <Icon cssClass={getIconType(node)} type="far" icon="square" />
        }
    }

    // Unknown Elements
    return <Icon cssClass={getIconType(node)} type="fas" icon="question" />
}

const handleLeafClick = (node: SamplingTreeNode, props: Props) => {
    if (node.isCommentRequired() && !node.hasComment()) {
        props.openMenu({ node })
        return
    }

    props.toggleNodeSelection(node as SamplingTreeNode)
}

const filterNotSkipped = (node: SamplingTreeNode): boolean => node.state !== States.SKIPPED

const filterNautical = (node: SamplingTreeNode): boolean => node.isNautical()

const navtreeFilter = (node: SamplingTreeNode): boolean => filterNautical(node) && filterNotSkipped(node)

const getSamplingToolbar = (node: SamplingTreeNode) => <SamplingToolbar node={node} />

const searchFilter = (search: string, tree: SamplingTreeNode, node: SamplingTreeNode, navtree: boolean): boolean => {
    const filter = SearchFilters.treeNodeFilter(search)

    if (navtree && !navtreeFilter(node)) {
        return false
    }

    if (!navtree && !filterNotSkipped(node)) {
        return false
    }

    if (filter(node)) {
        return true
    }

    let containsMatch = false

    walkDownAndMatch(node, (node: TreeNodeType) => filter(node), (node: TreeNodeType) => {
        containsMatch = true
    })

    if (containsMatch) return true

    let parentMatches = false

    walkUpFrom(tree, node, (node, parent) => {
        if (filter(node)) {
            parentMatches = true
        }
    })

    if (parentMatches) return true

    return false
}

const SamplingTree = (props: Props) => (
    <div id="tree-box">
        <ListGroup id="navtree">
            {props.tree.map(((node: SamplingTreeNode) => (
                <TreeNode
                    customTitle={(node) => {
                        if (node.isOrganisational()) return `${props.project.customer.name} ${props.project.customer.surname}`
                        return node.title
                    }}
                    shallRender={(treeNode: SamplingTreeNode, parent?: SamplingTreeNode): boolean => (props.search.trim().length > 0 ? searchFilter(props.search, props.tree[0], treeNode, true) : navtreeFilter(treeNode))}
                    onLeafClick={(node) => handleLeafClick(node as SamplingTreeNode, props)}
                    onParentClick={(node) => {
                        if ((node as SamplingTreeNode).isEdgeElement()) {
                            props.setCategory(node as SamplingTreeNode)
                        }
                    }}
                    key={node.id}
                    node={node}
                    toggle={props.toggleNodeExpansion}
                    isExpanded={(node: SamplingTreeNode) => props.expandedNodes.indexOf(node.id) !== -1}
                    getPrefix={getIcon}
                    getToolbar={getSamplingToolbar}
                />
            )))}
        </ListGroup>

        {props.category && props.tree && props.tree[0].getReferenceOf(props.category) &&
            <ListGroup id="datatree">
                {props.tree[0].getReferenceOf(props.category)!.children.map(((node: SamplingTreeNode) => (
                    <TreeNode
                        shallRender={(treeNode: SamplingTreeNode, parent?: SamplingTreeNode): boolean => (props.search.trim().length > 0 ? searchFilter(props.search, props.tree[0], treeNode, false) : filterNotSkipped(treeNode))}
                        onLeafClick={(node) => handleLeafClick(node as SamplingTreeNode, props)}
                        key={node.id}
                        node={node}
                        toggle={props.toggleNodeExpansion}
                        isExpanded={(node: SamplingTreeNode) => props.expandedNodes.indexOf(node.id) !== -1}
                        getPrefix={getIcon}
                        getToolbar={getSamplingToolbar}
                    />
                )))}
            </ListGroup>
        }
    </div>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    category: state.sampling.category,
    expandedNodes: state.sampling.expandedNodes,
    search: state.search.text
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    setCategory: (node: SamplingTreeNode) => dispatch({ type: SamplingActionTypes.SET_NAVTREE_CATEGORY, node }),
    toggleNodeExpansion: (node: SamplingTreeNode) => dispatch({ type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_EXPANSION, node }),
    toggleNodeSelection: (node: SamplingTreeNode) => dispatch({ type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_SELECTION, node })
})

export default withPortal("OPEN_SAMPLING_MENU", "openMenu")(
    connect(mapStateToProps, mapDispatchToProps)(SamplingTree)
)
import axios from "axios"
import User from '../objects/User'
import MasterdataTreeNode from '../objects/MasterdataTreeNode'
import Customer from '../objects/Customer'
import Role from "../objects/Role";
import Project from "../objects/Project";
import SamplingTreeNode from "../objects/SamplingTreeNode";
import Image from "../objects/Image";

/* MasterData */

export const fetchMasterdata = () => () => axios.get(`/masterdata`)

export const pushMasterdata = (tree: Array<MasterdataTreeNode>) => () => axios.patch(`/masterdata`, tree)

/* Users */

export const fetchUsers = () => () => axios.get(`/users`)

export const patchUser = (user: User) => () => axios.patch(`/user/${user.id}`, user)

export const createUser = (user: User) => () => axios.put(`/users`, user)

export const deleteUser = (user: User) => () => axios.delete(`/user/${user.id}`)

/* Authentication */

export const login = (user: string, password: string) => () => axios.post(`/auth/login`, {user, password})

export const refresh = () => () => axios.post(`/auth/refresh`)

/* Customers */

export const fetchCustomers = () => () => axios.get(`/customers`)

export const createCustomer = (customer: Customer) => () => axios.put(`/customers`, customer)

export const getCustomer = (customer: Customer) => () => axios.get(`/customer/${customer.id}`)

export const patchCustomer = (customer: Customer) => () => axios.patch(`/customer/${customer.id}`, customer)

export const deleteCustomer = (customer: Customer) => () => axios.delete(`/customer/${customer.id}`)

/* Roles */

export const fetchRoles = () => () => axios.get(`/roles`)

export const grantRole = (user: User, role: Role) => () => axios.post(`/role/${role.name}/grant/${user.id}`)

export const revokeRole = (user: User, role: Role) => () => axios.post(`/role/${role.name}/revoke/${user.id}`)

/* Projects */

export const fetchProjects = () => () => axios.get(`/projects`)

export const fetchArchivedProjects = () => () => axios.get(`/projects/archived`)

export const createProject = (project: Project) => () => axios.put(`/projects`, project)

export const patchProject = (project: Project) => () => axios.patch(`/project/${project.id}`, project)

export const deleteProject = (project: Project) => () => axios.delete(`/projects/${project.id}`)

export const completeProject = (project: Project) => () => axios.post(`/project/${project.id}/complete`)

export const restoreProject = (project: Project) => () => axios.post(`/project/${project.id}/restore`)

/* Sampling */

export const fetchProjectTree = (project: string) => () => axios.get(`/project/${project}/tree`)

export const patchProjectTree = (project: string, tree: SamplingTreeNode[]) => () => axios.patch(`/project/${project}/tree`, tree)

/* Chronicle */

export const fetchChronicleEntries = () => () => axios.get(`/chronicle`)

/* Backups V2 */

export const getAllBackups = () => () => axios.get(`/backups`)

export const downloadBackup = (fileName: string) => () => axios({
    url: `/backups/${fileName}/download`,
    method: "GET",
    responseType: "blob"
})

/* Images */

export const getAllImages = () => () => axios.get(`/images`)

export const createImage = (image: Omit<Image, "id">) => () => axios.put(`/images`, image)

export const deleteImage = (id: string) => () => axios.delete(`/image/${id}`)
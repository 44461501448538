export enum Roles {
    MANAGE_MASTERDATA = "manage-masterdata",
    MANAGE_USERS = "manage-users",
    MANAGE_ROLES = "manage-roles",
    MANAGE_PROJECTS = "manage-projects",
    MANAGE_CUSTOMERS = "manage-customers",
    CREATE_CUSTOMERS = "create-customers",
    MANAGE_BACKUPS = "manage-backups",
    READ_CHRONICLE = "read-chronicle",
    MANAGE_OWN_USER = "manage-own-user",
    MANAGE_IMAGES = "manage-images",
    
    MANAGE_PROJECT_WITH_ID = "manage-project-"
}
import React from "react"

import {connect} from "react-redux"
import {Dispatch} from "redux"

import "./customers.sass"
import {ApplicationState} from "../../../state/store"
import Button from "reactstrap/lib/Button"
import Icon from "../../utils/Icon";
import {CustomersActionTypes} from "../../../state/ducks/customers.duck"
import {withPortal} from "react-portalgun"

interface ComponentProps { }

interface ReduxProps { }

interface PortalProps {
    openCreator(): void
}

interface ReduxActions {
    loadCustomers(): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

const CustomerBar = (props: Props) => (
    <div className="customer-bar">
        <Button color="primary"
            onClick={props.openCreator}
        >
            <Icon type="fas" icon="plus" />
            &nbsp;
            <span>Neuer Kunde</span>
        </Button>
        <Button color="success"
            onClick={props.loadCustomers}
        >
            <Icon type="fas" icon="sync" />
            &nbsp;
            <span>Wiederherstellen</span>
        </Button>
    </div>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({

})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadCustomers: () => dispatch({ type: CustomersActionTypes.FETCH_CUSTOMERS_REQUEST })
})

export default withPortal("CREATE_CUSTOMER", "openCreator")(
    connect(mapStateToProps, mapDispatchToProps)(CustomerBar)
)
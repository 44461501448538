import React from "react"
import {UncontrolledButtonDropdown} from "reactstrap/lib/Uncontrolled"
import DropdownToggle from "reactstrap/lib/DropdownToggle"
import DropdownMenu from "reactstrap/lib/DropdownMenu"
import DropdownItem from "reactstrap/lib/DropdownItem"
import Icon from "../../utils/Icon"

interface Props { }

const MasterdataLegend = (props: Props) => (
    <UncontrolledButtonDropdown direction="left">
        <DropdownToggle caret color="info">
            <span>
                <Icon type="fas" icon="info-circle" />
                &nbsp;
                Legende
            </span>
        </DropdownToggle>

        <DropdownMenu>
            <DropdownItem header>Formen</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="circle" />&nbsp;Exklusives Element</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="square" />&nbsp;Kumulatives Element</DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>Farben</DropdownItem>
            <DropdownItem disabled><span className="text-primary">Blau</span> - Übergangselement</DropdownItem>
            <DropdownItem disabled><span className="text-danger">Rot</span> - Navigationselement </DropdownItem>
            <DropdownItem disabled><span className="text-secondary">Grau</span> - Detailelement</DropdownItem>
            <DropdownItem divider />
            <DropdownItem header>Aktionen</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="plus" />&nbsp;Kind hinzufügen</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="square" /> / <Icon type="fas" icon="circle" />&nbsp;Verhalten der Kinder ändern</DropdownItem>
            <DropdownItem disabled><Icon type="fas" icon="ellipsis-h" />&nbsp;Erweiterte Optionen</DropdownItem>
        </DropdownMenu>
    </UncontrolledButtonDropdown>
)

export default MasterdataLegend
import {StoreSubscriber} from './StoreSubscriber'
import {Store} from 'redux'
import {ApplicationState} from '../store'
import axios from 'axios'
import {BackendServer} from '../../typescript/objects/BackendServer'

/*
    Whenever the backend server changes, Axios' settings needs to be updated
*/
class AxiosSettingsSubscriber implements StoreSubscriber {

    lastBackend?: BackendServer

    accept = (store: Store<ApplicationState>) => {
        const currentBackend = store.getState().authentication.backend
        
        // If the backend hasn't changed since the last call, we don't need to do anything here
        if (currentBackend === this.lastBackend) return

        // Update the backend variable for the next call
        this.lastBackend = currentBackend

        // Update the Axios Settings to use the new backend as Base URL
        axios.defaults.baseURL = currentBackend.url
    }

}

// We only need StoreSubscribers once, so we export it as a Singleton instance
export default new AxiosSettingsSubscriber()

import React, {Component} from 'react'
import {Button, ButtonGroup, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap'
import Label from 'reactstrap/lib/Label'
import {Form, FormState, FormValues, Text} from 'informed'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {ApplicationState} from '../../../state/store'
import {SamplingActionTypes} from '../../../state/ducks/sampling.duck'
import SamplingTreeNode from '../../../typescript/objects/SamplingTreeNode'
import FormGroup from 'reactstrap/lib/FormGroup'
import FormValidators from '../../../typescript/utils/FormValidators'
import FormFeedback from 'reactstrap/lib/FormFeedback'
import FormText from 'reactstrap/lib/FormText'
import Row from 'reactstrap/lib/Row'
import Col from 'reactstrap/lib/Col'

interface State {
}

interface ComponentProps {
}

interface ReduxProps {
    readonly samplingtree: SamplingTreeNode
}

interface PortalProps {
    readonly node: SamplingTreeNode

    onClose(): void
}

interface ReduxActions {
    editComment(node: SamplingTreeNode): void

    editCloneName(node: SamplingTreeNode): void

    cloneNode(source: SamplingTreeNode, clone: SamplingTreeNode): void

    toggleSpecialPricing(node: SamplingTreeNode): void

    toggleCustomerPerformance(node: SamplingTreeNode): void

    toggleProtocolExclusion(node: SamplingTreeNode): void

    deleteClone(clone: SamplingTreeNode): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class SamplingNodeMenuModal extends Component<Props, State> {

    getNode = () => this.props.samplingtree.getReferenceOf(this.props.node.id)!

    bufferedComment: string = this.getNode().comment;

    bufferCommentString = (values: FormValues) => {
        this.bufferedComment = values.comment.toString()
    }

    storeComment = (value: string) => {
        // Prepare a new Node
        const node = SamplingTreeNode.valueOf(this.getNode())

        // Inject comment
        node.comment = this.bufferedComment

        // Trigger the Comment Editing
        this.props.editComment(node)
    }

    saveComment = (values: FormValues) => {
        // Prepare a new Node
        const node = SamplingTreeNode.valueOf(this.getNode())

        // Inject Form Values
        node.comment = values.comment.toString()

        // Trigger the Comment Editing
        this.props.editComment(node)
    }

    renameClone = (values: FormValues) => {
        // Prepare a new Node
        const node = SamplingTreeNode.valueOf(this.getNode())

        // Inject Form Values
        node.title = values.title.toString()

        // Trigger the Name Editing
        this.props.editCloneName(node)

        // Close the modal after rename
        this.props.onClose()
    }


    saveClone = (values: FormValues) => {
        // Prepare a new Node
        const clone = SamplingTreeNode.cleanCopy(this.getNode())

        // Inject Form Values
        clone.title = values.title.toString()

        // Trigger the node cloning
        this.props.cloneNode(this.getNode(), clone)

        // Close the modal after cloning
        this.props.onClose()
    }

    deleteClone = () => {
        if (!window.confirm("Wollen Sie dieses Element wirklich löschen?")) return
        this.props.onClose()
        this.props.deleteClone(this.props.node)
    }

    render = () => (
        <Modal isOpen size="lg" fade={false}>
            <ModalHeader>
                <Form onSubmit={this.renameClone}>
                    {({formState}: { formState: FormState<FormValues> }) => (
                        <FormGroup>
                            <Label htmlFor="title">Bezeichnung</Label>
                            <Row>
                                <Col xs="auto" md={10}>
                                    <Text field="title"
                                          readOnly={!this.getNode().isClone()}
                                          initialValue={this.getNode().title}
                                          className={`form-control${FormValidators.validateText(formState.values.title) === undefined ? " is-valid" : " is-invalid"}`}
                                          validateOnChange
                                          validateOnMount
                                          validate={FormValidators.validateText}
                                    />
                                    <FormFeedback
                                        invalid="true">{FormValidators.validateText(formState.values.title)}</FormFeedback>

                                </Col>

                                <Col xs="auto" md={2}>
                                    <Button color="primary" type="submit"
                                            disabled={!this.getNode().isClone() || formState.invalid}>Ändern</Button>
                                </Col>
                            </Row>
                        </FormGroup>
                    )}
                </Form>
            </ModalHeader>

            <ModalBody>
                <Form onValueChange={this.bufferCommentString}>
                    {({formState}: { formState: FormState<FormValues> }) => (
                        <FormGroup>
                            <Label htmlFor="comment">Freitextbemerkung</Label>
                            <Row>
                                {this.props.node.isCommentRequired() &&
                                    <Col xs="auto" md={8}>
                                        <Text field="comment"
                                              readOnly={this.getNode().isOrganisational()}
                                              initialValue={this.getNode().comment}
                                              className={`form-control${FormValidators.validateText(formState.values.comment) === undefined ? " is-valid" : " is-invalid"}`}
                                              validateOnChange
                                              validateOnMount
                                              validate={FormValidators.validateText}
                                        />
                                        <FormFeedback
                                            invalid="true">{FormValidators.validateText(formState.values.comment)}</FormFeedback>
                                        <FormText>Für dieses Element ist eine Freitextbemerkung erforderlich.</FormText>
                                    </Col>
                                }

                                {!this.props.node.isCommentRequired() &&
                                    <Col xs="auto" md={8}>
                                        <Text field="comment"
                                              readOnly={this.getNode().isOrganisational()}
                                              initialValue={this.getNode().comment}
                                              className={`form-control${FormValidators.validateOptionalText(formState.values.comment) === undefined ? " is-valid" : " is-invalid"}`}
                                              validateOnChange
                                              validateOnMount
                                              validate={FormValidators.validateOptionalText}
                                        />
                                        <FormFeedback
                                            invalid="true">{FormValidators.validateOptionalText(formState.values.comment)}</FormFeedback>
                                    </Col>
                                }
                            </Row>
                        </FormGroup>
                    )}
                </Form>

                <hr/>

                {this.props.node.isNautical() && // check for a role that overrides this limitation?
                    <>
                        <Form onSubmit={this.saveClone}>
                            {({formState}: { formState: FormState<FormValues> }) => (
                                <FormGroup>
                                    <Label htmlFor="title">Bezeichnung des Klons</Label>
                                    <Row>
                                        <Col xs="auto" md={8}>
                                            <Text field="title"
                                                  readOnly={this.getNode().isOrganisational()}
                                                  initialValue={`Kopie von ${this.getNode().title}`}
                                                  className={`form-control${FormValidators.validateText(formState.values.title) === undefined ? " is-valid" : " is-invalid"}`}
                                                  validateOnChange
                                                  validateOnMount
                                                  validate={FormValidators.validateText}
                                            />
                                            <FormFeedback
                                                invalid="true">{FormValidators.validateText(formState.values.title)}</FormFeedback>
                                            {this.getNode().isOrganisational() &&
                                                <FormText>Dieses Element kann nicht geklont werden.</FormText>}
                                        </Col>

                                        <Col xs="auto" md="auto">
                                            <ButtonGroup className="float-right">
                                                <Button color="primary" type="submit"
                                                        disabled={this.getNode().isOrganisational() || formState.invalid}>Klonen</Button>
                                            </ButtonGroup>
                                        </Col>
                                    </Row>
                                </FormGroup>
                            )}
                        </Form>

                        <hr/>
                    </>
                }

                <ButtonGroup id="flag-buttons">
                    <Button
                        color={this.getNode().isCustomerPerformance() ? "success" : "danger"}
                        active={this.getNode().isCustomerPerformance()}
                        disabled={this.getNode().isOrganisational()}
                        onClick={() => this.props.toggleCustomerPerformance(this.getNode())}
                    >
                        Eigenleistung des Bauherrn &nbsp;
                    </Button>

                    <Button
                        color={this.getNode().isSpecialPricing() ? "success" : "danger"}
                        active={this.getNode().isSpecialPricing()}
                        disabled={this.getNode().isOrganisational()}
                        onClick={() => this.props.toggleSpecialPricing(this.getNode())}
                    >
                        Mehr-/Minderpreis &nbsp;
                    </Button>

                    <Button
                        color={this.getNode().isExcludedFromProtocol() ? "success" : "danger"}
                        active={this.getNode().isExcludedFromProtocol()}
                        disabled={this.getNode().isOrganisational()}
                        onClick={() => this.props.toggleProtocolExclusion(this.getNode())}
                    >
                        Vom Protokoll ausgeschlossen &nbsp;
                    </Button>
                </ButtonGroup>
            </ModalBody>

            <ModalFooter>
                {this.props.node.isClone() &&
                    <Button
                        color="danger"
                        onClick={this.deleteClone}
                    >
                        Element löschen
                    </Button>
                }

                <Button color="primary" onClick={() => {
                    this.storeComment(this.bufferedComment);
                    this.props.onClose()
                }}>Speichern</Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    samplingtree: state.sampling.tree[0]!
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    editComment: (node: SamplingTreeNode) => dispatch({type: SamplingActionTypes.EDIT_SAMPLING_NODE_COMMENT, node}),
    editCloneName: (node: SamplingTreeNode) => dispatch({
        type: SamplingActionTypes.RENAME_CLONED_NONE,
        node
    }),
    cloneNode: (source: SamplingTreeNode, clone: SamplingTreeNode) => dispatch({
        type: SamplingActionTypes.CLONE_SAMPLING_NODE,
        source,
        clone
    }),
    toggleCustomerPerformance: (node: SamplingTreeNode) => dispatch({
        type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_CUSTOMER_PERFORMANCE,
        node
    }),
    toggleSpecialPricing: (node: SamplingTreeNode) => dispatch({
        type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_SPECIAL_PRICING,
        node
    }),
    toggleProtocolExclusion: (node: SamplingTreeNode) => dispatch({
        type: SamplingActionTypes.TOGGLE_SAMPLING_NODE_PROTOCOL_EXCLUSION,
        node
    }),
    deleteClone: (clone: SamplingTreeNode) => dispatch({type: SamplingActionTypes.DELETE_CLONED_SAMPLING_NODE, clone})
})

export default connect(mapStateToProps, mapDispatchToProps)(SamplingNodeMenuModal)
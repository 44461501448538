import Customer from './../objects/Customer';
import User from '../objects/User';
import Project from '../objects/Project';
import {TreeNode} from './TreeUtils';

const shorten = (str: string) => `${str || ""}`.trim().toLowerCase()

class SearchFilters {

    static customerFilter = (search: string) => (customer: Customer) => {
        const searchText = shorten(search)

        if (searchText.length === 0) return true

        return shorten(customer.name).includes(searchText)
            || shorten(customer.surname).includes(searchText)
            || shorten(customer.email).includes(searchText)
            || shorten(customer.company!).includes(searchText)
            || shorten(customer.phone).includes(searchText)
    }

    static userFilter = (search: string) => (user: User) => {
        const searchText = shorten(search)

        if (searchText.length === 0) return true

        return shorten(user.name).includes(searchText)
            || shorten(user.surname).includes(searchText)
            || shorten(user.email).includes(searchText)
            || shorten(user.username).includes(searchText)
    }

    static projectFilter = (search: string) => (project: Project) => {
        const searchText = shorten(search)

        if (searchText.length === 0) return true

        return shorten(project.name).includes(searchText)
            || shorten(project.city).includes(searchText)
            || shorten(project.street).includes(searchText)
            || shorten(project.houseNumber).includes(searchText)
            || shorten(project.zipCode).includes(searchText)
            || shorten(project.manager.name).includes(searchText)
            || shorten(project.manager.surname).includes(searchText)
            || shorten(project.customer.name).includes(searchText)
            || shorten(project.customer.surname).includes(searchText)
    }

    static treeNodeFilter = (search: string) => (node: TreeNode): boolean => {
        const searchText = shorten(search)
        const nodeTitle = shorten(node.title)

        return nodeTitle.includes(searchText)
    }

}

export default SearchFilters

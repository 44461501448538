import {Reducer} from "redux"
import * as BackendAPI from "../../typescript/backend/BackendAPI"
import {all, call, fork, put, takeEvery} from "redux-saga/effects"
import {AxiosResponse} from "axios"
import ChronicleEntry from "../../typescript/objects/ChronicleEntry";

const initialState: ChronicleState = {
    entries: [],
    authenticated: false,
    busy: false
}

export const reducer: Reducer<ChronicleState> = (state: ChronicleState = initialState, action: ChronicleAction) => {
    switch (action.type) {
        case ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_START:
            return { ...state, busy: true }

        case ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_FAILURE:
            return { ...state, busy: false, error: action.error }

        case ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_SUCCESS:
            return { ...state, busy: false, error: undefined, entries: action.entries }

        default:
            return state
    }
}

export interface ChronicleState {
    readonly entries: Array<ChronicleEntry>
    readonly authenticated: boolean

    readonly busy: boolean
    readonly error?: string
}

export enum ChronicleActionTypes {
    FETCH_CHRONICLE_ENTRIES_REQUEST = "@chronicle/fetch_entries/request",
    FETCH_CHRONICLE_ENTRIES_START = "@chronicle/fetch_entries/start",
    FETCH_CHRONICLE_ENTRIES_SUCCESS = "@chronicle/fetch_entries/success",
    FETCH_CHRONICLE_ENTRIES_FAILURE = "@chronicle/fetch_entries/failure",
}

export type ChronicleFetchEntriesRequestAction = { type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_REQUEST }
export type ChronicleFetchEntriesStartAction = { type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_START }
export type ChronicleFetchEntriesSuccessAction = { type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_SUCCESS, entries: Array<ChronicleEntry> }
export type ChronicleFetchEntriesFailureAction = { type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_FAILURE, error: string }

export type ChronicleAction =
    ChronicleFetchEntriesRequestAction | ChronicleFetchEntriesStartAction | ChronicleFetchEntriesFailureAction | ChronicleFetchEntriesSuccessAction

/* Sagas */

// Login
function* fetchChronicleEntriesSaga() {
    yield takeEvery(ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_REQUEST, function* (action: ChronicleFetchEntriesRequestAction) {
        yield put({ type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_START })

        try {
            const result: AxiosResponse = yield call(BackendAPI.fetchChronicleEntries())
            const entries: Array<ChronicleEntry> = (result.data as Array<any>).map(rawEntry => new ChronicleEntry(rawEntry))

            yield put({ type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_SUCCESS, entries })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)

            yield put({ type: ChronicleActionTypes.FETCH_CHRONICLE_ENTRIES_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Export all watchers as a Saga
export function* saga() {
    yield all([
        fork(fetchChronicleEntriesSaga)
    ])
}
import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import User from '../../../typescript/objects/User';
import Label from 'reactstrap/lib/Label';
import {Form, FormState, FormValues, Text} from 'informed';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../state/store';
import {UsersActionTypes} from '../../../state/ducks/users.duck';
import FormGroup from 'reactstrap/lib/FormGroup';
import FormFeedback from 'reactstrap/lib/FormFeedback';
import FormValidators from '../../../typescript/utils/FormValidators';

interface State { }

interface ComponentProps {
    user: User
}

interface ReduxProps { }

interface PortalProps {
    onClose(): void
}

interface ReduxActions {
    editUser(user: User): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class UserEditingModal extends Component<Props, State> {

    save = (values: FormValues) => {
        // Prepare a new user
        const user = new User(this.props.user)

        // Swap the necessary fields with the form values
        user.name = values.name.toString()
        user.surname = values.surname.toString()
        user.email = values.email.toString()
        user.password = values.password.toString()

        // Trigger the User Editing
        this.props.editUser(user)

        // Close the Modal
        this.props.onClose()
    }

    render = () => (
        <Modal isOpen size="lg">
            <ModalHeader>
                {this.props.user.name} {this.props.user.surname} ({this.props.user.username})
            </ModalHeader>

            <Form onSubmit={this.save}>
                {({ formState }: { formState: FormState<FormValues> }) => (
                    <>
                        <ModalBody>
                            <FormGroup>
                                <Label htmlFor="name">Vorname</Label>
                                <Text field="name"
                                    initialValue={this.props.user.name || ""}
                                    className={`form-control${FormValidators.validateText(formState.values.name) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.name)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="surname">Nachname</Label>
                                <Text field="surname"
                                    initialValue={this.props.user.surname || ""}
                                    className={`form-control${FormValidators.validateText(formState.values.surname) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateText}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateText(formState.values.surname)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="email">E-Mail</Label>
                                <Text field="email"
                                    initialValue={this.props.user.email || ""}
                                    className={`form-control${FormValidators.validateEmail(formState.values.email) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateEmail}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateEmail(formState.values.email)}</FormFeedback>
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="password">Passwort</Label>
                                <Text field="password"
                                    type="password"
                                    initialValue=""
                                    className={`form-control${FormValidators.validateOptionalPassword(formState.values.password) === undefined ? " is-valid" : " is-invalid"}`}
                                    validateOnChange
                                    validateOnMount
                                    validate={FormValidators.validateOptionalPassword}
                                />
                                <FormFeedback invalid="true">{FormValidators.validateOptionalPassword(formState.values.password)}</FormFeedback>
                            </FormGroup>

                        </ModalBody>

                        <ModalFooter>
                            <Button color="danger" onClick={this.props.onClose}>Abbrechen</Button>
                            <Button color="primary" type="submit" disabled={formState.invalid}>Speichern</Button>
                        </ModalFooter>
                    </>
                )}
            </Form>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    editUser: (user: User) => dispatch({ type: UsersActionTypes.EDIT_USER_REQUEST, user })
})

export default connect(mapStateToProps, mapDispatchToProps)(UserEditingModal)
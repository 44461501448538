import React, {Component} from "react"
import CustomerTable from "../components/routes/customers/CustomerTable"
import {connect} from "react-redux"
import {Dispatch} from "redux"
import {ApplicationState} from "../state/store"
import Customer from "../typescript/objects/Customer"
import {CustomersActionTypes} from "../state/ducks/customers.duck"
import Helmet from "react-helmet";
import CustomerBar from "../components/routes/customers/CustomerBar";

interface State { }

interface ComponentProps { }

interface ReduxProps {
    customers: Array<Customer>
}

interface ReduxActions {
    loadCustomers(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class CustomersView extends Component<Props, State> {

    // (Re-)Load all customers when the view is mounted
    componentDidMount = () => this.props.loadCustomers()

    render = () => (
        <>
            <Helmet
                title="Kunden - Heimerl 4.0"
                meta={[{ name: "description", content: "Kundenverwaltung" }]}
            />

            <CustomerBar />
            <hr />

            <CustomerTable customers={this.props.customers} />
        </>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    customers: state.customers.filteredCustomers
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadCustomers: () => dispatch({ type: CustomersActionTypes.FETCH_CUSTOMERS_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomersView)
import React from "react"
import {ApplicationState} from "../../../state/store"
import {Dispatch} from "redux"
import {connect} from "react-redux"
import MasterdataTreeNode from "../../../typescript/objects/MasterdataTreeNode"
import {withPortal} from "react-portalgun"
import Button from "reactstrap/lib/Button"
import ButtonGroup from "reactstrap/lib/ButtonGroup"
import Icon from "../../utils/Icon"
import {Behaviours} from "../../../typescript/constants/Behaviours"
import {MasterdataActionTypes} from "../../../state/ducks/masterdata.duck"

interface ComponentProps {
    node: MasterdataTreeNode
}

interface ReduxProps { }

interface PortalProps {
    createNode(params: any): void
    openMenu(params: any): void
}

interface ReduxActions {
    switchBehaviour(node: MasterdataTreeNode): void
    indexUp(node: MasterdataTreeNode): void
    indexDown(node: MasterdataTreeNode): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

const MasterdataToolbar = (props: Props) => (
    <div className="masterdata-toolbar">
        {!props.node.isOrganisational() &&
            <ButtonGroup id="index-buttons">
                <Button
                    size="sm"
                    color="info"
                    onClick={() => props.indexDown(props.node)}
                >
                    <Icon type="fas" icon="arrow-down" />
                </Button>
                <Button
                    size="sm"
                    color="info"
                    onClick={() => props.indexUp(props.node)}
                >
                    <Icon type="fas" icon="arrow-up" />
                </Button>
            </ButtonGroup>
        }

        <ButtonGroup>
            <Button
                size="sm"
                color="primary"
                onClick={() => props.createNode({ parent: props.node })}
            >
                <Icon type="fas" icon="plus" />
            </Button>

            <Button
                size="sm"
                color="primary"
                onClick={() => props.switchBehaviour(props.node)}
            >
                {!props.node.hasChildren() && <Icon type="fas" icon="circle" />}
                {props.node.hasChildren() &&
                    <>
                        {props.node.children[0].behaviour === Behaviours.EXCLUSIVE && <Icon type="fas" icon="circle" />}
                        {props.node.children[0].behaviour === Behaviours.CUMULATIVE && <Icon type="fas" icon="square" />}
                    </>
                }
            </Button>

            <Button
                size="sm"
                color="primary"
                onClick={() => props.openMenu({ node: props.node })}
            >
                <Icon type="fas" icon="ellipsis-h" />
            </Button>
        </ButtonGroup>
    </div >
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({

})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    switchBehaviour: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.SWITCH_MASTERDATA_NODE_BEHAVIOUR, node }),
    indexUp: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.MASTERDATA_NODE_INDEX_UP, node }),
    indexDown: (node: MasterdataTreeNode) => dispatch({ type: MasterdataActionTypes.MASTERDATA_NODE_INDEX_DOWN, node })
})

export default withPortal("CREATE_MASTERDATA_NODE", "createNode")(
    withPortal("OPEN_MASTERDATA_MENU", "openMenu")(
        connect(mapStateToProps, mapDispatchToProps)(MasterdataToolbar)
    )
)
import {StyleSheet} from "@react-pdf/renderer"

//TODO: REFACTOR WITH VARIABLES! header height, footer height, etc
//      footer needs fixing.
//      current proposal: try to remove outer containers. → <header><node><node><node><footer>
//              then page padding

export default StyleSheet.create(
    {
    //custom
        viewer: {
            width: '100%',
            height: "85vh"
        },
    
        page: {
            flexDirection: 'column',
            backgroundColor: '',
    
            padding: '1cm',
            paddingBottom: '2.5cm',
            
        },

        section: {
            //marginTop: '2mm',
            //marginBottom: '1.3cm',
            padding: '3mm',
            //paddingBottom: '2cm',
            
            flexGrow: 1,
            //maxHeight: '70%',
            //minHeight: '85%',

            fontSize: '12pt',

            //border: '1pt solid black',
            borderLeft: '1pt solid black',
            borderRight: '1pt solid black',

            
        },
    
        header: {
            width: '100%',
            height: '1.7cm',
            //margin: '1cm',
    
            backgroundColor: '#006A4D',
            zIndex: '10',
    
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',

            marginBottom: '3mm'
        },
    
        logo: {
            height: '2.6cm',
            width: '4cm',
            backgroundColor: '#FFFFFF'
        },

        headertext: {
            fontSize: '13pt',
            color: '#F0F0F0',
        },
    
        footer: {
            width: '100%',
            height: '1.3cm',
           
            position: 'absolute',
            bottom: '1cm',
            left: '1cm', 
            right: '0',
            //textAlign: 'center',
           // margin: '1cm',
            marginTop: '2mm',

    
            border: '1pt solid black',
    
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-around',

            fontSize: '10pt',
        },

        footerBarGreen: {
            width: '33%',
            height: '2mm',
           
            position: 'absolute',
            bottom: '1cm',
            left: '72%', 
            right: '0',
            //textAlign: 'center',
           // margin: '1cm',

           backgroundColor: '#006A4D'
        },

        footerBarRed: {
            width: '65%',
            height: '2mm',
           
            position: 'absolute',
            bottom: '1cm',
            left: '1cm', 
            right: '0',
            //textAlign: 'center',
           // margin: '1cm',

           backgroundColor: '#B5152A'
        },


    
        cumulative: {
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'flex-start',
            flexWrap: 'wrap',
            //flexGrow: '1',
    
            //paddingRight: '8pt',
        },
    
        exclusive: {
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'flex-start',
            flexWrap: 'nowrap',
            //flexGrow: '1',
    
            //paddingLeft: '9pt',

            
        }


    }
)
import Helmet from "react-helmet";
import React, {useEffect, useState} from "react"
import {fetchArchivedProjects, restoreProject} from "../typescript/backend/BackendAPI";
import {Button, Container, Paper, styled} from "@mui/material";
import {DataGrid, GridColDef, gridStringOrNumberComparator} from "@mui/x-data-grid";
import {LabelDisplayedRowsArgs} from "@mui/material/TablePagination/TablePagination";
import Project from "../typescript/objects/Project";
import RestorePageIcon from '@mui/icons-material/RestorePage';

const ArchivesView = () => {
    const [projects, setProjects] = useState<Array<Project>>([])
    const [reload, setReload] = useState(true)

    useEffect(() => {
        if (reload) {
            fetchArchivedProjects()().then(response => {
                setProjects((response.data || []).map(project => new Project(project)))
                setReload(false)
            })
        }
    }, [reload])

    const restore = (project: Project) => {
        restoreProject(project)().then(response => setReload(true))
    }

    const columns: GridColDef[] = [
        {
            field: 'customer',
            headerName: 'Kunde',
            flex: 3,
            headerAlign: "center",
            renderCell: (cell) => `${cell.value.surname}, ${cell.value.name}`,
            sortComparator: (v1, v2, cell1, cell2) => gridStringOrNumberComparator(v1.surname, v2.surname, cell1, cell2)
        },
        {
            field: 'year',
            headerName: 'Baujahr',
            flex: 1,
            headerAlign: "center",
        },
        {
            field: 'city',
            headerName: 'Bauort',
            flex: 2,
            headerAlign: "center",
        },
        {
            field: 'name',
            headerName: 'Bezeichnung',
            flex: 3,
            headerAlign: "center",
        },
        {
            field: 'manager',
            headerName: 'Bauleiter',
            flex: 2,
            headerAlign: "center",
            renderCell: (cell) => `${cell.value.surname}, ${cell.value.name}`,
            sortComparator: (v1, v2, cell1, cell2) => gridStringOrNumberComparator(v1.surname, v2.surname, cell1, cell2)
        },
        {
            field: 'download',
            headerName: 'Wiederherstellen',
            flex: 1,
            sortable: false,
            align: "center",
            headerAlign: "center",
            renderCell: (cell) => (
                <Button variant="contained"
                        color="primary"
                        onClick={() => restore(cell.row)}
                >
                    <RestorePageIcon/>
                </Button>
            )
        }
    ];

    const StyledDataGrid = styled(DataGrid)(({theme}) => ({
        [`.odd`]: {
            backgroundColor: "rgba(255,255,255, 0.35)"
        },
        [`.even`]: {
            backgroundColor: "rgba(211,211,211, 0.35)"
        }
    }))

    return (
        <>
            <Helmet
                title="Archiv - Heimerl 4.0"
                meta={[{name: "description", content: "Archivierte Bauvorhaben"}]}
            />

            <Container maxWidth="xl">
                <Paper elevation={4} style={{height: "80vh", backgroundColor: "rgba(255,255,255,0.85)"}}>
                    <StyledDataGrid
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableSelectionOnClick
                        hideFooterSelectedRowCount
                        columns={columns}
                        rows={projects}
                        paginationMode="client"
                        rowsPerPageOptions={[25, 50, 100]}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        initialState={{
                            sorting: {
                                sortModel: [{field: "customer", sort: "asc"}]
                            }
                        }}
                        localeText={{
                            MuiTablePagination: {
                                labelRowsPerPage: "Zeilen pro Seite",
                                labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) => `${paginationInfo.from} - ${paginationInfo.to} von ${paginationInfo.count}, Seite ${paginationInfo.page + 1}`
                            },
                        }}
                    />
                </Paper>
            </Container>
        </>
    )
}

export default ArchivesView
import {Reducer} from "redux"
import * as BackendAPI from "../../typescript/backend/BackendAPI"
import {all, call, Effect, fork, put, select, takeEvery} from "redux-saga/effects"
import {AxiosResponse} from "axios"
import MasterdataTreeNode from '../../typescript/objects/MasterdataTreeNode'
import {ApplicationState} from "../store"
import * as TreeUtils from "../../typescript/utils/TreeUtils"
import {Behaviours} from "../../typescript/constants/Behaviours";
import {Flags} from "../../typescript/constants/Flags";

const initialState: MasterdataState = {
    tree: [],
    expandedNodes: [],
    busy: false
}

export const reducer: Reducer<MasterdataState> = (state: MasterdataState = initialState, action: MasterdataAction) => {
    switch (action.type) {
        case MasterdataActionTypes.FETCH_MASTERDATA_START:
        case MasterdataActionTypes.EDIT_MASTERDATA_START:
            return { ...state, busy: true }

        case MasterdataActionTypes.FETCH_MASTERDATA_FAILURE:
        case MasterdataActionTypes.EDIT_MASTERDATA_FAILURE:
            return { ...state, busy: false, error: action.error }

        case MasterdataActionTypes.FETCH_MASTERDATA_SUCCESS:
        case MasterdataActionTypes.EDIT_MASTERDATA_SUCCESS:
            return { ...state, busy: false, error: undefined, tree: action.tree }

        case MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_EXPANSION:
            return { ...state, expandedNodes: state.expandedNodes.indexOf(action.node.id) !== -1 ? state.expandedNodes.filter(id => id !== action.node.id) : [...state.expandedNodes, action.node.id] }

        case MasterdataActionTypes.REPLACE_MASTERDATA_TREE:
            return { ...state, tree: [action.tree] }

        default:
            return state
    }
}

export interface MasterdataState {
    readonly tree: Array<MasterdataTreeNode>,
    readonly expandedNodes: Array<string>
    readonly busy: boolean,
    readonly error?: string
}

export enum MasterdataActionTypes {
    FETCH_MASTERDATA_REQUEST = "@masterdata/fetch_masterdata/request",
    FETCH_MASTERDATA_START = "@masterdata/fetch_masterdata/start",
    FETCH_MASTERDATA_SUCCESS = "@masterdata/fetch_masterdata/success",
    FETCH_MASTERDATA_FAILURE = "@masterdata/fetch_masterdata/failure",

    EDIT_MASTERDATA_REQUEST = "@masterdata/edit_masterdata/request",
    EDIT_MASTERDATA_START = "@masterdata/edit_masterdata/start",
    EDIT_MASTERDATA_SUCCESS = "@masterdata/edit_masterdata/success",
    EDIT_MASTERDATA_FAILURE = "@masterdata/edit_masterdata/failure",

    TOGGLE_MASTERDATA_NODE_EXPANSION = "@masterdata/masterdata_node_expansion/toggle",

    TOGGLE_MASTERDATA_NODE_CATEGORY = "@masterdata/masterdata_node_category/toggle",

    TOGGLE_MASTERDATA_NODE_COMMENT_REQUIRED = "@masterdata/masterdata_node_comment_required/toggle",

    TOGGLE_MASTERDATA_NODE_DISABLED = "@masterdata/masterdata_node_disabled/toggle",

    CREATE_MASTERDATA_NODE = "@masterdata/create_masterdata_node",

    CLONE_MASTERDATA_NODE = "@masterdata/clone_masterdata_node",

    EDIT_MASTERDATA_NODE = "@masterdata/edit_masterdata_node",

    DELETE_MASTERDATA_NODE = "@masterdata/delete_masterdata_node",

    SWITCH_MASTERDATA_NODE_BEHAVIOUR = "@masterdata/switch_masterdata_node_behaviour",

    MASTERDATA_NODE_INDEX_UP = "@masterdata/node_index_up",

    MASTERDATA_NODE_INDEX_DOWN = "@masterdata/node_index_down",

    REPLACE_MASTERDATA_TREE = "@masterdata/internal/replace_tree",

    SWITCH_MASTERDATA_NODES = "@masterdata/switch_nodes"
}

export type MasterdataFetchRequestAction = { type: MasterdataActionTypes.FETCH_MASTERDATA_REQUEST }
export type MasterdataFetchStartAction = { type: MasterdataActionTypes.FETCH_MASTERDATA_START }
export type MasterdataFetchSuccessAction = { type: MasterdataActionTypes.FETCH_MASTERDATA_SUCCESS, tree: Array<MasterdataTreeNode> }
export type MasterdataFetchFailureAction = { type: MasterdataActionTypes.FETCH_MASTERDATA_FAILURE, error: string }

export type MasterdataEditingRequestAction = { type: MasterdataActionTypes.EDIT_MASTERDATA_REQUEST }
export type MasterdataEditingStartAction = { type: MasterdataActionTypes.EDIT_MASTERDATA_START }
export type MasterdataEditingSuccessAction = { type: MasterdataActionTypes.EDIT_MASTERDATA_SUCCESS, tree: Array<MasterdataTreeNode> }
export type MasterdataEditingFailureAction = { type: MasterdataActionTypes.EDIT_MASTERDATA_FAILURE, error: string }

export type MasterdataNodeExpansionToggleAction = { type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_EXPANSION, node: MasterdataTreeNode }

export type MasterdataNodeCategoryToggleAction = { type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_CATEGORY, node: MasterdataTreeNode }

export type MasterdataNodeCommentRequiredToggleAction = { type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_COMMENT_REQUIRED, node: MasterdataTreeNode }

export type MasterdataNodeDisabledToggleAction = { type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_DISABLED, node: MasterdataTreeNode }

export type MasterdataNodeCreationAction = { type: MasterdataActionTypes.CREATE_MASTERDATA_NODE, node: MasterdataTreeNode, parent: MasterdataTreeNode }

export type MasterdataNodeCloningAction = { type: MasterdataActionTypes.CLONE_MASTERDATA_NODE, source: MasterdataTreeNode, clone: MasterdataTreeNode }

export type MasterdataNodeEditingAction = { type: MasterdataActionTypes.EDIT_MASTERDATA_NODE, node: MasterdataTreeNode }

export type MasterdataNodeDeletionAction = { type: MasterdataActionTypes.DELETE_MASTERDATA_NODE, node: MasterdataTreeNode }

export type MasterdataNodeSwitchBehaviourAction = { type: MasterdataActionTypes.SWITCH_MASTERDATA_NODE_BEHAVIOUR, node: MasterdataTreeNode }

export type MasterdataReplaceTreeInternalAction = { type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree: MasterdataTreeNode }

export type MasterdataSwitchNodesAction = { type: MasterdataActionTypes.SWITCH_MASTERDATA_NODES, from: string, to: string }

export type MasterdataNodeIndexUpAction = { type: MasterdataActionTypes.MASTERDATA_NODE_INDEX_UP, node: MasterdataTreeNode }

export type MasterdataNodeIndexUpDownAction = { type: MasterdataActionTypes.MASTERDATA_NODE_INDEX_DOWN, node: MasterdataTreeNode }

export type MasterdataAction =
    MasterdataFetchRequestAction | MasterdataFetchStartAction | MasterdataFetchSuccessAction | MasterdataFetchFailureAction |
    MasterdataEditingRequestAction | MasterdataEditingStartAction | MasterdataEditingSuccessAction | MasterdataEditingFailureAction |
    MasterdataNodeExpansionToggleAction | MasterdataNodeCategoryToggleAction | MasterdataNodeCommentRequiredToggleAction |
    MasterdataNodeCreationAction | MasterdataNodeEditingAction | MasterdataNodeDeletionAction | MasterdataNodeSwitchBehaviourAction |
    MasterdataReplaceTreeInternalAction | MasterdataNodeCloningAction | MasterdataSwitchNodesAction | MasterdataNodeIndexUpAction |
    MasterdataNodeIndexUpDownAction

/* Sagas */

// Masterdata Fetch
function* masterdataFetchSaga() {
    yield takeEvery(MasterdataActionTypes.FETCH_MASTERDATA_REQUEST, function* (action: MasterdataFetchRequestAction) {
        yield put({ type: MasterdataActionTypes.FETCH_MASTERDATA_START })

        try {
            const result: AxiosResponse = yield call(BackendAPI.fetchMasterdata())
            const tree: Array<MasterdataTreeNode> = (result.data as Array<any>).map(rawNode => MasterdataTreeNode.valueOf(rawNode))

            yield put({ type: MasterdataActionTypes.FETCH_MASTERDATA_SUCCESS, tree })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)

            yield put({ type: MasterdataActionTypes.FETCH_MASTERDATA_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Masterdata Editing
function* masterdataEditingSaga() {
    yield takeEvery(MasterdataActionTypes.EDIT_MASTERDATA_REQUEST, function* (action: MasterdataEditingRequestAction) {
        yield put({ type: MasterdataActionTypes.EDIT_MASTERDATA_START })

        const treeTemplate: Array<MasterdataTreeNode> = yield select((state: ApplicationState) => state.masterdata.tree)

        try {
            const result: AxiosResponse = yield call(BackendAPI.pushMasterdata(treeTemplate))
            const tree: Array<MasterdataTreeNode> = (result.data as Array<any>).map(rawNode => MasterdataTreeNode.valueOf(rawNode))

            yield put({ type: MasterdataActionTypes.EDIT_MASTERDATA_SUCCESS, tree })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)

            yield put({ type: MasterdataActionTypes.EDIT_MASTERDATA_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Node Expansion Nesting
function* nodeExpansionSaga() {
    yield takeEvery(MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_EXPANSION, function* (action: MasterdataNodeExpansionToggleAction) {
        const node: MasterdataTreeNode = action.node
        const expandedNodes: Array<string> = yield select((state: ApplicationState) => state.masterdata.expandedNodes)

        // Toggle every node that is below this one - this will automatically recurse down the whole tree (if necessary)
        const effects: Array<Effect> = node.children
            .filter(child => expandedNodes.indexOf(child.id) !== -1)
            .map(child => put({ type: MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_EXPANSION, node: child }))

        // Trigger the actions concurrently
        yield all(effects)
    })
}

// Node Toggle Category
function* nodeToggleCategorySaga() {
    yield takeEvery(MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_CATEGORY, function* (action: MasterdataNodeCategoryToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Toggle the value as required by the action
        if (node.isNautical()) {
            node.flags = node.flags.filter(flag => flag !== Flags.NAUTICAL)
        } else node.flags = [...node.flags, Flags.NAUTICAL]

        // Nautical nodes are called categories
        const category: boolean = node.isNautical()

        if (category) { // If a node is nautical, all its parents must be nautical, too
            TreeUtils.walkUpFrom(tree, node, (child, parent) => {
                child.flags = [...child.flags, Flags.NAUTICAL]

                tree.getSiblingsOf(child as MasterdataTreeNode).forEach(sibling => {
                    sibling.flags = [...sibling.flags, Flags.NAUTICAL]
                })
            })
        } else { // If a node is no longer nautical, none of its children can be nautical either
            TreeUtils.walkDownFully(node, (child) => {
                child.flags = child.flags.filter(flag => flag !== Flags.NAUTICAL)

                tree.getSiblingsOf(child as MasterdataTreeNode).forEach(sibling => {
                    sibling.flags = sibling.flags.filter(flag => flag !== Flags.NAUTICAL)
                })
            })
        }

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Switch Behaviour
function* nodeSwitchBehaviourSaga() {
    yield takeEvery(MasterdataActionTypes.SWITCH_MASTERDATA_NODE_BEHAVIOUR, function* (action: MasterdataNodeSwitchBehaviourAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Switch the behaviour of the children
        node.children.forEach(child => {
            child.behaviour = child.behaviour === Behaviours.EXCLUSIVE ? Behaviours.CUMULATIVE : Behaviours.EXCLUSIVE
        })

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Toggle CommentRequired
function* nodeToggleCommentRequiredSaga() {
    yield takeEvery(MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_COMMENT_REQUIRED, function* (action: MasterdataNodeCommentRequiredToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Toggle the value
        if (node.isCommentRequired()) {
            node.flags = node.flags.filter(flag => flag !== Flags.COMMENT_REQUIRED)
        } else node.flags = [...node.flags, Flags.COMMENT_REQUIRED]

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Toggle Flag DISABLED
function* nodeToggleDisabled() {
    yield takeEvery(MasterdataActionTypes.TOGGLE_MASTERDATA_NODE_DISABLED, function* (action: MasterdataNodeDisabledToggleAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        // Toggle the value
        if (node.isDisabled()) {
            node.flags = node.flags.filter(flag => flag !== Flags.DISABLED)
        } else node.flags = [...node.flags, Flags.DISABLED]

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Deletion
function* nodeDeletionSaga() {
    yield takeEvery(MasterdataActionTypes.DELETE_MASTERDATA_NODE, function* (action: MasterdataNodeDeletionAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        const parent = tree.getParentOf(node)

        if (!parent) return

        // Rearrange the children of the parent and exclude this node from it
        parent.children = parent.children.filter(child => !child.equals(node))

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Creation
function* nodeCreationSaga() {
    yield takeEvery(MasterdataActionTypes.CREATE_MASTERDATA_NODE, function* (action: MasterdataNodeCreationAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)

        const node = action.node
        const parent = tree.getReferenceOf(action.parent.id)

        if (!parent) return

        // Add the new children
        parent.children.push(node)

        // Fix the new nodes behaviour to match its siblings, if necessary
        if (parent.children.length > 1) {
            const behaviour = parent.getSiblingsOf(node)[0].behaviour
            node.behaviour = behaviour
        }

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Cloning
function* nodeCloningSaga() {
    yield takeEvery(MasterdataActionTypes.CLONE_MASTERDATA_NODE, function* (action: MasterdataNodeCloningAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)

        const clone = action.clone
        const source = tree.getReferenceOf(action.source.id)

        if (!source) return

        const sourceParent = tree.getParentOf(source)

        if (!sourceParent) {
            return
        }

        // Insert the cloned element right after the original one
        const sourceIndex = sourceParent.children.indexOf(source)
        const size = sourceParent.children.length
        const modifiedChildren = []

        for (let index = 0; index < size; index++) {
            modifiedChildren.push(sourceParent.children[index])

            if (index !== sourceIndex) {
                continue
            }

            modifiedChildren.push(clone)
        }

        sourceParent.children = modifiedChildren

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Editing
function* nodeEditingSaga() {
    yield takeEvery(MasterdataActionTypes.EDIT_MASTERDATA_NODE, function* (action: MasterdataNodeEditingAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)
        const node = tree.getReferenceOf(action.node.id)

        if (!node) return

        const parent = tree.getParentOf(node)

        if (!parent) return

        // Rearrange the children of the parent and replace the node
        parent.children = [...parent.children.map(child => child.equals(node) ? action.node : child)]

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Switchning (drag-and-drop)
function* nodeSwitchingSaga() {
    yield takeEvery(MasterdataActionTypes.SWITCH_MASTERDATA_NODES, function* (action: MasterdataSwitchNodesAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)

        const node = tree.getReferenceOf(action.from)
        if (!node) return

        const target = tree.getReferenceOf(action.to)
        if (!target) return

        const nodeParent = tree.getParentOf(node)
        if (!nodeParent) return

        // Dragging away organisational nodes is forbidden (being a target is okay though)
        if (node.isOrganisational()) return

        // Dropping on the same node probably means the user just aborted his drag-and-drop activity
        if (target.equals(node)) return

        // Dragging the node inside its current parent makes no difference and should be ignored
        if (target.equals(nodeParent)) return

        // If you want to move a node into its own child, their positions needs to be switched and their children transfered
        if (node.containsNode(target)) {
            // This code essentially works - but it needs additional edits to the target-node behaviour, nautical flags, etc.
            // And currently its not sure how much we need THIS special case to work - since its a kinda strange move anyways.

            // const targetParent = tree.getParentOf(target)
            // if (!targetParent) {
            //     alert("parent of " + target.title + " doesnt exist")
            //     return
            // }

            // TreeUtils.walkDownUntil(node, target, (childNode) => {
            //     let child = childNode as MasterdataTreeNode
            //     const parent = tree.getParentOf(child)

            //     if (!parent) {
            //         alert("madness! @" + node.title)
            //         return
            //     }

            //     child.children = child.children.filter(grandChild => !grandChild.equals(target))
            // })

            // nodeParent.children = [target, ...nodeParent.children]

            alert("Diese Verschiebung ist aktuell nicht zulässig.")
            return
        }

        // Remove the node from its former parent
        nodeParent.children = nodeParent.children.filter(child => !child.equals(node))

        if (target.hasChildren()) {
            // Ensure the behaviour matches with other children
            node.behaviour = target.children[0].behaviour

            // If the other children are nautical, the dragged node should be too
            if (target.children[0].isNautical()) {
                node.flags = [...new Set([...node.flags, Flags.NAUTICAL])]
            }

            // If the other children are not nautical, but the node currently is, it should become a detail-element
            if (!target.children[0].isNautical() && node.isNautical()) {
                node.flags = node.flags.filter(flag => flag !== Flags.NAUTICAL)
            }
        }

        // Remove the targets comment-required flag if you add children to it
        if (target.isCommentRequired()) {
            target.flags = target.flags.filter(flag => flag != Flags.COMMENT_REQUIRED)
        }

        // Add the node to its new parent
        target.children = [node, ...target.children]

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Index-Up
function* nodeIndexUpSaga() {
    yield takeEvery(MasterdataActionTypes.MASTERDATA_NODE_INDEX_UP, function* (action: MasterdataNodeIndexUpAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)

        const node = tree.getReferenceOf(action.node.id)
        if (!node) return

        const parent = tree.getParentOf(node)
        if (!parent) return

        const currentIndex = parent.children.indexOf(node)
        let newIndex = currentIndex === 0 ? parent.children.length - 1 : Math.max(0, currentIndex - 1)

        const swappedNode = parent.children[newIndex]

        parent.children[newIndex] = node
        parent.children[currentIndex] = swappedNode

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Node Index-Down
function* nodeIndexDownSaga() {
    yield takeEvery(MasterdataActionTypes.MASTERDATA_NODE_INDEX_DOWN, function* (action: MasterdataNodeIndexUpDownAction) {
        const originalTree = yield select((state: ApplicationState) => state.masterdata.tree[0])

        // We have to copy the tree before manipulting it, to prevent the state from being mutated
        const tree = MasterdataTreeNode.valueOf(originalTree)

        const node = tree.getReferenceOf(action.node.id)
        if (!node) return

        const parent = tree.getParentOf(node)
        if (!parent) return

        const currentIndex = parent.children.indexOf(node)
        let newIndex = currentIndex === parent.children.length - 1 ? 0 : Math.min(parent.children.length - 1, currentIndex + 1)

        const swappedNode = parent.children[newIndex]

        parent.children[newIndex] = node
        parent.children[currentIndex] = swappedNode

        // Send the state update!
        yield put({ type: MasterdataActionTypes.REPLACE_MASTERDATA_TREE, tree })
    })
}

// Export a combined Saga
export function* saga() {
    yield all([
        fork(masterdataFetchSaga),
        fork(masterdataEditingSaga),
        fork(nodeExpansionSaga),
        fork(nodeToggleCategorySaga),
        fork(nodeSwitchBehaviourSaga),
        fork(nodeToggleCommentRequiredSaga),
        fork(nodeToggleDisabled),
        fork(nodeDeletionSaga),
        fork(nodeCreationSaga),
        fork(nodeEditingSaga),
        fork(nodeCloningSaga),
        fork(nodeSwitchingSaga),
        fork(nodeIndexUpSaga),
        fork(nodeIndexDownSaga)
    ])
}
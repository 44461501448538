import React, {Component} from 'react'
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap'
import {Checkbox, Form, FormValues} from 'informed'
import {connect} from 'react-redux'
import {Dispatch} from 'redux'
import {SamplingActionTypes} from '../../../state/ducks/sampling.duck'
import SamplingTreeNode from '../../../typescript/objects/SamplingTreeNode'
import FormGroup from 'reactstrap/lib/FormGroup'
import Icon from '../../utils/Icon'
import ListGroup from 'reactstrap/lib/ListGroup';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import {Flags} from "../../../typescript/constants/Flags";
import Project from "../../../typescript/objects/Project"
import {PDFDownloadLink} from "@react-pdf/renderer"
import SamplingProtocol from "../protocol/SamplingProtocol"
import {formatDateBackwards} from "../../../typescript/utils/FormatUtil";
import Typography from "@mui/material/Typography"

interface State {
    bSelectionFinished: boolean
 }

interface ReduxActions{
    loadSampling(): void
}

interface ComponentProps { }

interface PortalProps {
    tempTree: Array<SamplingTreeNode>
    readonly project: Project
    onClose(): void
}

type Props = ComponentProps & PortalProps & ReduxActions

class SamplingPartProtocolModal extends Component<Props, State> {

    constructor(props: any, context: any) {
        super(props, context);

        this.state = {
           bSelectionFinished: false
        };
     };

    setIncludedStatus = (values: FormValues) => {
        this.props.tempTree[0].children.forEach( node =>
            (
                values[node.id] ?
                  (  node.flags = node.flags.filter( flag => flag !== Flags.EXCLUDED_FROM_PROTOCOL )  )
                    : node.flags.push(Flags.EXCLUDED_FROM_PROTOCOL)
            )
        )

    }

    buildDocument = () => {
        return <SamplingProtocol project={this.props.project} tree={this.props.tempTree} date={new Date()} partial />
    }

    handleSubmit = (values: FormValues) => {
        this.setIncludedStatus(values) //apply
        this.setState({ bSelectionFinished: true})

    }

    handleClose = () => {
        this.props.onClose()
        this.props.loadSampling() //restore
    }


    render = () => (

        <Modal isOpen size="lg" fade={true}>
            <Form onSubmit={this.handleSubmit}>
            <div className="sampling-bar" style={{paddingTop: '15px'}}>
                <Typography variant="h6">Teilprotokoll</Typography>
                <div style={{float: 'right'}}>
                { !this.state.bSelectionFinished &&
                <Button color="primary" type="submit">
                    Auswahl bestätigen
                </Button>
                }
                { this.state.bSelectionFinished &&
                <PDFDownloadLink document={this.buildDocument()} fileName={`Teilprotokoll ${this.props.project.customer.surname} ${this.props.project.customer.name} ${formatDateBackwards(new Date())}.pdf`}>
                        {({ blob, url, loading, error }: { blob: any, url: string, loading: boolean, error?: string }) => (
                            <span >
                                {loading && <span><Icon type="fas" icon="spinner" spin />   </span>}
                                {!loading && <span><Icon type="fas" icon="arrow-down" />&nbsp;&nbsp;Herunterladen</span>}
                            </span>
                        )}
                </PDFDownloadLink>
                }
                &nbsp;&nbsp;
                <Button color="danger" onClick={this.handleClose}>Schließen</Button>
                </div>
            </div>

            <ModalBody>


                    <FormGroup>
                        <ListGroup id='auswahl'>

                            {this.props.tempTree[0].children.map( (node: SamplingTreeNode) =>
                                (
                                <ListGroupItem>
                                    <Checkbox field={node.id} style={{height: 20, width: 20}} checked={false} disabled={this.state.bSelectionFinished}/>
                                    &nbsp;&nbsp;
                                    <span>{node.title}</span>

                                </ListGroupItem>
                                )
                            )}
                        </ListGroup>
                    </FormGroup>


            </ModalBody>
            </Form>
            <ModalFooter>

            </ModalFooter>
        </Modal>
    );
}

const mapStateToProps = () => ({

})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadSampling: () => dispatch({ type: SamplingActionTypes.FETCH_SAMPLING_REQUEST }),
})

export default connect(mapStateToProps, mapDispatchToProps)(SamplingPartProtocolModal)
import {StoreSubscriber} from './StoreSubscriber'
import {Store} from 'redux'
import {ApplicationState} from '../store'
import {push} from 'connected-react-router'

/*
    If a user is not authenticated, he will be redirected to login ASAP.
*/
class LoginRedirectionSubscriber implements StoreSubscriber {

    accept = (store: Store<ApplicationState>) => {
        const authenticated = store.getState().authentication.authenticated
        const currentPage = store.getState().router.location.pathname

        // If the user is authenticated, we don't need to redirect him to login
        if (authenticated) return

        // If the user is already on the login page, we also don't redirect him to prevent an infinite redirection cycle
        if (currentPage.endsWith("/login")) return

        // Redirect the user to the login page
        store.dispatch(push("/login"))
    }

}

// We only need StoreSubscribers once, so we export it as a Singleton instance
export default new LoginRedirectionSubscriber()

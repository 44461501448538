import {FormValue, FormValues} from "informed"

const INVALID_EMAIL = "Bitte geben Sie eine gültige Email-Adresse an."
const INVALID_USERNAME = "Bitte geben Sie einen Benutzernamen aus Kleinbuchstaben mit maximal 32 Zeichen ein."
const INVALID_PASSWORD = "Bitte geben Sie ein Passwort mit mindestens 5 Zeichen ein."
const INVALID_PHONE = "Bitte geben Sie eine gültige Telefonnummer an."
const INVALID_TEXT = "Bitte geben Sie etwas ein."
const INVALID_ZIPCODE = "Bitte geben Sie eine gültige Postleitzahl an."
const INVALID_COMMENT = "Bitte geben Sie einen gültigen Text an."
const INVALID_HOUSENUMBER = "Bitte geben Sie eine gültige Hausnummer an."
const INVALID_NUMBER = "Bitte geben Sie eine gültige Zahl für das Baujahr an."

class FormValidators {

    static validateText = (value: FormValue, values?: FormValues) => {
        const text = `${value || ""}`
        const valid = /(.{1,})/.test(text)

        return valid ? undefined : INVALID_TEXT
    }

    static validateOptionalText = (value: FormValue, values?: FormValues) => {
        const text = `${value || ""}`
        const valid = text.length === 0 || /(.{1,})/.test(text)

        return valid ? undefined : INVALID_TEXT
    }

    static validateEmail = (value: FormValue, values?: FormValues) => {
        const email = `${value || ""}`
        const valid = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(email)

        return valid ? undefined : INVALID_EMAIL
    }

    static validateOptionalEmail = (value: FormValue, values?: FormValues) => {
        const email = `${value || ""}`
        return email.length === 0 ? undefined : FormValidators.validateEmail(value, values)
    }

    static validateUsername = (value: FormValue, values?: FormValues) => {
        const username = `${value ? value.toString() : ""}`
        const valid = /(^(([a-z]{1,32})\S)$)/.test(username)

        return valid ? undefined : INVALID_USERNAME
    }

    static validatePassword = (value: FormValue, values?: FormValues) => {
        const password = `${value || ""}`
        const valid = /(.{5,})/.test(password)

        return valid ? undefined : INVALID_PASSWORD
    }

    static validateOptionalPassword = (value: FormValue, values?: FormValues) => {
        const password = `${value || ""}`
        const valid = password.length === 0 || /(.{5,})/.test(password)

        return valid ? undefined : INVALID_PASSWORD
    }

    static validatePhone = (value: FormValue, values?: FormValues) => {
        const phone = `${value || ""}`
        const valid = /([0-9+/\\-]{5,32})/.test(phone)

        return valid ? undefined : INVALID_PHONE
    }

    static validateOptionalPhone = (value: FormValue, values?: FormValues) => {
        const phone = `${value || ""}`
        return phone.length === 0 ? undefined : FormValidators.validatePhone(value, values)
    }

    // Currently, all kinds of comments are valid
    static validateComment = (value: FormValue, values?: FormValues) => {
        const comment = `${value || ""}`
        const valid = comment.length === 0 || /(.*)/.test(comment)

        return valid ? undefined : INVALID_COMMENT
    }

    static validateZipCode = (value: FormValue, values?: FormValues) => {
        const zipCode = `${value || ""}`
        const valid = /(^([0-9])\S{4,5}$)/.test(zipCode)

        return valid ? undefined : INVALID_ZIPCODE
    }

    static validateHouseNumber = (value: FormValue, values?: FormValues) => {
        const houseNumber = `${value || ""}`
        const valid = /(([0-9]+)([^0-9]*))/.test(houseNumber)

        return valid ? undefined : INVALID_HOUSENUMBER
    }

    static validateNumber = (value: FormValue, values?: FormValues) => {
        const number = `${value || ""}`
        const valid = /(([0-9]+))/.test(number)

        return valid ? undefined : INVALID_NUMBER
    }

}

export default FormValidators
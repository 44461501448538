import React from "react"
import "./UserList.sass"
import User from "../../../typescript/objects/User"
import ListGroup from "reactstrap/lib/ListGroup"
import ListGroupItem from "reactstrap/lib/ListGroupItem"
import Badge from "reactstrap/lib/Badge"
import ListGroupItemHeading from "reactstrap/lib/ListGroupItemHeading"
import Icon from "../../utils/Icon"
import Button from "reactstrap/lib/Button"
import UserToolbar from "./UserToolbar"
import {withPortal} from "react-portalgun"
import {Dispatch} from "redux"
import {ApplicationState} from '../../../state/store'
import {Roles} from "../../../typescript/constants/Roles"
import {connect} from "react-redux"

interface ComponentProps {
    users: Array<User>
}

interface ReduxProps {
    currentUser?: User
}

interface ReduxActions { }

interface PortalProps {
    openCreator(): void
}

type Props = ComponentProps & PortalProps & ReduxProps & ReduxActions

const UserList = (props: Props) => (
    <>
        <div id="users-list">
            <ListGroup>
                <ListGroupItem className="hamburger-bun">
                    <b>Benutzer</b>
                    <h5 className="normalized float-right">
                        <Badge>
                            <Icon type="fas" icon="users" />
                            &nbsp;
                            {props.users.length}
                        </Badge>
                    </h5>
                </ListGroupItem>

                {props.users.map(user => (
                    <ListGroupItem className={`user-${user.deleted ? "disabled" : "enabled"}`} key={user.id}>
                        <ListGroupItemHeading>
                            <Icon type="fas" icon="user" />
                            &nbsp;
                            <span>{user.name} {user.surname}</span>

                            <span className="float-right">
                                <UserToolbar user={user} />
                            </span>
                        </ListGroupItemHeading>
                    </ListGroupItem>
                ))}

                <ListGroupItem className="hamburger-bun">
                    {props.currentUser && props.currentUser.hasRole(Roles.MANAGE_USERS) &&
                        <Button
                            color="primary"
                            onClick={props.openCreator}
                        >
                            <Icon type="fas" icon="user-plus" />
                            &nbsp;
                            <span>Neuer Benutzer</span>
                        </Button>
                    }
                </ListGroupItem>
            </ListGroup>
        </div>
    </>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    currentUser: state.authentication.user
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({

})

export default withPortal("CREATE_USER", "openCreator")(
    connect(mapStateToProps, mapDispatchToProps)(UserList)
)
import {Behaviours} from "../constants/Behaviours"
import {v4} from "node-uuid"
import {walkDownUntil} from "../utils/TreeUtils";
import {Flags} from "../constants/Flags";

class MasterdataTreeNode {

    id: string = v4()
    title: string = "Neues Element"
    behaviour: Behaviours = Behaviours.EXCLUSIVE
    flags: Array<Flags> = []
    pictures: Array<string> = []
    children: Array<MasterdataTreeNode> = []

    hasFlag = (flag: Flags): boolean => this.flags.indexOf(flag) !== -1

    isNautical = (): boolean => this.flags.indexOf(Flags.NAUTICAL) !== -1

    isCommentRequired = (): boolean => this.flags.indexOf(Flags.COMMENT_REQUIRED) !== -1

    isOrganisational = (): boolean => this.flags.indexOf(Flags.ORGANISATIONAL) !== -1

    isDisabled = (): boolean => this.flags.indexOf(Flags.DISABLED) !== -1

    hasChildren = (): boolean => this.children && this.children.length > 0

    isLeaf = (): boolean => !this.hasChildren()

    hasGrandChildren = (): boolean => this.hasChildren() && this.children.filter(child => child.hasChildren()).length > 0

    equals = (node: MasterdataTreeNode) => this.id === node.id

    getReferenceOf = (id: string): MasterdataTreeNode | undefined => {
        if (!this.containsNode(MasterdataTreeNode.valueOf({id}))) return undefined
        var found = undefined

        walkDownUntil(this, MasterdataTreeNode.valueOf({id}), (node) => {
            if (node.id !== id) return
            found = node
        })

        return found
    }

    getSiblingsOf = (node: MasterdataTreeNode): Array<MasterdataTreeNode> => {
        const parent = this.getParentOf(node)
        if (!parent) return []

        return parent.children.filter(child => !child.equals(node))
    }

    getParentOf = (node: MasterdataTreeNode): MasterdataTreeNode | undefined => {
        if (!this.containsNode(node)) return undefined
        var found = undefined

        walkDownUntil(this, node, (child) => {
            const ref = (child as MasterdataTreeNode).getReferenceOf(node.id)
            if (!ref) return

            if ((child.children as Array<MasterdataTreeNode>).indexOf(ref) !== -1) {
                found = child
            }
        })

        return found
    }

    containsNode = (node: MasterdataTreeNode): boolean => {
        if (this.equals(node)) return true
        if (!this.hasChildren()) return false

        for (let child of this.children) {
            if (child.containsNode(node)) return true
        }

        return false
    }

    static valueOf = (input: any): MasterdataTreeNode => {
        const node = new MasterdataTreeNode()

        node.title = input.title
        node.behaviour = input.behaviour

        if (input.id) node.id = input.id
        if (input.flags) node.flags = input.flags
        if (input.pictures) node.pictures = input.pictures
        if (input.children) node.children = (input.children as Array<any>).map(rawChild => MasterdataTreeNode.valueOf(rawChild))

        return node
    }

    static copy = (source: MasterdataTreeNode): MasterdataTreeNode => {
        const copy = new MasterdataTreeNode()

        copy.title = source.title
        copy.behaviour = source.behaviour

        copy.flags = [...source.flags]
        copy.pictures = [...source.pictures]
        copy.children = source.children.map(MasterdataTreeNode.copy)

        return copy
    }

}

export default MasterdataTreeNode
import React from "react"

import ReactTable from 'react-table'

import "./ChronicleEntryTable.sass"
import ChronicleEntry from "../../../typescript/objects/ChronicleEntry"

const columns = [
    {
        Header: "Beschreibung",
        accessor: "message",
        className: "centered"
    },
    {
        Header: "Benutzer",
        accessor: "source",
        className: "centered"
    },
    {
        Header: "Gegenstand",
        accessor: "object",
        className: "centered"
    },
    {
        id: "date",
        Header: "Datum",
        accessor: (entry: ChronicleEntry) => `${entry.createdAt.toLocaleString()}`,
        className: "centered"
    }
]

interface Props {
    entries: Array<ChronicleEntry>
}

const ChronicleEntryTable = (props: Props) => (
    <ReactTable
        columns={columns}
        data={props.entries}
        sortable
        defaultPageSize={100}
        pageSizeOptions={[50, 100, 250, 500, 1000]}
        noDataText="Keine Chronikeinträge gefunden!"
        pageText="Seite"
        ofText="von"
        previousText="Vorherige"
        nextText="Nächste"
        rowsText="Zeilen"
        className="-striped -highlight chronicle-entry-table"/>
)

export default ChronicleEntryTable
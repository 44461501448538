import React, {useEffect, useState} from "react"
import Helmet from "react-helmet";
import {Box, Button, Container, ImageList, ImageListItem, ImageListItemBar, Paper, Stack} from "@mui/material";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Image from "../typescript/objects/Image";
import {createImage, deleteImage, getAllImages} from "../typescript/backend/BackendAPI";


const ImagesView = () => {
    const [reload, setReload] = useState(true)
    const [images, setImages] = useState<Array<Image>>([])

    useEffect(() => {
        if (reload) {
            getAllImages()().then(response => {
                setImages(response.data)
                setReload(false)
            })
        }
    }, [reload])

    const uploadImage = (file: File, name: string) => {
        const reader = new FileReader()

        reader.onload = () => {
            const base64 = reader.result.toString()
            createImage({name: name, data: base64})().then(response => setReload(true))
        }

        reader.readAsDataURL(file)
    }

    const removeImage = (image: Image) => {
        deleteImage(image.id)().then(response => setReload(true))
    }

    return (
        <Container maxWidth="lg" style={{marginBottom: "50px"}}>
            <Helmet
                title="Bilder - Heimerl 4.0"
                meta={[{name: "description", content: "Systembilder"}]}
            />

            <Stack spacing={2} direction="column">
                <Paper elevation={4}>
                    <Box padding="20px">
                        <span>
                            <input
                                hidden
                                type="file"
                                accept="image/*"
                                id="upload-image-button"
                                onChange={event => uploadImage(event.target.files[0], event.target.files[0].name)}
                            />

                            <label htmlFor="upload-image-button">
                                <Button
                                    variant="contained"
                                    component="span"
                                    startIcon={<AddPhotoAlternateIcon/>}
                                >
                                    Bild hochladen
                                </Button>
                            </label>
                        </span>
                    </Box>
                </Paper>

                <Paper elevation={4}>
                    <Box padding="20px">
                        <ImageList cols={4} style={{ alignItems: "flex-end"}}>
                            {images.map((image) => (
                                <ImageListItem key={image.id}>
                                    <img src={image.data} alt={image.name}/>

                                    <ImageListItemBar
                                        title={image.name}
                                        actionIcon={
                                            <Button
                                                size="small"
                                                variant="text"
                                                color="error"
                                            >
                                                <DeleteForeverIcon/>
                                            </Button>
                                        }
                                        onClick={() => removeImage(image)}
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </Paper>
            </Stack>
        </Container>
    )
}

export default ImagesView
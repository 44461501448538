import React, {FormEvent} from "react"
import "./Searchbar.sass"
import {Button, Input, InputGroup} from "reactstrap"
import InputGroupAddon from "reactstrap/lib/InputGroupAddon";
import Icon from "../utils/Icon";
import {ApplicationState} from "../../state/store";
import {Dispatch} from "redux";
import {connect} from "react-redux";
import {SearchActionTypes} from "../../state/ducks/search.duck";

interface ComponentProps { }

interface ReduxProps { }

interface ReduxActions {
    setText(text: string): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

const Searchbar = (props: Props) => (
    <InputGroup id="search">
        <Input
            type="text"
            placeholder="Suchen..."
            id="search-bar"
            onChange={(event: FormEvent<HTMLInputElement>) => props.setText(event.currentTarget.value)}
        />

        <InputGroupAddon addonType="append">
            <Button id="search-button">
                <Icon type="fas" icon="search" />
            </Button>
        </InputGroupAddon>
    </InputGroup>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({

})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    setText: (text: string) => dispatch({ type: SearchActionTypes.SET_TEXT, text })
})

export default connect(mapStateToProps, mapDispatchToProps)(Searchbar)
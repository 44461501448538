import React from "react"
import {ApplicationState} from "../../../state/store"
import {Dispatch} from "redux"
import {connect} from "react-redux"
import {SamplingActionTypes} from "../../../state/ducks/sampling.duck"
import "./sampling.sass"
import Button from "reactstrap/lib/Button"
import Icon from "../../utils/Icon"
import SamplingLegend from "./SamplingLegend"
import ButtonGroup from "reactstrap/lib/ButtonGroup"
import SamplingTreeNode from "../../../typescript/objects/SamplingTreeNode"
import Project from "../../../typescript/objects/Project"
import {withPortal} from "react-portalgun"

interface ComponentProps {
    tree: Array<SamplingTreeNode>
    project: Project
}

interface ReduxProps {
    busy: boolean
}

interface PortalProps {
    openMenu(params: any): void
}

interface ReduxActions {
    loadSampling(): void
    saveSampling(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions & PortalProps

const SamplingBar = (props: Props) => {
    return (
        <div className="sampling-bar">
            <span>
                <ButtonGroup>
                    <Button color="primary" disabled={props.busy}
                        onClick={props.saveSampling}
                    >
                        <Icon type="fas" icon="save" />
                        &nbsp;
                        <span>Speichern</span>
                    </Button>
                </ButtonGroup>
            </span>

            <span>
                <ButtonGroup>
                    <SamplingLegend />
                    <Button color="success" disabled={props.busy}
                        onClick={props.loadSampling}
                    >
                        <Icon type="fas" icon="sync" spin={props.busy} />
                        &nbsp;
                        <span>Wiederherstellen</span>
                    </Button>
                    <Button 
                        onClick={()=>{
                            props.openMenu({tempTree: props.tree, project: props.project})
                        }}
                    >
                        <span>Teilprotokoll ausgeben</span>
                    </Button>
                    
                </ButtonGroup>
            </span>
        </div>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    busy: state.sampling.busy
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadSampling: () => dispatch({ type: SamplingActionTypes.FETCH_SAMPLING_REQUEST }),
    saveSampling: () => dispatch({ type: SamplingActionTypes.EDIT_SAMPLING_REQUEST })
})

export default withPortal("OPEN_TEILPROTOKOLL_MENU", "openMenu")( 
    connect(mapStateToProps, mapDispatchToProps)(SamplingBar) 
)
            
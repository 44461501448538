import React from "react"

import {connect} from "react-redux"
import {Dispatch} from "redux"

import "./projects.sass"
import {ApplicationState} from "../../../state/store"
import Button from "reactstrap/lib/Button"
import Icon from "../../utils/Icon";
import {ProjectsActionTypes} from "../../../state/ducks/projects.duck"
import {withPortal} from "react-portalgun"
import ButtonGroup from "reactstrap/lib/ButtonGroup";

interface ComponentProps { }

interface ReduxProps {
    busy: boolean
}

interface PortalProps {
    openCreator(): void
}

interface ReduxActions {
    loadProjects(): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

const ProjectBar = (props: Props) => (
    <div className="project-bar">
        <Button color="primary" disabled={props.busy}
            onClick={props.openCreator}
        >
            <Icon type="fas" icon="plus" />
            &nbsp;
            <span>Neues Bauvorhaben</span>
        </Button>
        <ButtonGroup>
            <Button color="success" disabled={props.busy}
                onClick={props.loadProjects}
            >
                <Icon type="fas" icon="sync" spin={props.busy} />
                &nbsp;
            <span>Aktualisieren</span>
            </Button>
        </ButtonGroup>
    </div>
)

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    busy: state.projects.busy
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadProjects: () => dispatch({ type: ProjectsActionTypes.FETCH_PROJECTS_REQUEST })
})

export default withPortal("CREATE_PROJECT", "openCreator")(
    connect(mapStateToProps, mapDispatchToProps)(ProjectBar)
)
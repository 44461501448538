import Helmet from "react-helmet";
import Backup from "../typescript/objects/Backup";
import React, {useEffect, useState} from "react"
import {downloadBackup, getAllBackups} from "../typescript/backend/BackendAPI";
import {Button, Container, Paper, styled} from "@mui/material";
import {DataGrid, GridColDef} from "@mui/x-data-grid";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {v4} from "node-uuid";
import {LabelDisplayedRowsArgs} from "@mui/material/TablePagination/TablePagination";

const BackupsView = () => {
    const [backups, setBackups] = useState<Array<Backup>>([])

    useEffect(() => {
        getAllBackups()().then(response => setBackups((response.data || []).map(backup => new Backup(backup))))
    }, [])

    const download = (backup: Backup) => {
        downloadBackup(backup.fileName)().then(response => {
            const url = window.URL.createObjectURL(new Blob([response.data]))

            const link = document.createElement("a");
            link.href = url;

            link.setAttribute(
                "download",
                `${backup.fileName}`
            );

            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);

            // Cleanup URL, prevents memory leak
            window.URL.revokeObjectURL(url)
        })
    }

    const columns: GridColDef[] = [
        {
            field: 'creationDate',
            headerName: 'Datum',
            flex: 2,
            headerAlign: "center",
            renderCell: (cell) => cell.value.toLocaleString()
        },
        {
            field: 'fileName',
            headerName: 'Name',
            flex: 4,
            headerAlign: "center",
        },
        {
            field: 'fileSize',
            headerName: 'Größe',
            flex: 1,
            headerAlign: "center",
            renderCell: cell => `${Math.ceil(cell.value / 1024 / 1024)} MB`
        },
        {
            field: 'download',
            headerName: 'Download',
            flex: 1,
            align: "center",
            sortable: false,
            headerAlign: "center",
            renderCell: (cell) => (
                <Button variant="contained"
                        color="primary"
                        onClick={() => download(cell.row)}
                >
                    <CloudDownloadIcon/>
                </Button>
            )
        }
    ];

    const StyledDataGrid = styled(DataGrid)(({theme}) => ({
        [`.odd`]: {
            backgroundColor: "rgba(255,255,255, 0.35)"
        },
        [`.even`]: {
            backgroundColor: "rgba(211,211,211, 0.35)"
        }
    }))

    return (
        <>
            <Helmet
                title="Backups - Heimerl 4.0"
                meta={[{name: "description", content: "Backup Verwaltung"}]}
            />

            <Container maxWidth="lg">
                <Paper elevation={4} style={{height: "80vh", backgroundColor: "rgba(255,255,255,0.85)"}}>
                    <StyledDataGrid
                        disableColumnFilter
                        disableColumnMenu
                        disableColumnSelector
                        disableSelectionOnClick
                        hideFooterSelectedRowCount
                        columns={columns}
                        rows={backups}
                        paginationMode="client"
                        rowsPerPageOptions={[25, 50, 100]}
                        getRowId={(backup: Backup) => v4()}
                        getRowClassName={(params) =>
                            params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
                        }
                        initialState={{
                            sorting: {
                                sortModel: [{field: "creationDate", sort: "desc"}]
                            }
                        }}
                        localeText={{
                            MuiTablePagination: {
                                labelRowsPerPage: "Zeilen pro Seite",
                                labelDisplayedRows: (paginationInfo: LabelDisplayedRowsArgs) => `${paginationInfo.from} - ${paginationInfo.to} von ${paginationInfo.count}, Seite ${paginationInfo.page + 1}`
                            },
                        }}
                    />
                </Paper>
            </Container>
        </>
    )
}

export default BackupsView
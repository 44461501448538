import React from "react"
import {render} from "react-dom"

import {Provider} from "react-redux"
import store, {persistor} from "./app/state/configureStore"

import {ConnectedRouter as Router} from "connected-react-router"
import {history} from "./app/state/store"

// Bootstrap
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle.min.js"

// Roboto Font
import "typeface-roboto"

// FontAwesome Icons
import "@fortawesome/fontawesome-free/css/all.min.css"
//import "@fortawesome/fontawesome-free/js/all.min.js" - Don"t include FAs JS, because their JS-based rendering interferes with React
// ReactTable Stylings
import "react-table/react-table.css"

// Custom Styles
import "./index.sass"

import Header from "./app/components/header/Header"
import Container from "reactstrap/lib/Container"
import Footer from "./app/components/footer/Footer"
import AppRouter from "./app/components/routes/Router"
import {PersistGate} from "redux-persist/integration/react"
import {PortalProvider} from "react-portalgun"
import MODAL_MAPPINGS from "./app/typescript/constants/ModalMappings"
import CssBaseline from "@mui/material/CssBaseline"


const App = () => (
    <>
        <CssBaseline />

        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <Router history={history}>
                    <PortalProvider portals={MODAL_MAPPINGS}>
                        <>
                            <Header />

                            <Container fluid id="page-container">
                                <AppRouter />
                            </Container>

                            <Footer />
                        </>
                    </PortalProvider>
                </Router>
            </PersistGate>
        </Provider>
    </>
)

render(<App />, document.getElementById("app"))
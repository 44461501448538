import React from "react"
import "./Header.sass"
import Navigation from "../navigation/HeaderNavigation"

const Header = () => (
    <div id="header">
        <Navigation />
    </div>
)

export default Header
import UserEditingModal from "../../components/routes/users/UserEditingModal"
import UserCreationModal from "../../components/routes/users/UserCreationModal"
import UserRolesModal from "../../components/routes/users/UserRolesModal"
import CustomerCreationModal from "../../components/routes/customers/CustomerCreationModal"
import MasterdataNodeCreationModal from "../../components/routes/masterdata/MasterdataNodeCreationModal"
import ProjectCreationModal from "../../components/routes/projects/ProjectCreationModal"
import MasterdataNodeMenuModal from "../../components/routes/masterdata/MasterdataNodeMenuModal"
import SamplingNodeMenuModal from "../../components/routes/sampling/SamplingNodeMenuModal"
import SamplingTeilprotokollModal from "../../components/routes/sampling/SamplingPartProtocolModal"

const MODAL_MAPPINGS = {
    "EDIT_USER": UserEditingModal,
    "CREATE_USER": UserCreationModal,
    "USER_ROLES": UserRolesModal,

    "CREATE_CUSTOMER": CustomerCreationModal,

    "CREATE_MASTERDATA_NODE": MasterdataNodeCreationModal,
    "OPEN_MASTERDATA_MENU": MasterdataNodeMenuModal,

    "CREATE_PROJECT": ProjectCreationModal,

    "OPEN_SAMPLING_MENU": SamplingNodeMenuModal,
    "OPEN_TEILPROTOKOLL_MENU": SamplingTeilprotokollModal
}

export default MODAL_MAPPINGS
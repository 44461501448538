import React, {Component} from "react"
import ProjectTable from "../components/routes/projects/ProjectsTable"
import {connect} from "react-redux"
import {Dispatch} from "redux"
import {ApplicationState} from "../state/store"
import Project from "../typescript/objects/Project"
import {ProjectsActionTypes} from "../state/ducks/projects.duck"
import Helmet from "react-helmet";
import ProjectBar from "../components/routes/projects/ProjectsBar";
import {UsersActionTypes} from "../state/ducks/users.duck";
import {CustomersActionTypes} from "../state/ducks/customers.duck";

interface State { }

interface ComponentProps { }

interface ReduxProps {
    projects: Array<Project>
}

interface ReduxActions {
    loadUsers(): void
    loadCustomers(): void
    loadProjects(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class ProjectsView extends Component<Props, State> {

    // (Re-)Load all projects, users and customers when the view is mounted
    componentDidMount = () => {
        this.props.loadUsers()
        this.props.loadCustomers()
        this.props.loadProjects()
    }

    render = () => (
        <>
            <Helmet
                title="Bauvorhaben - Heimerl 4.0"
                meta={[{ name: "description", content: "Bauvorhaben Verwaltung" }]}
            />

            <ProjectBar />
            <hr />

            <ProjectTable projects={this.props.projects} />
        </>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    projects: state.projects.filteredProjects
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadProjects: () => dispatch({ type: ProjectsActionTypes.FETCH_PROJECTS_REQUEST }),
    loadUsers: () => dispatch({ type: UsersActionTypes.FETCH_USERS_REQUEST }),
    loadCustomers: () => dispatch({ type: CustomersActionTypes.FETCH_CUSTOMERS_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(ProjectsView)
import {StoreSubscriber} from './StoreSubscriber'
import {Store} from 'redux'
import {ApplicationState} from '../store'
import {AuthenticationActionTypes} from '../ducks/authentication.duck';

/*
    Regularly check the token for expiry and initiate a logout if necessary
*/
class TokenExpirationSubscriber implements StoreSubscriber {

    lastCheck: number = 0

    accept = (store: Store<ApplicationState>) => {
        const authenticated = store.getState().authentication.authenticated
        const token = store.getState().authentication.token!

        // This makes no sense when the user is not authenticated
        if (!authenticated) return

        // Check the token validity all 30 seconds only
        if ((Date.now() - this.lastCheck) < 30000) return
        this.lastCheck = Date.now()

        const tokenParts = token.split(".")
        const encodedPayload = tokenParts[1]

        const payload = JSON.parse(atob(encodedPayload))

        const expiry = payload.exp

        // Check if the token is expired
        if (Date.now() >= (expiry * 1000)) {
            // Logout the user
            store.dispatch({ type: AuthenticationActionTypes.LOGOUT_REQUEST })
        }
    }

}

// We only need StoreSubscribers once, so we export it as a Singleton instance
export default new TokenExpirationSubscriber()

import React, {Component} from "react"
import UserList from "../components/routes/users/UserList";
import {ApplicationState} from "../state/store";
import {Dispatch} from "redux";
import User from "../typescript/objects/User";
import {connect} from "react-redux";
import {UsersActionTypes} from "../state/ducks/users.duck";
import Helmet from "react-helmet";
import {Roles} from "../typescript/constants/Roles";

interface State { }

interface ComponentProps {

}

interface ReduxProps {
    busy: boolean
    users: Array<User>
    user?: User
}

interface ReduxActions {
    loadUsers(): void
}

type Props = ComponentProps & ReduxProps & ReduxActions

class UsersView extends Component<Props, State> {

    // (Re-)Load all users when the view is mounted
    componentDidMount = () => this.props.loadUsers()

    render = () => (
        <>
            <Helmet
                title="Benutzer - Heimerl 4.0"
                meta={[{ name: "description", content: "Benutzer Verwaltung" }]}
            />

            {this.props.user && this.props.user.hasRole(Roles.MANAGE_USERS) && <UserList users={this.props.users} />}
            {this.props.user && !this.props.user.hasRole(Roles.MANAGE_USERS) && <UserList users={this.props.users.filter(user => user.equals(this.props.user!))} />}
        </>
    )

}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    busy: state.users.busy,
    users: state.users.users,
    user: state.authentication.user
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadUsers: () => dispatch({ type: UsersActionTypes.FETCH_USERS_REQUEST })
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersView)
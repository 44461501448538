import React, {Component} from 'react'
import {Button, ListGroup, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap"
import User from '../../../typescript/objects/User';
import {connect} from 'react-redux';
import {Dispatch} from 'redux';
import {ApplicationState} from '../../../state/store';
import {UsersActionTypes} from '../../../state/ducks/users.duck';
import ListGroupItem from 'reactstrap/lib/ListGroupItem';
import Icon from '../../utils/Icon';
import Role from '../../../typescript/objects/Role';
import {ProjectsActionTypes} from '../../../state/ducks/projects.duck';
import Project from '../../../typescript/objects/Project';
import {Roles} from '../../../typescript/constants/Roles';
import "./UserRolesModal.sass"
import Col from 'reactstrap/lib/Col';
import Row from 'reactstrap/lib/Row';

interface State { }

interface ComponentProps {
    user: User
}

interface ReduxProps {
    users: Array<User>
    roles: Array<Role>
    projects: Array<Project>
}

interface PortalProps {
    onClose(): void
}

interface ReduxActions {
    loadRoles(): void
    loadProjects(): void
    grantRole(user: User, role: Role): void
    revokeRole(user: User, role: Role): void
}

type Props = ComponentProps & ReduxProps & PortalProps & ReduxActions

class UserRolesModal extends Component<Props, State> {

    componentDidMount = () => {
        this.props.loadRoles()
        this.props.loadProjects()
    }

    // To properly render the users' assigned roles, we need the most current version of him, from the redux state
    getUser = (): User => this.props.users.find(user => user.id === this.props.user.id)!

    getProjectWriteRole = (project: Project): Role => new Role({ name: Roles.MANAGE_PROJECT_WITH_ID + project.id })

    canWriteToProject = (user: User, project: Project): boolean => user.hasRole(Roles.MANAGE_PROJECT_WITH_ID + project.id)

    render = () => (
        <Modal isOpen size="lg" id="user-roles-modal">
            <ModalHeader>
                Berechtigungen von {this.getUser().name} {this.getUser().surname}
            </ModalHeader>

            <ModalBody>
                <Row>
                    <Col>
                        <h4>Schreibzugriff auf Projekte</h4>
                        <ListGroup className="scrollable" id="project-specific-roles">
                            {this.props.projects.map(project => (
                                <ListGroupItem key={project.id} color={this.canWriteToProject(this.getUser(), project) ? "success" : "danger"}>
                                    <b>{project.name} ({project.customer.name} {project.customer.surname})</b>

                                    <Button
                                        size="sm"
                                        onClick={this.canWriteToProject(this.getUser(), project) ? () => this.props.revokeRole(this.getUser(), this.getProjectWriteRole(project)) : () => this.props.grantRole(this.getUser(), this.getProjectWriteRole(project))}
                                        color={this.canWriteToProject(this.getUser(), project) ? "success" : "danger"}
                                        className="float-right"
                                    >
                                        <Icon type="fas" icon={this.canWriteToProject(this.getUser(), project) ? "toggle-on" : "toggle-off"} />
                                    </Button>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Col>

                    <Col>
                        <h4>Übergreifende Berechtigungen</h4>
                        <ListGroup className="scrollable" id="global-roles">
                            {this.props.roles.map(role => (
                                <ListGroupItem key={role.name} color={this.getUser().hasRole(role) ? "success" : "danger"}>
                                    <b>{role.description}</b>

                                    <Button
                                        size="sm"
                                        onClick={this.getUser().hasRole(role) ? () => this.props.revokeRole(this.getUser(), role) : () => this.props.grantRole(this.getUser(), role)}
                                        color={this.getUser().hasRole(role) ? "success" : "danger"}
                                        className="float-right"
                                    >
                                        <Icon type="fas" icon={this.getUser().hasRole(role) ? "toggle-on" : "toggle-off"} />
                                    </Button>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </ModalBody>

            <ModalFooter>
                <Button color="danger" onClick={this.props.onClose}>Schließen</Button>
            </ModalFooter>
        </Modal>
    )
}

const mapStateToProps = (state: ApplicationState): ReduxProps => ({
    users: state.users.users,
    projects: state.projects.projects,
    roles: state.users.availableRoles
})

const mapDispatchToProps = (dispatch: Dispatch): ReduxActions => ({
    loadRoles: () => dispatch({ type: UsersActionTypes.FETCH_ROLES_REQUEST }),
    loadProjects: () => dispatch({ type: ProjectsActionTypes.FETCH_PROJECTS_REQUEST }),
    grantRole: (user: User, role: Role) => dispatch({ type: UsersActionTypes.GRANT_ROLE_REQUEST, user, role }),
    revokeRole: (user: User, role: Role) => dispatch({ type: UsersActionTypes.REVOKE_ROLE_REQUEST, user, role })
})

export default connect(mapStateToProps, mapDispatchToProps)(UserRolesModal)
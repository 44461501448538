import User from '../../typescript/objects/User'
import {Reducer} from "redux"
import * as BackendAPI from "../../typescript/backend/BackendAPI"
import {all, call, fork, put, takeEvery} from "redux-saga/effects"
import {AxiosResponse} from "axios"
import Role from '../../typescript/objects/Role';

const initialState: UsersState = {
    availableRoles: [],
    users: [],
    busy: false
}

export const reducer: Reducer<UsersState> = (state: UsersState = initialState, action: UsersAction) => {
    switch (action.type) {
        case UsersActionTypes.FETCH_USERS_START:
        case UsersActionTypes.CREATE_USER_START:
        case UsersActionTypes.EDIT_USER_START:
        case UsersActionTypes.DELETE_USER_START:
        case UsersActionTypes.FETCH_ROLES_START:
        case UsersActionTypes.GRANT_ROLE_START:
        case UsersActionTypes.REVOKE_ROLE_START:
            return { ...state, busy: true }

        case UsersActionTypes.FETCH_USERS_FAILURE:
        case UsersActionTypes.CREATE_USER_FAILURE:
        case UsersActionTypes.EDIT_USER_FAILURE:
        case UsersActionTypes.DELETE_USER_FAILURE:
        case UsersActionTypes.FETCH_ROLES_FAILURE:
        case UsersActionTypes.GRANT_ROLE_FAILURE:
        case UsersActionTypes.REVOKE_ROLE_FAILURE:
            return { ...state, busy: false, error: action.error }

        case UsersActionTypes.FETCH_USERS_SUCCESS:
            return { ...state, busy: false, error: undefined, users: action.users }

        case UsersActionTypes.CREATE_USER_SUCCESS:
            return { ...state, busy: false, error: undefined, users: [...state.users, action.user] }

        case UsersActionTypes.EDIT_USER_SUCCESS: 
        case UsersActionTypes.GRANT_ROLE_SUCCESS:
        case UsersActionTypes.REVOKE_ROLE_SUCCESS:
            return { ...state, busy: false, error: undefined, users: [...state.users.filter(user => user.id !== action.user.id), action.user] }

        case UsersActionTypes.DELETE_USER_SUCCESS:
            return { ...state, busy: false, error: undefined, users: state.users.filter(user => user.id !== action.user.id) }

        case UsersActionTypes.FETCH_ROLES_SUCCESS:
            return { ...state, busy: false, error: undefined, availableRoles: action.roles }

        default:
            return state
    }
}

export interface UsersState {
    readonly availableRoles: Array<Role>
    readonly users: Array<User>,
    readonly busy: boolean,
    readonly error?: string
}

export enum UsersActionTypes {
    FETCH_USERS_REQUEST = "@users/fetch_users/request",
    FETCH_USERS_START = "@users/fetch_users/start",
    FETCH_USERS_SUCCESS = "@users/fetch_users/success",
    FETCH_USERS_FAILURE = "@users/fetch_users/failure",

    CREATE_USER_REQUEST = "@users/create_user/request",
    CREATE_USER_START = "@users/create_user/start",
    CREATE_USER_SUCCESS = "@users/create_user/success",
    CREATE_USER_FAILURE = "@users/create_user/failure",

    EDIT_USER_REQUEST = "@users/edit_user/request",
    EDIT_USER_START = "@users/edit_user/start",
    EDIT_USER_SUCCESS = "@users/edit_user/success",
    EDIT_USER_FAILURE = "@users/edit_user/failure",
    
    DELETE_USER_REQUEST = "@users/delete_user/request",
    DELETE_USER_START = "@users/delete_user/start",
    DELETE_USER_SUCCESS = "@users/delete_user/success",
    DELETE_USER_FAILURE = "@users/delete_user/failure",

    FETCH_ROLES_REQUEST = "@users/fetch_roles/request",
    FETCH_ROLES_START = "@users/fetch_roles/start",
    FETCH_ROLES_SUCCESS = "@users/fetch_roles/success",
    FETCH_ROLES_FAILURE = "@users/fetch_roles/failure",

    GRANT_ROLE_REQUEST = "@users/grant_role/request",
    GRANT_ROLE_START = "@users/grant_role/start",
    GRANT_ROLE_SUCCESS = "@users/grant_role/success",
    GRANT_ROLE_FAILURE = "@users/grant_role/failure",

    REVOKE_ROLE_REQUEST = "@users/revoke_role/request",
    REVOKE_ROLE_START = "@users/revoke_role/start",
    REVOKE_ROLE_SUCCESS = "@users/revoke_role/success",
    REVOKE_ROLE_FAILURE = "@users/revoke_role/failure"
}

export type UsersFetchRequestAction = { type: UsersActionTypes.FETCH_USERS_REQUEST }
export type UsersFetchStartAction = { type: UsersActionTypes.FETCH_USERS_START }
export type UsersFetchSuccessAction = { type: UsersActionTypes.FETCH_USERS_SUCCESS, users: Array<User> }
export type UsersFetchFailureAction = { type: UsersActionTypes.FETCH_USERS_FAILURE, error: string }

export type UserCreationRequestAction = { type: UsersActionTypes.CREATE_USER_REQUEST, user: User  }
export type UserCreationStartAction = { type: UsersActionTypes.CREATE_USER_START }
export type UserCreationSuccessAction = { type: UsersActionTypes.CREATE_USER_SUCCESS, user: User }
export type UserCreationFailureAction = { type: UsersActionTypes.CREATE_USER_FAILURE, error: string }

export type UserEditingRequestAction = { type: UsersActionTypes.EDIT_USER_REQUEST, user: User  }
export type UserEditingStartAction = { type: UsersActionTypes.EDIT_USER_START }
export type UserEditingSuccessAction = { type: UsersActionTypes.EDIT_USER_SUCCESS, user: User }
export type UserEditingFailureAction = { type: UsersActionTypes.EDIT_USER_FAILURE, error: string }

export type UserDeletionRequestAction = { type: UsersActionTypes.DELETE_USER_REQUEST, user: User  }
export type UserDeletionStartAction = { type: UsersActionTypes.DELETE_USER_START }
export type UserDeletionSuccessAction = { type: UsersActionTypes.DELETE_USER_SUCCESS, user: User }
export type UserDeletionFailureAction = { type: UsersActionTypes.DELETE_USER_FAILURE, error: string }

export type RolesFetchRequestAction = { type: UsersActionTypes.FETCH_ROLES_REQUEST }
export type RolesFetchStartAction = { type: UsersActionTypes.FETCH_ROLES_START }
export type RolesFetchSuccessAction = { type: UsersActionTypes.FETCH_ROLES_SUCCESS, roles: Array<Role> }
export type RolesFetchFailureAction = { type: UsersActionTypes.FETCH_ROLES_FAILURE, error: string }

export type UserGrantRoleRequestAction = { type: UsersActionTypes.GRANT_ROLE_REQUEST, user: User, role: Role }
export type UserGrantRoleStartAction = { type: UsersActionTypes.GRANT_ROLE_START }
export type UserGrantRoleSuccessAction = { type: UsersActionTypes.GRANT_ROLE_SUCCESS, user: User }
export type UserGrantRoleFailureAction = { type: UsersActionTypes.GRANT_ROLE_FAILURE, error: string }

export type UserRevokeRoleRequestAction = { type: UsersActionTypes.REVOKE_ROLE_REQUEST, user: User, role: Role }
export type UserRevokeRoleStartAction = { type: UsersActionTypes.REVOKE_ROLE_START }
export type UserRevokeRoleSuccessAction = { type: UsersActionTypes.REVOKE_ROLE_SUCCESS, user: User }
export type UserRevokeRoleFailureAction = { type: UsersActionTypes.REVOKE_ROLE_FAILURE, error: string }

export type UsersAction = 
UsersFetchRequestAction | UsersFetchStartAction | UsersFetchSuccessAction | UsersFetchFailureAction |
UserCreationRequestAction | UserCreationStartAction | UserCreationSuccessAction | UserCreationFailureAction |
UserEditingRequestAction | UserEditingStartAction | UserEditingSuccessAction | UserEditingFailureAction |
UserDeletionRequestAction | UserDeletionStartAction | UserDeletionSuccessAction | UserDeletionFailureAction |
RolesFetchRequestAction | RolesFetchStartAction | RolesFetchSuccessAction | RolesFetchFailureAction |
UserGrantRoleRequestAction | UserGrantRoleStartAction | UserGrantRoleSuccessAction | UserGrantRoleFailureAction | 
UserRevokeRoleRequestAction | UserRevokeRoleStartAction | UserRevokeRoleSuccessAction | UserRevokeRoleFailureAction

/* Sagas */

// Users Fetch
function* usersFetchSaga() {
    yield takeEvery(UsersActionTypes.FETCH_USERS_REQUEST, function*(action: UsersFetchRequestAction) {
        yield put({ type: UsersActionTypes.FETCH_USERS_START })

        try {
            const result: AxiosResponse = yield call(BackendAPI.fetchUsers())
            const users: Array<User> = (result.data as Array<any>).map(rawUser => new User(rawUser))

            yield put({ type: UsersActionTypes.FETCH_USERS_SUCCESS, users })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: UsersActionTypes.FETCH_USERS_FAILURE, error: message })
            console.error(message)
        }
    })
}

// User Creation
function* userCreationSaga() {
    yield takeEvery(UsersActionTypes.CREATE_USER_REQUEST, function*(action: UserCreationRequestAction) {
        yield put({ type: UsersActionTypes.CREATE_USER_START })

        const userTemplate: User = action.user
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.createUser(userTemplate))
            const user: User = new User(result.data)

            yield put({ type: UsersActionTypes.CREATE_USER_SUCCESS, user })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: UsersActionTypes.CREATE_USER_FAILURE, error: message })
            console.error(message)
        }
    })
}

// User Editing
function* userEditingSaga() {
    yield takeEvery(UsersActionTypes.EDIT_USER_REQUEST, function*(action: UserEditingRequestAction) {
        yield put({ type: UsersActionTypes.EDIT_USER_START })

        const userTemplate: User = action.user
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.patchUser(userTemplate))
            const user: User = new User(result.data)

            yield put({ type: UsersActionTypes.EDIT_USER_SUCCESS, user })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: UsersActionTypes.EDIT_USER_FAILURE, error: message })
            console.error(message)
        }
    })
}

// User Deletion
function* userDeletionSaga() {
    yield takeEvery(UsersActionTypes.DELETE_USER_REQUEST, function*(action: UserDeletionRequestAction) {
        yield put({ type: UsersActionTypes.DELETE_USER_START })

        const user: User = action.user
    
        try {
            yield call(BackendAPI.deleteUser(user))
            yield put({ type: UsersActionTypes.DELETE_USER_SUCCESS, user })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: UsersActionTypes.DELETE_USER_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Roles Fetch
function* rolesFetchSaga() {
    yield takeEvery(UsersActionTypes.FETCH_ROLES_REQUEST, function*(action: RolesFetchRequestAction) {
        yield put({ type: UsersActionTypes.FETCH_ROLES_START })
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.fetchRoles())
            const roles: Array<Role> = (result.data as Array<any>).map(rawRole => new Role(rawRole))

            yield put({ type: UsersActionTypes.FETCH_ROLES_SUCCESS, roles })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: UsersActionTypes.FETCH_ROLES_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Grant Role
function* grantRoleSaga() {
    yield takeEvery(UsersActionTypes.GRANT_ROLE_REQUEST, function*(action: UserGrantRoleRequestAction) {
        yield put({ type: UsersActionTypes.GRANT_ROLE_START })

        const userTemplate: User = action.user
        const role: Role = action.role
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.grantRole(userTemplate, role))
            const user: User = new User(result.data)

            yield put({ type: UsersActionTypes.GRANT_ROLE_SUCCESS, user })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: UsersActionTypes.GRANT_ROLE_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Revoke Role
function* revokeRoleSaga() {
    yield takeEvery(UsersActionTypes.REVOKE_ROLE_REQUEST, function*(action: UserRevokeRoleRequestAction) {
        yield put({ type: UsersActionTypes.REVOKE_ROLE_START })

        const userTemplate: User = action.user
        const role: Role = action.role
    
        try {
            const result: AxiosResponse = yield call(BackendAPI.revokeRole(userTemplate, role))
            const user: User = new User(result.data)

            yield put({ type: UsersActionTypes.REVOKE_ROLE_SUCCESS, user })
        } catch (error) {
            const message: string = error.response && error.response.data ? JSON.stringify(error.response.data) : JSON.stringify(error)
    
            yield put({ type: UsersActionTypes.REVOKE_ROLE_FAILURE, error: message })
            console.error(message)
        }
    })
}

// Export a combined Saga
export function* saga() {
    yield all([
        fork(usersFetchSaga),
        fork(userCreationSaga),
        fork(userEditingSaga),
        fork(userDeletionSaga),
        fork(rolesFetchSaga),
        fork(grantRoleSaga),
        fork(revokeRoleSaga)
    ])
}
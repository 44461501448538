import {v4} from "node-uuid"
import Customer from "./Customer"
import User from './User';

class Project {

    id: string = v4()
    name: string
    customer: Customer
    tree: string
    street: string
    year: number
    houseNumber: string
    zipCode: string
    city: string
    manager: User
    completed: boolean = false
    createdAt: Date = new Date()
    modifiedAt: Date = new Date()

    equals = (project: Project) => this.id === project.id

    constructor(input: any) {
        this.name = input.name
        this.customer = new Customer(input.customer)
        this.tree = input.tree
        this.street = input.street
        this.houseNumber = input.houseNumber
        this.zipCode = input.zipCode
        this.city = input.city
        this.manager = new User(input.manager)
        this.year = input.year

        if (input.id) this.id = input.id
        if (input.completed) this.completed = input.completed
        if (input.createdAt) this.createdAt = new Date(input.createdAt)
        if (input.modifiedAt) this.modifiedAt = new Date(input.modifiedAt)
    }

}

export default Project
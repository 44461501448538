import {combineReducers} from "redux"

import {connectRouter, RouterState} from 'connected-react-router'
import {createHashHistory} from 'history'
import {all, fork} from "redux-saga/effects"

import {
    AuthenticationState,
    reducer as authenticationReducer,
    saga as authenticationSaga
} from "./ducks/authentication.duck"
import {reducer as usersReducer, saga as usersSaga, UsersState} from "./ducks/users.duck"
import {CustomersState, reducer as customersReducer, saga as customersSaga} from "./ducks/customers.duck"
import {MasterdataState, reducer as masterdataReducer, saga as masterdataSaga} from "./ducks/masterdata.duck"
import {ProjectsState, reducer as projectsReducer, saga as projectsSaga} from "./ducks/projects.duck"
import {reducer as samplingReducer, saga as samplingSaga, SamplingState} from "./ducks/sampling.duck"
import {reducer as searchReducer, SearchState} from "./ducks/search.duck"
import {ChronicleState, reducer as chronicleReducer, saga as chronicleSaga} from "./ducks/chronicle.duck"
import autoMergeLevel2 from "redux-persist/es/stateReconciler/autoMergeLevel2"
import {PersistConfig, persistReducer} from "redux-persist"
import storage from 'redux-persist/lib/storage'

// To allow us to change the locally cached data structure, we use a changeable key to invalidate any older entry, so the app won't "lock" or crash because of invalid cache data
const samplingPersistenceConfig: PersistConfig = {
    key: "heimerl-sampling-2021-10-08",
    version: 4408102021, // Version (41 = 4.1) Month (12 = DEC) Year (2018)
    storage,
    stateReconciler: autoMergeLevel2,
    whitelist: ["projectId"]
}

export const history = createHashHistory({
    hashType: 'slash',
    getUserConfirmation: (message: any, callback: any) => callback(window.confirm(message))
})

export interface ApplicationState {
    readonly router: RouterState
    readonly authentication: AuthenticationState
    readonly users: UsersState
    readonly customers: CustomersState
    readonly masterdata: MasterdataState
    readonly projects: ProjectsState
    readonly sampling: SamplingState
    readonly search: SearchState
    readonly chronicle: ChronicleState
}

export const rootReducer = combineReducers<ApplicationState>({
    router: connectRouter(history),
    authentication: authenticationReducer,
    users: usersReducer,
    customers: customersReducer,
    masterdata: masterdataReducer,
    projects: projectsReducer,
    sampling: persistReducer(samplingPersistenceConfig, samplingReducer) as any,
    search: searchReducer,
    chronicle: chronicleReducer,
})

export function* rootSaga() {
    yield all([
        fork(authenticationSaga),
        fork(usersSaga),
        fork(customersSaga),
        fork(masterdataSaga),
        fork(projectsSaga),
        fork(samplingSaga),
        fork(chronicleSaga),
    ])
}
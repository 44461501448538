import React from "react"
import {Image, Page, Text, View} from "@react-pdf/renderer"
import styles from "./SamplingProtocolStyles"
import Project from "../../../typescript/objects/Project";
import logo from "../../../images/Heimerl_Logo_Small.png"
import SamplingTreeNode from "../../../typescript/objects/SamplingTreeNode";

interface Props {
    project: Project
    date: Date
    tree: SamplingTreeNode
    partial: Boolean
}

const SamplingProtocolCover = (props: Props) => (
    <Page size="A4" style={[styles.page, { display: 'flex', flexDirection: 'column' }]} >
        <View style={{
            display: 'flex', flexDirection: 'row', padding: '1.5cm', fontSize: '14pt', lineHeight: '1.3',
            borderTop: '1pt solid #006A4D',
            borderLeft: '1pt solid #006A4D',
            borderRight: '1pt solid #006A4D',
            marginBottom: '0mm'
        }} >
            <View style={{ maxWidth: '33%' }}><Image style={{ height: '4.2cm', width: '4.2cm', backgroundColor: '#006A4D' }} src={logo} /></View>
            <View style={{ flexGrow: '1', maxWidth: '70%', paddingTop: '1.2cm', paddingLeft: '1.2cm' }} >
                <Text style={{ fontFamily: 'Helvetica-Bold', color: '#006A4D', fontSize: '20pt', marginBottom: '2mm' }} >{props.partial ? "Teilprotokoll" : "Bemusterungsprotokoll"}</Text>

                {/* On partial Protocols the included parts shall be named on the top line of the Cover */}
                {props.partial && <Text>{props.tree.children.filter(node => !node.isExcludedFromProtocol()).map(node => node.title).join(", ")}</Text>}
            </View>
        </View>

        <View style={{ display: 'flex', flexDirection: 'column', flexGrow: '1', justifyContent: 'center', alignItems: 'center', lineHeight: '1.3', fontSize: '14pt'
            , borderLeft: '1pt solid #006A4D',
            borderRight: '1pt solid #006A4D'
        }} >
            {/* Customer */}
            <Text>Bauherr: </Text>
            <Text>{props.project.customer.surname} {props.project.customer.name}</Text>

            {/* Empty line for better overview */}
            <Text>{"\n"}</Text>

            {/* Construction Address */}
            <Text>Bauort: </Text>
            <Text>{props.project.street} {props.project.houseNumber}</Text>
            <Text>{props.project.zipCode} {props.project.city}</Text>
        </View>

        {/* Project Manager */}
        <View style={{
            textAlign: 'center', fontSize: '12pt'
            , borderLeft: '1pt solid #006A4D',
            borderRight: '1pt solid #006A4D',
        }} >
            <Text>Projektleiter</Text>
            <Text style={{ fontSize: '14pt' }}>{props.project.manager.surname} {props.project.manager.name}</Text>
        </View>

        <View style={{
            display: 'flex'
            , flexDirection: 'row'
            , justifyContent: 'space-around'
            , fontSize: '11pt'
            , lineHeight: '1.5'
            , marginBottom: '1.5cm'
            , paddingTop: '3.5cm'

            , borderLeft: '1pt solid #006A4D',
            borderRight: '1pt solid #006A4D',
        }}
        >
            {/* On partial protocols, no Signature fields shall be rendered! */}
            {!props.partial &&
                <>
                    <View ><Text >_______________________</Text><Text>   Bauherr</Text></View>
                    <View ><Text>_______________________</Text><Text>   Bauleiter</Text></View>
                </>
            }
        </View>

        <View style={styles.footer} fixed>
            <Text fixed>
                {`Stand: ${props.date.toLocaleDateString()}`}
            </Text>
            <Text
                render={({ pageNumber, totalPages }: { pageNumber: number, totalPages: number }) => `Seite ${pageNumber} von ${totalPages}`}
                fixed
            />
        </View>
    </Page>
)

export default SamplingProtocolCover